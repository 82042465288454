import React, {useEffect, useState} from "react";
import styled from 'styled-components';

import HexBorder from '../../components/HexagonalBorder';
import axios from "axios";
import {api} from "../../config";
import {Form, Input, message, Modal, Button as AntButton} from "antd";
import moment from "moment";
import Chat, {StyledChat} from "../../components/Chat";
import MoversList from "../../components/MoversList";
import {connect} from "react-redux";
import {
    BlockInputs,
    BlockList,
    BlockRequest,
    Column,
    Line, NumBlock, Outline,
    RequestPage_, Row,
    TextYourRequest,
    TitleRequest
} from "../requestPage/RequestPage";
import {useInterval} from "../../utils/useInterval";

export const Button = styled.div`
  cursor: pointer;
  width: 100%;
  height: 60px;
  border: 0;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${(props) => props?.theme_?.button.inBlock.background};
  color: ${(props) => props?.theme_?.button.inBlock.color};

  &:hover, &:active, &:focus {
    background: ${(props) => props?.theme_?.button.inBlock.background};
  }
`


function Manager6({theme, ...props}) {

    const [request, setRequest] = useState();
    const [responses, setResponses] = useState([]);

    const getRequests = () => {
        axios(`${api}/requests/${props.match.params.requestId}`).then(({data}) => {
            setRequest(data)
        }).catch(({response: {data}}) =>
            message.error(data)
        )
    }
    const getResponses = () => {
        axios(`${api}/requests/${props.match.params.requestId}/responses/${props.match.params.moverId}`).then(({data}) => {
            setResponses(data);
        }).catch(({response: {data}}) =>
            message.error(data)
        )
    }

    const sendResponse = ({response}) => {
        axios.put(`${api}/requests/${props.match.params.requestId}/response`, JSON.stringify(response)).then(() => {
            message.success("Ответ успешно отправлен");
            getResponses();
        })
    }

    useEffect(_ => {
        getRequests();
        getResponses();
    }, [])

    useInterval(()=>{
        getResponses();
    }, 5000)

    return (
        <RequestPage_ theme={theme}>
            <Row margin={"40px 0 0 0"}>
                <Column width={"31%"}>

                    <BlockRequest>
                        <HexBorder
                            theme={theme.button.inBlock}
                            width={"250px"}
                            height={"60px"}
                            className={"HexInput"}>

                            <Button theme_={theme} style={{height: "60px"}}>Чат</Button>
                        </HexBorder>
                        <Line/>
                        <Chat theme={theme} requestId={props.match.params.requestId}
                              userId={props.match.params.moverId}/>
                    </BlockRequest>

                </Column>

                <Column width={"31%"}>
                    <Outline>
                        {responses.map((el, index) => (
                            <BlockList key={index}><NumBlock>{index + 1})</NumBlock>
                                <div className="response">
                                    <div className="name">
                                        {el.User.Surname && el.User.Name ? `${el.User.Surname} ${el.User.Name}` : el.User.Login}
                                    </div>
                                    <div className="text">
                                        {el.Text}
                                    </div>
                                    <div className="time">
                                        {moment(el.Time).format("DD.MM.YY HH:mm")}
                                    </div>
                                </div>
                            </BlockList>
                        ))}
                    </Outline>
                </Column>


            </Row>
        </RequestPage_>
    )
}


const mapStateToProps = state => {
    return {
        user: state.loginReducer.userData
    }
}

export default connect(mapStateToProps, null)(Manager6)

