import React, {Component} from "react";
import styled from 'styled-components';

const HexBorder = styled.div`
  
  transition: opacity .2s;
  
  &:hover {
    opacity: .8;
  }
  
  --borderSize: ${props => Math.sqrt((props.height) * (props.height) / 2)}px;
  
  width: 100%;
  padding: 0px calc(var(--borderSize) / 1.5);
  position: relative;
  overflow: hidden;

  display: flex;
  align-items: center;
  
  & > *{
    z-index: 1;
  }

  &:hover::before, &:hover::after{
    background: ${props => props.hoverBG};
  }
  &:active::before, &:active::after{
    background: ${props => props.activeBG};
  }

  &::before, &::after{

    content: "";
    transition: .3s;
    visibility: inherit;
    height: var(--borderSize);
    width: var(--borderSize);
    position: absolute;
    transform: rotateZ(45deg) skew(10deg, 10deg);
    background: ${props => props.background};
  }

  &::before{
    left: 5px;

    border-left: 3px solid ${props => props.borderColor};
    border-bottom: 3px solid ${props => props.borderColor};


    // left: ${props => (props.height) / test + testSub}px;
   
  }

  &::after{
    right: 5px;

    // right: ${props => (props.height) / test + testSub}px;

    border-top: 3px solid ${props => props.borderColor};
    border-right: 3px solid ${props => props.borderColor};

  }

`

let test = 2.6;
let testSub = -21;

const TopBotBorder = styled.div`
  pointer-events: none;
  top: 0;
  position: absolute;
  box-sizing: content-box;
  height: calc(100% - 6px);
  width: calc(100% - 44px);
  margin-left: -2px;
  border-top: solid 3px ${props => props.borderColor};
  border-bottom: solid 3px ${props => props.borderColor};
`;

export const OuterBorder = styled.div`

  width: fit-content;
  width: ${props => props.width};
  height: fit-content;
  padding: 0px 5px;
  margin: 6px 0;
  position: relative;

  display: flex;
  align-items: center;

  &::before, &::after{
    --outerBorderSize: ${props => Math.sqrt(((props.height) * (props.height) + 200) / 2)}px;

    content: "";
    visibility: inherit;
    height: var(--outerBorderSize);
    width: var(--outerBorderSize);
    position: absolute;
    transform: rotateZ(45deg) skew(10deg, 10deg);
  }

  &::before{
    left: 3px;
    border-left: 3px solid ${props => props.oBorderColor};
    border-bottom: 3px solid ${props => props.oBorderColor};

    // left: ${props => (props.height) / test + testSub - 2}px;
  }

  &::after{
    right: 3px;
    border-top: 3px solid ${props => props.oBorderColor};
    border-right: 3px solid ${props => props.oBorderColor};

    // right: ${props => (props.height) / test + testSub - 2}px;
  }

`

const OuterTopBotBorder = styled.div`
  --borderSize: ${props => Math.sqrt((props.height) * (props.height) / 2)}px;

  pointer-events: none;
  top: -6px;
  left: 20px;
  left: calc(var(--borderSize) / 1.7);
  position: absolute;
  box-sizing: content-box;

  height: calc(100% + 6px);
  width: calc(100% - calc(var(--borderSize) / 1.6 * 2 - 1px));
  margin-left: 1px;

  border-top: solid 3px ${props => props.oBorderColor};
  border-bottom: solid 3px ${props => props.oBorderColor};
`;

// width - ширина элемента
// height - влияет на высоту уголков с лево на право
// background - цвет внутренних уголков
// hoverBG - цвет внутренних уголков при hover
// focusBG - цвет внутренних уголков при focus
// borderColor - цвет обводки элемента (инпута и внутренних уголков)
// oBorderColor - цвет внешних линий

function HexagonalBorder(props) {

  const themeB = props.theme;
  return (
    <OuterBorder 
      className={props.className}
      width={props.width}
      height={parseInt(props.height || "47px")}
      oBorderColor={props.oBorderColor ||  (themeB ? themeB.borderColor: "rgba(255, 255, 255, 0.38)")}>

      <HexBorder 
        height={parseInt(props.height || "47px")}
        background={props.background || (themeB ? themeB.background: "#fafafa")} 
        hoverBG={props.hoverBG} 
        focusBG={props.focusBG}
        activeBG={props.activeBG}
        borderColor={props.borderColor || "none"}>

        {props.children}
        <TopBotBorder borderColor={props.borderColor || "#0000"}/>
      </HexBorder>
      <OuterTopBotBorder 
        height={parseInt(props.height || "47px")}
        oBorderColor={props.oBorderColor || (themeB ? themeB.borderColor: "rgba(255, 255, 255, 0.38)")}/>
    </OuterBorder>
  )
}


export default HexagonalBorder

