import React, {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import {Button, DatePicker, Input, Select} from "antd";

import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

import SearchSvg from "../../css/svgs/Search";

import HexBorder from '../../components/HexagonalBorder';
import axios from "axios";
import {api} from "../../config";
import moment from "moment";
import {Buttons_, SubGroupButtons} from "../manager4/Manager4";
import history from "../../utils/history";
import {getStationName} from "../../utils/getStationName";
import RoundedButton, {StyledRoundedButton} from "../../components/RoundedButton";
import ArrowRightSVG from "../../images/ArrowRightSVG";

const {Option} = Select;

const SearchInput = styled(Input)`

  width: 100%;
  height: 47px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 0;
  max-width: 610px;

  & input {
    font-size: 18px;
  }

  &.ant-input-affix-wrapper:focus, &.ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 #0000;
  }
`

const PaginationAdditional = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  cursor: pointer;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;

  > span {
    margin: 0 40px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;

    .ant-input {
      background: #FAFAFA;
      border: 1px solid #606060;
      color: #000000;
      border-radius: 8px;
      width: 48px;
      height: 43px;
      text-align: center;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      margin-right: 8px;
    }
  }
`;

const StyledSubGroupButtons = styled(SubGroupButtons)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 200px;
  grid-row-gap: 0;

  > ${StyledRoundedButton} {
    padding: 20px 17px;
  }
`;

const Row = styled.div`
  margin: ${props => props.margin};
  display: flex;
  width: 100%;
  justify-content: space-between;
  justify-content: ${props => props.justifyContent};;

`

const Page = styled.div`
  max-width: 1440px;
  padding: 45px 0;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;

  .filters {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    align-items: center;
    font-size: 18px;

    .ant-btn, .ant-select-selector, .ant-picker {
      height: 56px;
      font-size: 18px;
      background: linear-gradient(180deg, #FFFFFF 63.08%, #E0E0E0 100%);
      border: 1px solid #9C9C9C;
      border-radius: 8px;
    }

    .ant-select {
      margin-right: 12px;

      .ant-select-selector {
        min-width: 180px;
        font-size: 18px;

        .ant-select-selection-item {
          align-items: center;
          display: flex;

        }
      }
    }

    .ant-picker {
      margin: 0 12px;
      width: 145px;

      input {
        font-size: 18px;
      }
    }
  }

  & ${SearchInput} {
    background: ${props => props.theme.input.background};

    & svg {
      stroke: ${props => props.theme.input.svg};
    }

    & input {
      color: ${props => props.theme.input.color};
      background: ${props => props.theme.input.background};
    }
  }
`;

function Mover7({theme}) {

    const [currentPage, setCurrentPage] = useState(1);

    const [showAll, setShowAll] = useState(false);

    const [stations, setStations] = useState([]);
    const [requests, setRequests] = useState([]);
    const [findType, setFindType] = useState("period");
    const [quarter, setQuarter] = useState(moment().startOf('quarter'));
    const [date, setDate] = useState({
        from: moment().add(-1, 'month').format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD")
    });
    const [searchValue, setSearchValue] = useState("");
    const axiosCancelToken = useRef(axios.CancelToken.source());

    useEffect(() => {
        axios(`${api}/catalogs/stations`).then(({data}) => {
            setStations(data);
        });
    }, []);
    const handleSearch = () => {
        if (searchValue.trim() === "") {
            return
        }
        if (axiosCancelToken.current) {
            axiosCancelToken.current.cancel();
            axiosCancelToken.current = axios.CancelToken.source()
        }
        let config = {cancelToken: axiosCancelToken.current.token}

        if (findType === "quarter") {
            const from = quarter.startOf("quarter").format("YYYY-MM-DD");
            const to = quarter.endOf("quarter").format("YYYY-MM-DD");

            axios(`${api}/requests/search/${searchValue.trim()}/${from}/${to}`, config).then(({data}) => {
                setRequests(data);
            })
        } else {
            axios(`${api}/requests/search/${searchValue.trim()}/${date.from}/${date.to}`, config).then(({data}) => {
                setRequests(data);
            })
        }
    }
    return (
        <Page theme={theme}>
            <Row justifyContent={"center"} margin={"40px 0 0 0"}>
                    <SearchInput allowClear onPressEnter={handleSearch}
                                 onChange={({target: {value}}) => setSearchValue(value)}
                                 placeholder={"Введите станцию отправления или назначения"}
                                 prefix={<SearchSvg/>}/>
            </Row>

            <div className="filters">
                <Select value={findType} onChange={value => {
                    setFindType(value);
                }}>
                    <Option value="period">За период</Option>
                    <Option value="quarter">За квартал</Option>
                </Select>
                {findType === "period" ? <React.Fragment>
                    с
                    <DatePicker allowClear={false} locale={locale}
                                onChange={value => setDate({from: moment(value).format("YYYY-MM-DD"), to: date.to})}
                                format={"DD.MM.YY"} value={moment(date.from, "YYYY-MM-DD")}/>
                    по
                    <DatePicker allowClear={false} locale={locale}
                                onChange={value => setDate({from: date.from, to: moment(value).format("YYYY-MM-DD")})}
                                format={"DD.MM.YY"} value={moment(date.to, "YYYY-MM-DD")}/>
                    <Button onClick={handleSearch}>Поиск</Button>
                </React.Fragment> : <React.Fragment>
                    квартал
                    <DatePicker allowClear={false} value={quarter} onChange={value => {
                        setQuarter(value);
                    }} locale={locale} picker="quarter"/>
                    <Button onClick={handleSearch}>Поиск</Button>
                </React.Fragment>}
            </div>

            <Buttons_ theme={theme} className="items">
                <StyledSubGroupButtons>
                    {[...requests].slice(showAll ? 0 : (12 * (currentPage - 1)), showAll ? undefined : (12 * (currentPage - 1) + 12)).map((el, index) => (
                        <RoundedButton
                            onClick={_ => window.open(`/request/${el.CreatorId}/${el.Id}`, '_blank', "noreferrer")}
                            key={index} theme={theme}>
                            Запрос
                            № {el.Id} "{getStationName(stations, el.SrcStation)} — {getStationName(stations, el.DstStation)}"
                            от {moment(el.Created).format("DD.MM.YYYY")}
                        </RoundedButton>
                    ))}
                </StyledSubGroupButtons>
            </Buttons_>
            {!showAll && <Pagination>
                <RoundedButton theme={theme} onClick={() => {
                    if (currentPage > 1) {
                        setCurrentPage((currentPage) => currentPage - 1)
                    }
                }}>
                    <ArrowRightSVG style={{marginRight: 7}}/>
                    Предыдущая
                </RoundedButton>

                <span>
                    <Input value={currentPage} onChange={({target: {value}}) => {
                        if ((parseInt(value) <= Math.ceil(requests?.length / 12)) && parseInt(value) >= 1) {
                            setCurrentPage(parseInt(value));
                        }
                    }}/>
                    из {Math.ceil(requests?.length / 12)}
                </span>

                <RoundedButton theme={theme} onClick={() => {
                    if (currentPage + 1 <= Math.ceil(requests?.length / 12))
                        setCurrentPage((currentPage) => currentPage + 1)
                }}>
                    Следующая
                    <ArrowRightSVG style={{transform: "rotateY(180deg)", marginLeft: 7}}/>
                </RoundedButton>
            </Pagination>}
            <PaginationAdditional>
                <span onClick={() => setShowAll((showAll) => !showAll)}>
                    Вывести все <ArrowRightSVG style={{transform: "rotateY(180deg)", marginLeft: 7}}/>
                </span>
            </PaginationAdditional>

        </Page>
    )
}


export default Mover7

