import axios from "axios";
import {api} from "../../config";
import {message} from "antd";
import {GET_REQUESTS_SUCCESS} from "../actionTypes";
import {localGoBack} from "../../utils/localHistory";

export function submitRequestAction(fields) {
    return (dispatch) => {
        axios.post(`${api}/requests`, fields).then(({data}) => {
            localGoBack();
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }
}
export function editRequestAction(id, fields) {
    return (dispatch) => {
        axios.put(`${api}/requests/${id}`, fields).then(({data}) => {
            localGoBack();
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }
}

export function clearRequestsAction() {
    return (dispatch) => {
        dispatch({type: GET_REQUESTS_SUCCESS, payload: []})
    }
}

export function getRequestAction(prev) {
    return (dispatch) => {
        axios(`${api}/requests`).then(({data}) => {

            let validated = data.map(el => {
                if (prev !== undefined && prev.Data === undefined) {
                    let coincidence = prev.find(ell => el.Id === ell.Id);
                    if (coincidence?.CountResp !== el.CountResp || coincidence.front_updated !== undefined) {
                        return {...el, front_updated: true};
                    }
                }
                return el;
            })

            dispatch({type: GET_REQUESTS_SUCCESS, payload: validated})
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }
}

export function getRequestPaginationAction(prev, page) {
    return (dispatch) => {
        axios(`${api}/requests/pages/${page}`).then(({data}) => {

            let validated = {
                ...data, Data: data.Data.map(el => {
                    if (prev.Data !== undefined) {
                        let coincidence = prev.Data.find(ell => el.Id === ell.Id);
                        if (coincidence?.CountResp !== el.CountResp || coincidence.front_updated !== undefined) {
                            return {...el, front_updated: true};
                        }
                    }
                    return el;
                })
            }
            console.log(validated);

            dispatch({type: GET_REQUESTS_SUCCESS, payload: validated})
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }
}