import React from 'react';

function SmallUserSVG(props: any) {
    return (
        <svg {...props} width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5 0.398438C5.26585 0.398438 0 5.66429 0 11.8984C0 18.1326 5.26585 23.3984 11.5 23.3984C17.7341 23.3984 23 18.1326 23 11.8984C23 5.66429 17.7341 0.398438 11.5 0.398438ZM11.5 6.14844C13.486 6.14844 14.95 7.61124 14.95 9.59844C14.95 11.5856 13.486 13.0484 11.5 13.0484C9.5151 13.0484 8.05 11.5856 8.05 9.59844C8.05 7.61124 9.5151 6.14844 11.5 6.14844ZM5.6281 17.3862C6.65965 15.8682 8.38005 14.8562 10.35 14.8562H12.65C14.6211 14.8562 16.3404 15.8682 17.3719 17.3862C15.9022 18.9594 13.8173 19.9484 11.5 19.9484C9.18275 19.9484 7.0978 18.9594 5.6281 17.3862Z"
                fill="#686868"/>
        </svg>
    );
}

export default SmallUserSVG;