import React from 'react';
import styled from "styled-components";
import {Button} from "antd";

export const ButtonWrap = styled.div`
  border: 3px solid ${({theme}) => theme.button.borderColor};
  padding: 2px;
  display: flex;
`;

export const StyledTekButton = styled(Button)`
  height: 74px;
  border: 1px solid #9C9C9C;
  border-radius: 0;
  min-width: 265px;
  background: ${({theme}) => theme.button.background} !important;
  color: ${({theme}) => theme.button.color};
  font-size: 18px;
  font-weight: 500;

  &:active, &:focus, &:hover {
    background: ${({theme}) => theme.button.background} !important;
    color: ${({theme}) => theme.block.color};
    border: 1px solid #9C9C9C;
  }

  &:hover {
    opacity: .8;
  }
`;

function TekButton({children, wrapClassName, ...props}) {
    return (
        <ButtonWrap theme={props.theme} className={wrapClassName || ""}>
            <StyledTekButton {...props}
                             getPopupContainer={() => document.querySelector("." + StyledTekButton.styledComponentId)}>
                {children}
            </StyledTekButton>
        </ButtonWrap>
    );
}

export default TekButton;