import {Button, Form, Input, message} from 'antd';
import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import axios from "axios";
import {api} from "../config";
import {getRequestAction, getRequestPaginationAction} from "../redux/actions/pageActions";
import history from "../utils/history";
import EditSvg from "../images/EditSvg";
import {Edit} from "../components/RequestInfo";
import {useSelector} from "react-redux";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FAFAFA;
  border-radius: 4px;
  margin-top: 112px;
  padding: 40px 88px 50px 88px;
  max-width: 575px;
  position: relative;
  transform-style: preserve-3d;

  .ant-form-item-explain {
    div {
      opacity: 0;
    }
  }

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;

    textarea {
      background: #FFFFFF;
      border: 2px solid #E3E3E3;
      border-radius: 4px;
      resize: none;
      width: 388px;
      height: 140px;
      margin-top: 40px;
    }

    .ant-btn {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      border: none;
      background: #FF7F26;
      border-radius: 4px;
      color: #FFFFFF !important;
      padding: 16px 21px;
      height: auto;
    }
  }

  &::before {
    position: absolute;
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    top: -3px;
    border-radius: 4px;
    border: 3px solid ${({theme}) => theme.svgColor};
    box-shadow: 0 0 0 3px #FFFFFF60;
    transform: translateZ(-1px);
  }

  h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    color: #000000;
    text-align: center;

    &.red {
      color: #ff4545;
    }
  }
`;

const StyledApplicationCommentPage = styled.div`
  display: flex;
  justify-content: center;
`;

function ApplicationCommentPage({theme, match}: any) {
    const user = useSelector((store: any) => store.loginReducer.userData)

    const [formRef] = Form.useForm();

    const [error, setError] = useState<boolean>(false);

    const onSubmit = () => {
        formRef.validateFields().then(() => {
            setError(false);
        }).catch(({response}) => {
            setError(true);
        })
    }
    const onFinish = (values: any) => {
        axios.put(`${api}/requests/${match.params.requestId}/status/closed`, JSON.stringify(values.StatusNote)).then(() => {
            setError(false);

            if (user.Role === 80)
                history.push(`/manager5/${match.params.requestId}`)
            else
                history.push(`/supervisor/request/${match.params.requestId}`)

            message.success("Вопрос успешно закрыт!")
            // {props.user.Role === 80 && <Edit onClick={() => history.push(`/edit/request/${request?.Id}`)}>
            //     <EditSvg/>
            // </Edit>}


        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    return (
        <StyledApplicationCommentPage>
            <Content theme={theme}>
                <h1 className={error ? "red" : ""}>{error ? "Введите комментарий, для закрытия данного запроса!" : "Оставьте комментарий!"}</h1>
                <Form form={formRef} onFinish={onFinish}>
                    <Form.Item name={"StatusNote"} rules={[{
                        required: true
                    }]}>
                        <Input.TextArea/>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType={"submit"} onClick={onSubmit}>Отправить</Button>
                    </Form.Item>
                </Form>
            </Content>
        </StyledApplicationCommentPage>
    );
}

export default ApplicationCommentPage;