import React, {useContext} from 'react';
import {ThemeContext} from "../css/Themes";

function ExpandSVG(props) {
    const context = useContext(ThemeContext);

    return (
        <svg {...props} width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="21.5" cy="21.5" r="21.5" fill={context.svgColor}/>
            <path d="M15 19L21 25L27 19" stroke="white" strokeWidth="2"/>
        </svg>
    );
}

export default ExpandSVG;