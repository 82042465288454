import React, {Component} from "react";
import {Link} from "react-router-dom";
import styled from 'styled-components';

import HexBorder from '../../components/HexagonalBorder';
import history from "../../utils/history";
import {Buttons} from "../../components/Buttons";

const Page = styled.div`
  max-width: 1440px;
  padding: 45px 0;
	
`;

class Manager2 extends Component {

  render() {
    const theme = this.props.theme;

    return (
      <Page>
  
        <Buttons theme={theme}>

          <HexBorder theme={theme.button}> <button onClick={_=>history.push("/request")}>Создать новый запрос</button> </HexBorder>
          <HexBorder theme={theme.button}> <button onClick={_=>history.push("/manager4")}>Запросы в работе</button> </HexBorder>
          <HexBorder theme={theme.button}> <button onClick={_=>history.push("/manager7")}>История запросов</button> </HexBorder>

        </Buttons>
      </Page>
    )
  }
}


export default Manager2

