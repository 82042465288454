import React from 'react';

function PrintSvg(props: any) {
    return (
        <svg {...props} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 512 512" xmlSpace="preserve">
            <g>
                <path style={{fill: "#E0E0E2"}} d="M395.636,279.273H256H116.364c-12.853,0-23.273,10.42-23.273,23.273v69.818v23.273v23.273v69.818
    c0,12.853,10.42,23.273,23.273,23.273H256h139.636c12.853,0,23.273-10.42,23.273-23.273v-69.818v-23.273v-23.273v-69.818
    C418.909,289.693,408.489,279.273,395.636,279.273z"/>
                <path style={{fill: "#E0E0E2"}} d="M395.636,0H256H116.364c-12.853,0-23.273,10.42-23.273,23.273v69.818v23.273
    c0,12.853,10.42,23.273,23.273,23.273H256h139.636c12.853,0,23.273-10.42,23.273-23.273V93.091V23.273
    C418.909,10.42,408.489,0,395.636,0z"/>
            </g>
            <path style={{fill: "#FFB655"}} d="M488.727,93.091h-69.818v23.273c0,12.853-10.42,23.273-23.273,23.273H256H116.364
	c-12.853,0-23.273-10.42-23.273-23.273V93.091H23.273C10.42,93.091,0,103.511,0,116.364v279.273
	c0,12.853,10.42,23.273,23.273,23.273h69.818v-23.273v-23.273v-69.818c0-12.853,10.42-23.273,23.273-23.273H256h139.636
	c12.853,0,23.273,10.42,23.273,23.273v69.818v23.273v23.273h69.818c12.853,0,23.273-10.42,23.273-23.273V116.364
	C512,103.511,501.58,93.091,488.727,93.091z"/>
            <path style={{fill: "#57565C"}} d="M302.545,372.364H256h-46.545c-12.853,0-23.273,10.42-23.273,23.273s10.42,23.273,23.273,23.273H256
	h46.545c12.853,0,23.273-10.42,23.273-23.273S315.398,372.364,302.545,372.364z"/>
            <path style={{fill: "#D8143A"}} d="M395.644,186.188c-6.128,0-12.131,2.482-16.46,6.811c-4.329,4.329-6.813,10.333-6.813,16.446
	c0,6.128,2.484,12.133,6.813,16.462c4.329,4.329,10.332,6.827,16.46,6.827c6.113,0,12.119-2.498,16.448-6.827
	c4.329-4.329,6.811-10.333,6.811-16.462c0-6.111-2.482-12.116-6.811-16.446C407.761,188.67,401.757,186.188,395.644,186.188z"/>
            <path style={{fill: "#EE8700"}} d="M23.273,418.909h69.818v-23.273v-23.273v-69.818c0-12.853,10.42-23.273,23.273-23.273H256V139.636
	H116.364c-12.853,0-23.273-10.42-23.273-23.273V93.091H23.273C10.42,93.091,0,103.511,0,116.364v279.273
	C0,408.489,10.42,418.909,23.273,418.909z"/>
            <g>
                <path style={{fill: "#C6C5CA"}} d="M116.364,139.636H256V0H116.364c-12.853,0-23.273,10.42-23.273,23.273v69.818v23.273
		C93.091,129.216,103.511,139.636,116.364,139.636z"/>
                <path style={{fill: "#C6C5CA"}} d="M209.455,418.909c-12.853,0-23.273-10.42-23.273-23.273s10.42-23.273,23.273-23.273H256v-93.091
		H116.364c-12.853,0-23.273,10.42-23.273,23.273v69.818v23.273v23.273v69.818c0,12.853,10.42,23.273,23.273,23.273H256v-93.091
		H209.455z"/>
            </g>
        </svg>
    );
}

export default PrintSvg;