import React, {Component} from "react";
import styled from 'styled-components';

import PassRecForm from "./PassRecForm";

const PasswordRecoveryPage = styled.div`
  padding-top: 140px;
  display: flex;
  justify-content: center;
	
`;

class PasswordRecovery extends Component {

  render() {

    return (
      <PasswordRecoveryPage>
       		<PassRecForm/>
      </PasswordRecoveryPage>
    )
  }
}


export default PasswordRecovery

