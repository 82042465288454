import axios from "axios";
import {api, localClient} from "../../config";
import {CLEAR_ERROR, LOCAL_STATUS_CHANGE, LOGIN_ERROR, LOGIN_SUCCESS} from "../actionTypes";
import history from "../../utils/history";
import store from "../store";

export function loginAction({login, password}) {
    return (dispatch) => {
        dispatch({type: CLEAR_ERROR})
        axios(`${api}/auth?a123=${login}&a321=${password}`).then(({data}) => {
            dispatch({type: LOGIN_SUCCESS, payload: data})
            history.push(data.Role === 90 ? "/admin" : data.Role === 80 ? "/manager1" : data.Role === 85 ? "/supervisor" : "/mover1")
            store.dispatch(localClientStatusAction());
        }).catch(() => {
            dispatch({type: LOGIN_ERROR})
        })
    }
}

export function localClientStatusAction() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios(`${localClient}/status`).then(() => {
                if (store.getState().loginReducer.localClientStatus !== "ok") {
                    dispatch({type: LOCAL_STATUS_CHANGE, payload: "ok"})
                }
                resolve();
            }).catch(({response}) => {
                if (store.getState().loginReducer.localClientStatus !== "") {
                    dispatch({type: LOCAL_STATUS_CHANGE, payload: ""})
                }
                reject(response?.status);
            })
        })
    }
}

export function refreshTokenAction() {
    return (dispatch) => {
        axios(`${api}/user`).then(({data}) => {
            dispatch({type: LOGIN_SUCCESS, payload: data})
            history.location.pathname === "/login" && history.push(data.Role === 90 ? "/admin" : data.Role === 80 ? "/manager1" : data.Role === 85 ? "/supervisor" : "/mover1");
            store.dispatch(localClientStatusAction()).catch((err)=>{
                /*do nothing*/
            });
        }).catch(err => {
            console.log(err);
            history.push("/login")
        })
    }
}