import React, {Component, useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import {Link, useHistory, useLocation} from "react-router-dom";
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import ArrowSvg from '../css/svgs/Arrow';
import moment from "moment";
import {connect} from "react-redux";
import history from "../utils/history";
import ThemeChanger from "../images/ThemeChanger.svg"
import Notif from "../images/Notif.svg"
import feather from "../images/feather.png"
import CrossNotif from "../images/CrossNotif.svg"
import {api, localClient} from "../config";
import logout from "../images/logout.svg";
import axios from "axios";
import {Button, message, Modal, Spin} from "antd";
import {localClientStatusAction} from "../redux/actions/loginActions";
import store from "../redux/store";
import {localGoBack} from "../utils/localHistory";
import * as htmlToImage from "html-to-image";

const ModalImage = styled.img`
  display: flex;
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  min-height: 100vh;
  ${props => !props.fullBackground ? "max-height: 100vh;" : ""} //overflow: hidden;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;

  @media print {
    max-height: none;
  }

`

const ExitButton = styled.a`
  text-align: center;
  color: #fff;
  line-height: 17px;

  &:hover {
    color: #fffa;

    & svg {
      fill: #fffa;
    }
  }

`;

const BackLink = styled(Link)`

  text-align: center;
  color: #fff;
  line-height: 17px;

  &:hover {
    color: #fffa;

    & svg {
      fill: #fffa;
    }
  }


`

const TitleDate = styled.div`

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
`

const Title = styled.div`

  width: 100%;
  margin-top: 30px;
  text-align: center;
  color: #FAFAFA;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  padding-bottom: 20px;

  span {
    cursor: pointer;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;

  .actions {
    display: flex;
    position: absolute;
    right: 0;
    top: 50px;

    a {
      img {
        height: 31px;
      }
    }

    .ant-spin {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      width: 31px;
      height: 31px;

      .ant-spin-dot-item {
        background-color: black;
      }
    }

    .ThemeChanger, a {
      height: 31px;

      &:hover {
        opacity: .5;
      }
    }

    .ThemeChanger {
      cursor: pointer;
      margin-right: 15px;


    }
  }
`

const TopLayout = styled.div`
  margin-top: 60px;
`

const MainLayout = styled.div`
  .ant-modal-body {
    padding: 0;
  }

  background: ${props => props.theme.background};
  ${props => props.hideBackground ? "background: #FFF;" : ""}
  padding: 0 20px;

  display: flex;
  justify-content: center;

  & ${BackLink}, & ${ExitButton} {
    color: ${props => props.theme.colorBack};

    & svg {
      fill: ${props => props.theme.colorBack};
    }
  }

  & ${TitleDate} {
    color: ${props => props.theme.colorDate};
  }

  & ${Title} {
    color: ${props => props.theme.color};
    border-bottom: 2px solid ${props => props.theme.color};
  }
`


const Layout = ({back, children, fullBackground, hideBackground, hideTopMenu, localClientStatus, theme, user}) => {

    const [time, setTime] = useState(moment().format('HH:mm'));
    const [notifLoader, setNotifLoader] = useState(false);

    const timeInterval = useRef();
    const cropper = useRef(null);
    const imageSrc = useRef("");
    const [modalVisible, setModalVisible] = useState(false);
    const imageRef = useRef(null);

    useEffect(() => {
        if (imageRef.current !== null && modalVisible === true)
            cropper.current = new Cropper(imageRef.current, {
                viewMode: 3,
            })
        return () => {
            cropper.current?.destroy?.();
        }
    }, [modalVisible])


    const listener = (event) => {
        console.log(event);
        if (event.code === "Enter") {
            console.log(cropper.current.getCroppedCanvas().toDataURL('image/jpeg'));
        }
    }

    useEffect(() => {
        document.addEventListener("keyup", listener)
        return () => {
            document.removeEventListener("keyup", listener)
        }
    }, [cropper])

    useEffect(() => {
        timeInterval.current = setInterval(_ => {
            const currentTime = moment().format('HH:mm');
            if (time !== currentTime)
                setTime(currentTime);
        }, 1000)
        return () => {
            timeInterval.current !== null && clearInterval(timeInterval.current);
        }
    }, [])


    const handleOpenClient = () => {
        setNotifLoader(true);

        store.dispatch(localClientStatusAction()).then(() => {
            window.open(localClient, '_blank', "noreferrer")
        }).catch((status) => {
            if (status === 401) {
                message.error("Локальный клиент не авторизован на сервере!");
                window.open(localClient, '_blank', "noreferrer")
            } else {
                message.error("Локальный клиент не обнаружен!");
            }
        }).finally(() => {
            setNotifLoader(false)
        });
    }

    let TopMenu;

    if (!hideTopMenu) {
        TopMenu = (
            <TopLayout>

                <Modal
                    okText={"Сохранить"}


                    footer={[
                        <Button key="back" onClick={() => setModalVisible(false)}>
                            Отмена
                        </Button>,
                        <Button key="submit" type="primary" onClick={() => {
                            let link = document.createElement('a');
                            link.setAttribute('download', 'CRM_Screenshot.png');
                            link.setAttribute('href', cropper.current.getCroppedCanvas().toDataURL("image/png").replace("image/png", "image/octet-stream"));
                            link.click();
                        }}>
                            Сохранить
                        </Button>,
                        <Button
                            key="link"
                            type="primary"
                            onClick={() => {
                                let blobBin = atob(cropper.current.getCroppedCanvas().toDataURL().split(',')[1]);
                                let array = [];
                                for (let i = 0; i < blobBin.length; i++) {
                                    array.push(blobBin.charCodeAt(i));
                                }
                                let file = new Blob([new Uint8Array(array)], {type: 'image/png'});

                                axios.post(`${api}/user/screenshot`, file).then(() => {
                                    message.success("Скриншот успешно отправлен!")
                                }).catch(({response}) => {
                                    message.error(response?.data || "Error")
                                })
                            }}
                        >
                            Отправить на почту
                        </Button>
                    ]}

                    closable={false} getContainer={() => document.querySelector("." + MainLayout.styledComponentId)}
                    visible={modalVisible} forceRender onCancel={() => setModalVisible(false)}>
                    {imageSrc.current && <ModalImage ref={imageRef} src={imageSrc.current} alt=""/>}
                </Modal>
                <Row>
                    {back?.includes("/exit") ?
                        <ExitButton href={back}><ArrowSvg/> Выход</ExitButton> :
                        <ExitButton onClick={() => {
                            localGoBack();
                        }}><ArrowSvg/> Назад</ExitButton>}
                    <TitleDate>{moment().format('DD.MM.YY')} {time}</TitleDate>
                    <div className="actions">
                        <img onClick={() => {
                            document.querySelector("noscript").style.display = "none";
                            htmlToImage.toPng(document.body)
                                .then(function (dataUrl) {
                                    var img = new Image();
                                    img.src = dataUrl;
                                    imageSrc.current = img.src
                                    setModalVisible(true);
                                    document.querySelector("noscript").style.display = "";
                                })
                                .catch(function (error) {
                                    console.error('oops, something went wrong!', error);
                                });
                        }} className={"ThemeChanger"} src={feather}
                             alt=""/>
                        <img onClick={() => history.push("/theme")} className={"ThemeChanger"} src={ThemeChanger}
                             alt=""/>
                        {notifLoader ? <Spin/> : localClientStatus === "ok" ?
                            <img onClick={handleOpenClient} className={"ThemeChanger"} src={Notif}
                                 alt=""/>
                            :
                            <img onClick={handleOpenClient} className={"ThemeChanger"} src={CrossNotif}
                                 alt=""/>
                        }
                        <a href={`${api}/exit`} className="exit"><img src={logout} alt=""/></a>
                    </div>
                </Row>
                <Title>
                    {(() => {
                        switch (user.Role) {
                            case 80:
                                return "Менеджер коммерческого отдела: ";
                            case 85:
                                return "Руководитель: ";
                            default:
                                return
                        }
                    })()}
                    <span>{user.Surname && user.Name ? `${user.Surname} ${user.Name}` : user.Login}</span>
                </Title>
            </TopLayout>
        )
    }

    return (
        <MainLayout theme={theme} hideBackground={hideBackground}>
            <Content fullBackground={fullBackground}>
                {TopMenu}
                {children}
            </Content>
        </MainLayout>
    )
}


const mapStateToProps = state => {
    return {
        user: state.loginReducer.userData,
        localClientStatus: state.loginReducer.localClientStatus
    }
}

export default connect(mapStateToProps, null)(Layout);

