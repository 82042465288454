import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import SendMail from "../../images/SendMail";
import OpenWord from "../../images/OpenWord";
import {Form, message, Select} from "antd";
import HexBorder from "../../components/HexagonalBorder";
import axios from "axios";
import {api} from "../../config";
import {BlockRequest, Line, RequestPage_} from "../requestPage/RequestPage";
import Chat from "../../components/Chat";
import {Button} from "../manager6/Manager6";
import TekSelect from "../../components/TekSelect";
import RequestInfo, {StyledRequestInfo} from "../../components/RequestInfo";
import history from "../../utils/history";
import {ThemeContext} from "../../css/Themes";
import {useInterval} from "../../utils/useInterval";
import SmallUserSVG from "../../images/SmallUserSVG";
import moment from "moment/moment";

const StyledSupervisorRequestPage = styled(RequestPage_)`
  display: flex;
  justify-content: space-between;

  > div {
    &:not(:last-child) {
      margin-right: 10px;
    }

    &:first-child {
      width: 35%;
    }

    width: 30%;
  }

  .chat {
    margin-top: 16px;
    max-width: 450px;
    width: 100%;
  }
`;

const {Option, OptGroup} = Select;

const StyledCreateRequest = styled(BlockRequest)`
  background: #FAFAFA;
  width: 100%;
  max-width: 726px;
  min-height: 100px;
  margin-top: 16px;
  padding: 50px 30px 50px;
  position: relative;

  ${StyledRequestInfo} {
    width: 100%;
  }

  .typeRequest {
    position: absolute;
    right: 190px;
    top: 120px;
    width: 360px;
  }

  .actions {
    position: absolute;
    top: 50px;
    right: 30px;
    z-index: 999;

    svg {
      cursor: pointer;

      &:first-child {
        margin-right: 20px;
      }

      path {
        fill: ${props => props.theme.svgColor};
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
  }

  .saveBtn:not(:last-child) {
    margin-right: 50px;
  }

  h1 {
    display: flex;
    font-size: 42px;
    font-weight: 600;
    justify-content: center;
    width: 100%;
    margin-bottom: 75px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  .ant-select-selector {
    overflow: hidden;
  }

  .ant-select-selection-overflow {
    overflow-y: auto;

    .ant-select-selection-overflow-item {
      align-self: auto;
    }
  }

  .ant-select-selection-item-remove {
    margin-left: auto;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  .group {
    width: 100%;

    &:first-child {
      margin-right: 50px;
    }

    .ant-row {
      justify-content: space-between;
      align-items: center;
      display: flex;
      margin-bottom: 10px;

      label {
        padding-right: 6px;
        max-width: 304px;
        white-space: break-spaces;
        text-align: left;
        line-height: 22px;
        font-weight: 500;
        font-size: 18px;
        max-height: 44px;
        height: 100%;

        span {
          color: ${props => props.theme.block.line};
        }

        ::after {
          display: none;
        }
      }

      .ant-col {
        overflow: visible;
        max-width: 357px;

        input, .ant-select-selector {
          color: rgba(0, 0, 0, 0.85);

          .ant-select-selection-item {
            top: 7px;
            color: rgba(0, 0, 0, 0.85);
          }

          min-height: 50px;
          font-size: 18px;
        }
      }
    }
  }
`;

const CloseBlock = styled.div`
  display: flex;
  flex-direction: column;


  h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 25px;
    color: #000000;
  }
`;

const CloseComment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FAFAFA;
  border-radius: 4px;
  position: relative;
  transform-style: preserve-3d;
  padding: 30px 50px;
  margin-top: 10px;


  > span {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0 4px 8px 5px rgba(137, 137, 137, 0.25);
    border-radius: 13px;
    padding: 10px 20px 10px 10px;

    .name {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 5px;

      color: #686868;

      svg {
        margin-right: 7px;
      }
    }

    .text {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;

      color: #000000;
      display: flex;
      padding-left: 30px;
    }

    .date {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      margin-left: auto;

      color: #686868;
    }
  }
  
  &::before {
    position: absolute;
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    top: -3px;
    border-radius: 4px;
    border: 3px solid ${({theme}) => theme.svgColor};
    box-shadow: 0 0 0 3px #FFFFFF60;
    transform: translateZ(-1px);
  }
`;


const SubGroupButtons = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-right: 1px;
  }

  &:last-child {
    margin-left: auto;
  }
`
const Buttons = styled.div`
  margin-top: 40px;
  display: flex;

  & button {
    color: ${props => props.theme.button.color};
    background: ${props => props.theme.button.background};
  }

  & button {
    cursor: pointer;
    min-width: 223px;
    height: 47px;
    border: 0;
    font-size: 18px;

  }

  & ${SubGroupButtons} button {
    min-width: 150px;
  }

  & ${SubGroupButtons} > * {
    margin-bottom: 25px;
  }
`

function SupervisorRequestPage({match, ...props}) {
    const [formRef] = Form.useForm();
    const theme = useContext(ThemeContext);

    const [request, setRequest] = useState({});
    const [products, setProducts] = useState([]);
    const [responses, setResponses] = useState([]);
    const [stations, setStations] = useState([]);
    const [railWays, setRailWays] = useState([]);

    useInterval(() => {
        axios(`${api}/requests/${match.params.requestId}/responses`).then(({data}) => {
            let validated = data.map(el => {

                let coincidence = responses.find(ell => el.User.Id === ell.User.Id);
                if (coincidence?.Count !== el.Count || coincidence.front_updated !== undefined) {
                    return {...el, front_updated: true};
                }
                return el;
            })
            setResponses(validated);
        })
    }, 5000)

    useEffect(() => {

        axios(`${api}/requests/${match.params.requestId}/responses`).then(({data}) => {
            setResponses(data);
        })

        axios(`${api}/catalogs/products`).then(({data}) => {
            setProducts(data);
        });
        axios(`${api}/catalogs/stations`).then(({data}) => {
            setStations(data);
        });
        axios(`${api}/catalogs/railways`).then(({data}) => {
            setRailWays(data);
        });
        axios(`${api}/requests/${match.params.requestId}`).then(({data}) => {
            setRequest(data);
            formRef.setFieldsValue(data);
        });
    }, []);


    const getGroups = () => {
        return products.reduce((arr, el) => {
            if (!arr.includes(el.Group)) {
                arr.push(el.Group);
            }
            return arr;
        }, [])
    }

    const handleExportDocx = () => {
        axios.post(`${api}/requests/export/docx`, request, {
            responseType: "blob"
        }).then(({data, headers}) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;

            let filename = "";
            let disposition = headers["content-disposition"];

            if (disposition && disposition.indexOf('attachment') !== -1) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
            }

            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
        })

    }
    const handleReqStatusChange = (Id, status) => {
        if (status === "closed") {
            history.push(`/close/${Id}`);
        } else {
            axios.put(`${api}/requests/${Id}/status/${status}`).then(() => {
                axios(`${api}/requests/${match.params.requestId}`).then(({data}) => {
                    setRequest(data);
                });
            }).catch(() => {
                message.error("Ошибка");
            })
        }
    }

    const ListColumn1 = responses.map((response, index) =>
        <HexBorder key={index} theme={theme.button}>
            <button
                onClick={_ => history.push(`/manager6/${match.params.requestId}/${response.User.Id}`)}>{response.User.Surname && response.User.Name ? `${response.User.Surname} ${response.User.Name}` : response.User.Login}</button>
        </HexBorder>
    );

    const ListColumn2 = responses.map((response, index) =>
        <HexBorder key={index} theme={theme.button} background={response.front_updated ? "green" : undefined}>
            <button style={{
                cursor: 'default',
                minWidth: "auto",
                background: response.front_updated ? "green" : undefined
            }}>{response.Count}</button>
        </HexBorder>
    );


    return (
        <StyledSupervisorRequestPage theme={theme}>
            <StyledCreateRequest theme={theme}>

                <div className="actions">
                    <a href="https://mail.yandex.ru/#compose" target="_blank" rel="noopener noreferrer">
                        <SendMail/>
                    </a>
                    <OpenWord onClick={handleExportDocx}/>
                </div>
                <RequestInfo products={products} defaultOpened={true} railways={railWays} request={request}
                             theme={theme} stations={stations}/>
                <div className="buttons">
                    <TekSelect style={{height: "60px"}} noPopCon
                               value={request?.Status?.Value === "closed" ? "closed" : "work"}
                               onChange={(v) => handleReqStatusChange(request.Id, v)}
                               theme={theme}>
                        <Option value={"work"}>В работе</Option>
                        <Option value={"closed"}>Закрыта</Option>
                    </TekSelect>
                </div>
            </StyledCreateRequest>
            {request?.Status?.Value === "closed" ?
                <CloseBlock>
                    <CloseComment theme={theme}>
                        <h1>Комментарий от сотрудника</h1>
                        <span>
                                <span className={"name"}>
                                    <SmallUserSVG/>
                                    {request?.Status?.Author || "unknown"}
                                </span>
                                <span className="text">
                                    {request?.Status?.Note || "unknown"}
                                </span>
                                <span className="date">
                                    {request?.Status?.Date ? moment(request?.Status?.Date).format("DD.MM.YY HH:mm") : "unknown"}
                                </span>
                            </span>

                    </CloseComment>
                    <div className="chat">
                        <BlockRequest>
                            <h1>Архив чата</h1>
                            <Line/>
                            <Chat noSend theme={theme} requestId={match.params.requestId}/>
                        </BlockRequest>
                    </div>
                </CloseBlock>:
                <>
                    <div className="chat">
                        <BlockRequest>
                            <HexBorder
                                theme={theme.button.inBlock}
                                width={"250px"}
                                height={"60px"}
                                className={"HexInput"}>

                                <Button theme_={theme} style={{height: "60px"}}>Чат</Button>
                            </HexBorder>
                            <Line/>
                            <Chat theme={theme} requestId={match.params.requestId}/>
                        </BlockRequest>
                    </div>
                    <div>
                        <Buttons theme={theme}>
                            <SubGroupButtons>
                                {ListColumn1}
                            </SubGroupButtons>
                            <SubGroupButtons>
                                {ListColumn2}
                            </SubGroupButtons>
                        </Buttons>
                    </div>
                </>
            }
        </StyledSupervisorRequestPage>
    );
}

export default SupervisorRequestPage;