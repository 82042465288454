import React, {Component} from "react";
import styled from 'styled-components';

import HexBorder from '../../components/HexagonalBorder';

const NumBlock = styled.div` 
  height: 100%;
`


const TitleRequest = styled.div` 
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 18px;

`


const BlockRequest = styled.div` 
  width: 100%;
  height: 418px;
  background: #FAFAFA;
  padding: 14px 5px;

  display: flex;
  align-items: center;
  flex-direction: column;

  & .HexInput{
    width: 60%;
  }

  position: relative;
  &:before{
    content: "";
    pointer-events: none;
    position: absolute;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    top: -6px;
    left: -6px;

    border: 3px solid rgba(255, 255, 255, 0.38);
  }
`

const BlockList = styled.div` 
  width: 100%;
  min-height: 120px;
  background: #FAFAFA;
  padding: 6px 14px;

  font-size: 18px;
  line-height: 22px;
  color: #000000;
  display: flex;
  margin-bottom: 8px;
  &:last-child{
    margin-bottom: 0px;
  }
`

const BlockInputs = styled.div` 
  width: 100%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & input{
    width: 100%;
    height: 30px;
    margin-bottom: 8px;
    border: 1px solid #AAAAAA;
    background: #0000;
    outline: none;
    padding-left: 8px;
    
    &:last-child{
      margin-bottom: 0px;
    }
  }

`


const Row = styled.div`
  margin: ${props => props.margin};
  display: flex;
  width: 100%;
  justify-content: space-between;

`

const Column = styled.div`
  width: ${props => props.width};
  display: flex;
`

const Button = styled.div`
  cursor: pointer;
  width: 100%;
  height: 60px;
  border: 0;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;

  background: #FF7F26;
  &:hover {
    background: #Fa7F26;
  }
  &:active {
    background: #ea7F26;
  }
  &:focus{
    background: #FF7F26;
  }
`

const Line = styled.div`
  margin: 20px auto 40px auto;
  width: 65%;
  background: #000;
  border-bottom: 1px solid #FF7F26;
`

const TextYourRequest = styled.div`
  color: #000;
  font-size: 18px;
`

const Outline = styled.div`
  
  width: 100%;
  position: relative;
  &:before{
    content: "";
    pointer-events: none;
    position: absolute;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    top: -6px;
    left: -6px;

  }
`

const Page = styled.div`
  max-width: 1440px;
  padding: 45px 0;

  & ${Button} {
    color: ${props => props.theme.button.inBlock.color};
    background: ${props => props.theme.button.inBlock.background};
  }  

  & ${Outline} { 
    &:before{
      border: 3px solid ${props => props.theme.block.borderColor};
    } 
  }  

  & ${BlockList} {
    color: ${props => props.theme.block.color};
    background: ${props => props.theme.block.background};
    & input{
      border: 1px solid ${props => props.theme.borderColorInput};
    }
  }

  & ${BlockRequest}{
    
    background: ${props => props.theme.block.background};

    & ${TitleRequest}{
      color: ${props => props.theme.block.color};
    }

    & ${TextYourRequest}{
      color: ${props => props.theme.block.color};
    }

    & ${Line}{
      border-bottom: 1px solid ${props => props.theme.block.line};
    }

    &:before{
      border: 3px solid ${props => props.theme.block.borderColor};
    } 
  }
  
`;

class Mover5 extends Component {

  render() {
    const theme = this.props.theme;

    return (
      <Page theme={theme}>

        <Row margin={"40px 0 0 0"}>

          <Column width={"31%"}>

            <BlockRequest>
              <TitleRequest>№2 от 01.01.2021</TitleRequest>
              <Line/>
              <TextYourRequest>
                Описание запроса 
              </TextYourRequest>
            </BlockRequest>

          </Column>
          <Column width={"31%"}>

            <BlockRequest>
            <HexBorder
                theme={theme.button.inBlock}
                width={"250px"}
                height={"60px"}
                className={"HexInput"}> 

                <Button style={{height: "60px"}}>Чат</Button> 
              </HexBorder>
              <Line/>
              <TextYourRequest>
                Запрос
              </TextYourRequest>
            </BlockRequest>

          </Column>

          <Column width={"31%"}>
            <Outline>
              <BlockList>

                <NumBlock>1)</NumBlock>
                <BlockInputs>
                  <input/>
                  <input/>
                  <input/>
                </BlockInputs>

              </BlockList>
              <BlockList><NumBlock>2)</NumBlock></BlockList>
              <BlockList><NumBlock>3)</NumBlock></BlockList>
              <BlockList><NumBlock>4)</NumBlock></BlockList>
              <BlockList><NumBlock>5)</NumBlock></BlockList>
            </Outline>
          </Column>
          

        </Row>
      </Page>
    )
  }
}


export default Mover5

