import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import ava from "../../images/ava.svg";
import avatarPlus from "../../images/avatarPlus.svg";
import plus from "../../images/Plus.svg";
import ellipsis from "../../images/ellipsis.svg";
import cross from "../../images/Cross.svg";
import {Button as AntButton, Form, Input, message, Modal, Popover, Radio, Select} from "antd";
import axios from "axios";
import {api} from "../../config";
import HexBorder from '../../components/HexagonalBorder';
import {Buttons} from "../../components/Buttons";
import {localGoBack} from "../../utils/localHistory";
import SearchSvg from "../../css/svgs/Search";
import EmailCircleSVG from "../../images/EmailCircleSVG";
import DeleteSVG from "../../images/DeleteSVG";

const {Option} = Select;

const AntInput = styled(Form.Item)`

  height: 53px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 20px 0 20px;
  margin-bottom: 0px;

  & label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.4);

  }

  & .ant-input {
    border: 0px;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    &:focus {
      border-color: #0000;
      box-shadow: 0 0 0 #000;
    }

  }

`


const SearchInput = styled(Input)`

  width: 100%;
  height: var(--heightEl);
  height: 47px;
  background: #FAFAFA;
  border: 0;
  border-radius: 0;

  & input {
    font-size: 18px;
  }

  &.ant-input-affix-wrapper:focus, &.ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 #0000;
  }
`


const StyledAdminPage = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;

  .email-value {
    word-break: break-all;
  }

  svg {
    flex: 0 0 auto;
  }

  .ant-modal {
    .ant-modal-body {
      max-width: 393px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;

      p {
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        text-align: center;

        color: #302E2D;
        margin-top: 100px;
        margin-bottom: 90px;
      }

      .buttons {
        display: flex;
        gap: 25px;
        margin-bottom: 150px;
      }

      .ant-form-item-explain {
        position: absolute;
        width: 100%;
        bottom: -15px;
        font-size: 11px;
      }

      h1 {
        text-align: center;
        margin-bottom: 30px;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        margin-top: 100px;
        color: #302E2D;
      }

      .HexButton {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 140px;
      }

      .HexInput {
        width: 100%;

      }

      & > .HexInput {
        margin-bottom: 8px;
      }
    }
  }

  div {
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #FF7F26;
      border-radius: 23px;
    }
  }

  > .div-block {
    width: 100%;
    background: white;
  }

  & ${SearchInput} {
    background: ${props => props.theme.input.background};

    & svg {
      stroke: ${props => props.theme.input.svg};
    }

    & input {
      color: ${props => props.theme.input.color};
      background: ${props => props.theme.input.background};
    }
  }
`;

const EmployerItem = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding: 20px 25px;
  display: flex;
  align-items: center;
  line-height: 22px;
  position: relative;

  &:hover {
    background: #E9F0FD;
    cursor: pointer;
  }

  &.active {
    background: #E9F0FD;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      left: 0;
      border-left: 4px solid #FF7F26;
    }
  }

  img {
    margin-right: 7px;
    height: 35px;
  }
`;

const EmployersList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;

  .list {
    overflow-y: auto;
  }
`;


const Button_ = styled.button`
  cursor: pointer;
  font-size: 18px;
  border: none;
  width: 100%;
  height: 47px;
  color: ${props => props.theme.button.inBlock.color};
  background: ${props => props.theme.button.inBlock.background};
`;

const Button = styled(AntButton)`
  background: #FF7F26 !important;
  border: none;
  height: auto;
  padding: 17px 50px;
  color: white !important;
  font-size: 22px;
  font-weight: 600;
  max-width: 300px;
  margin: auto auto 25px auto;

  &:hover {
    opacity: .8;
  }

  img {
    margin-right: 23px;
  }
`;

const RightPanel = styled.div`
  flex: 1 1 25%;
`;

const Content = styled.div`
  flex: 1 1 50%;
  margin: 0 10px;
  overflow-y: auto;
`;

const PopoverList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    padding: 15px 28px;
    width: 100%;
    font-size: 18px;
    font-weight: 600;

    &:hover {
      background: #FFE0CB;
      cursor: pointer;
    }
  }

  div:not(:last-child) {
    border-bottom: 1px solid #9c9c9c;
  }
`;

const StyledCreateEmployer = styled.div`
  padding: 40px 60px;
  overflow-y: auto;

  .header {
    position: relative;
    margin-bottom: 50px;
    font-size: 34px;
    font-weight: 600;
    display: flex;
    justify-content: center;

    img {
      cursor: pointer;
      position: absolute;
      right: 0;
    }
  }

  .ant-form {
    .ant-row {
      margin-bottom: 7px;
    }

    .ant-col {
      .ant-form-item-control-input-content {
        display: flex;

        input:not(:last-child) {
          margin-right: 8px;
        }
      }

      label {
        font-size: 18px;
        font-weight: 600;
        width: 250px;
      }

      .ant-select-focused {
        .ant-select-selector {
          border: 3px solid #e4e4e4 !important;
        }
      }

      .ant-radio-group {
        display: flex;
        width: 100%;
        height: 50px;

        .ant-radio-button-wrapper {
          &:before {
            display: none;
          }

          &:first-child {
            margin-right: 8px;
          }

          display: flex;
          align-items: center;
          justify-content: center;
          border: 3px solid #e4e4e4 !important;
          box-shadow: none !important;
          background: white !important;
          width: 100%;
          height: auto;
          color: black;

          &.ant-radio-button-wrapper-checked {
            color: white;
            background: #FF7F26 !important;
          }
        }
      }

      input, .ant-select-selector {
        height: 50px;
        font-size: 18px;
        font-weight: 600;
        border: 3px solid #e4e4e4 !important;
        box-shadow: none !important;

        .ant-select-selection-item {
          top: 7px;
        }

        &:hover {
          border: 3px solid #e4e4e4 !important;
        }

        border-radius: 0;
      }
    }

    .border {
      border-bottom: 5px solid #e4e4e4;
      margin: 50px 0;
    }

    .horizontal {
      display: flex;
      align-items: center;
      margin-top: 50px;
      margin-left: 250px;

      ${Button} {
        font-size: 18px;
        margin: 0;
        height: 60px;
      }

      ${Button}.secondary {
        background: white !important;
        color: black !important;
        box-sizing: border-box;
        border: 2px solid #ff7f26;
        margin-left: 50px;
      }
    }
  }
`;

const StyledProfile = styled.div`
  padding: 28px 44px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    border: 1px solid #9c9c9c;
    padding: 0;
  }

  .avatar {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 25px;
    position: relative;

    .avatarImg {
      height: 164px;
    }

    .ellipsis {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
    }

    .title {
      margin-top: 25px;
      font-weight: 600;
      text-align: center;
      font-size: 34px;
    }
  }

  .body {
    .item {
      font-size: 18px;
      font-weight: 600;
      display: flex;
      margin: 30px 0;

      .name {
        width: 100%;
        min-width: 300px;
        max-width: 300px;
      }
    }
  }

  .border {
    border-bottom: 5px solid #E4E4E4;
  }
`;


function Profile({user, parent, closeAll, getUsers, setShowEdit}) {

    const removeUser = () => {
        axios.delete(`${api}/users/${user.Id}`).then(() => {
            closeAll();
            getUsers();
            message.warning("Пользователь удален!");
        }).catch(({response: {data}}) => {
            message.error(data);
        })
    }

    const switchStateUser = () => {
        axios.put(`${api}/users/${user.Id}/state`).then(() => {
            getUsers();
        }).catch(({response: {data}}) => {
            message.error(data);
        })
    }

    return (<StyledProfile>
        <div className="avatar">
            <img className={"avatarImg"} src={avatarPlus} alt=""/>
            <div className="title">{user.Surname && user.Name ? `${user.Surname} ${user.Name}` : user.Login}</div>
            <Popover trigger={"click"} placement={"rightTop"}
                     getPopupContainer={_ => document.querySelector(`.${StyledProfile.styledComponentId}`)}
                     content={<PopoverList>
                         <div onClick={_ => {
                             setShowEdit(true);
                         }}>Редактировать информацию
                         </div>
                         <div
                             onClick={switchStateUser}>{user.State ? "Заблокировать" : "Разблокировать"} пользователя
                         </div>
                         <div onClick={removeUser}>Удалить</div>
                     </PopoverList>}>
                <img className="ellipsis" src={ellipsis} alt="ellipsis"/>
            </Popover>
        </div>
        <div className="body">
            <div className="border"/>
            <div className="item">
                <div className="name">Язык:</div>
                <div className="value">-</div>
            </div>
            <div className="item">
                <div className="name">Часовой пояс:</div>
                <div className="value">-</div>
            </div>
            <div className="border"/>
            <div className="item">
                <div className="name">Почта:</div>
                <div className="value">{user.Email || "-"}</div>
            </div>
            <div className="item">
                <div className="name">Staff:</div>
                <div
                    className="value">{parent?.Surname && parent?.Name ? `${parent.Surname} ${parent.Name}` : parent?.Login || "-"}</div>
            </div>
            <div className="border"/>
            <div className="item">
                <div className="name">Отдел:</div>
                <div className="value">Все сотрудники</div>
            </div>
        </div>
    </StyledProfile>);
}

function CreateEmployer({closeCreate, fetchUsers}) {

    const createUser = ({Login, Surname, Name, Password, Role}) => {
        axios.post(`${api}/users`, {
            Login: Login, Surname: Surname, Name: Name, Password: Password, Role: parseInt(Role)
        }).then(() => {
            closeCreate();
            fetchUsers();
            message.success("Пользователь успешно создан!");
        })
    }

    return (<StyledCreateEmployer>
        <div className="header">
            Новый сотрудник
            <img onClick={_ => closeCreate()} src={cross} alt=""/>
        </div>
        <Form onFinish={createUser}>
            <Form.Item label={"Фамилия"} name={"Surname"}>
                <Input/>
            </Form.Item>
            <Form.Item label={"Имя"} name={"Name"}>
                <Input/>
            </Form.Item>
            <Form.Item label={"Должность"} name={"Position"}>
                <Input disabled/>
            </Form.Item>
            <Form.Item label={"Роль в системе"} name={"Role"}>
                <Select>
                    <Option value={90}>Администратор</Option>
                    <Option value={30}>Движенец</Option>
                    <Option value={80}>Менеджер</Option>
                    <Option value={85}>Руководитель</Option>
                    <Option disabled value={"998"}>Юрист</Option>
                </Select>
            </Form.Item>
            <Form.Item label={"Часовой пояс"} name={"TimeZone"}>
                <Select disabled>
                    <Option value="-12.0">(GMT -12:00) Эниветок, Кваджалейн</Option>
                    <Option value="-11.0">(GMT -11:00) Остров Мидуэй, Самоа</Option>
                    <Option value="-10.0">(GMT -10:00) Гавайи</Option>
                    <Option value="-9.0">(GMT -9:00) Аляска</Option>
                    <Option value="-8.0">(GMT -8:00) Тихоокеанское время (США и Канада)</Option>
                    <Option value="-7.0">(GMT -7:00) Горное время (США и Канада)</Option>
                    <Option value="-6.0">(GMT -6:00) Центральное время (США и Канада), Мехико</Option>
                    <Option value="-5.0">(GMT -5:00) Восточное время (США и Канада), Богота, Лима</Option>
                    <Option value="-4.0">(GMT -4:00) Атлантическое время (Канада), Каракас, Ла-Пас</Option>
                    <Option value="-3.5">(GMT -3:30) Ньюфаундленд</Option>
                    <Option value="-3.0">(GMT -3:00) Бразилия, Буэнос-Айрес, Джорджтаун</Option>
                    <Option value="-2.0">(GMT -2:00) Срединно-Атлантического</Option>
                    <Option value="-1.0">(GMT -1:00 час) Азорские острова, острова Зеленого Мыса</Option>
                    <Option value="0.0">(GMT) Время Западной Европе, Лондон, Лиссабон, Касабланка</Option>
                    <Option value="1.0">(GMT +1:00 час) Брюссель, Копенгаген, Мадрид, Париж</Option>
                    <Option value="2.0">(GMT +2:00) Киев, Калининград, Южная Африка</Option>
                    <Option value="3.0">(GMT +3:00) Багдад, Эр-Рияд, Москва, Санкт-Петербург</Option>
                    <Option value="3.5">(GMT +3:30) Тегеран</Option>
                    <Option value="4.0">(GMT +4:00) Абу-Даби, Мускат, Баку, Тбилиси</Option>
                    <Option value="4.5">(GMT +4:30) Кабул</Option>
                    <Option value="5.0">(GMT +5:00) Екатеринбург, Исламабад, Карачи, Ташкент</Option>
                    <Option value="5.5">(GMT +5:30) Бомбей, Калькутта, Мадрас, Нью-Дели</Option>
                    <Option value="5.75">(GMT +5:45) Катманду</Option>
                    <Option value="6.0">(GMT +6:00) Алматы, Дакке, Коломбо</Option>
                    <Option value="7.0">(GMT +7:00) Бангкок, Ханой, Джакарта</Option>
                    <Option value="8.0">(GMT +8:00) Пекин, Перт, Сингапур, Гонконг</Option>
                    <Option value="9.0">(GMT +9:00) Токио, Сеул, Осака, Саппоро, Якутск</Option>
                    <Option value="9.5">(GMT +9:30) Аделаида, Дарвин</Option>
                    <Option value="10.0">(GMT +10:00) Восточная Австралия, Гуам, Владивосток</Option>
                    <Option value="11.0">(GMT +11:00) Магадан, Соломоновы острова, Новая Каледония</Option>
                    <Option value="12.0">(GMT +12:00) Окленд, Веллингтон, Фиджи, Камчатка</Option>
                </Select>
            </Form.Item>
            <Form.Item label={"Дата рождения"} name={"dateOfBirth"}>
                <Input placeholder={"День"} disabled/>
                <Input placeholder={"Месяц"} disabled/>
                <Input placeholder={"Год"} disabled/>
            </Form.Item>
            <Form.Item label={"Пол"} name={"Sex"}>
                <Radio.Group
                    options={[{label: 'Мужской', value: 'male', disabled: true}, {
                        label: 'Женский',
                        value: 'female',
                        disabled: true
                    }]}
                    optionType="button"
                    buttonStyle="solid"

                />
            </Form.Item>
            <div className="border"/>
            <Form.Item label={"Логин"} name={"Login"}>
                <Input/>
            </Form.Item>
            <Form.Item label={"Пароль"} name={"Password"}>
                <Input/>
            </Form.Item>
            {/*<Form.Item label={"Еще раз"}>*/}
            {/*    <Input/>*/}
            {/*</Form.Item>*/}
            <Form.Item label={"Отдел"} name={"Department"}>
                <Input disabled/>
            </Form.Item>
            <div className={"horizontal"}>
                <Form.Item>
                    <Button htmlType={"submit"}>Добавить</Button>
                </Form.Item>
                <Form.Item>
                    <Button onClick={_ => closeCreate()} className={"secondary"}>Отменить</Button>
                </Form.Item>
            </div>
        </Form>
    </StyledCreateEmployer>)
}

function EditEmployer({closeCreate, fetchUsers, user}) {

    const editUser = ({Login, Surname, Name, Password, Role}) => {
        axios.put(`${api}/users/${user.Id}`, {
            Login: Login, Surname: Surname, Name: Name, Password: Password, Role: parseInt(Role)
        }).then(() => {
            closeCreate();
            fetchUsers();
            message.success("Пользователь успешно отредактирован!");
        })
    }

    return (<StyledCreateEmployer>
        <div className="header">
            Редактировать сотрудника
            <img onClick={_ => closeCreate()} src={cross} alt=""/>
        </div>
        <Form initialValues={user} onFinish={editUser}>
            <Form.Item label={"Фамилия"} name={"Surname"}>
                <Input defaultValue={user.Surname}/>
            </Form.Item>
            <Form.Item label={"Имя"} name={"Name"}>
                <Input defaultValue={user.Name}/>
            </Form.Item>
            <Form.Item label={"Должность"} name={"Position"}>
                <Input disabled/>
            </Form.Item>
            <Form.Item label={"Роль в системе"} name={"Role"}>
                <Select defaultValue={user.Role}>
                    <Option value={90}>Администратор</Option>
                    <Option value={30}>Движенец</Option>
                    <Option value={80}>Менеджер</Option>
                    <Option value={85}>Руководитель</Option>
                    <Option disabled value={"998"}>Юрист</Option>
                </Select>
            </Form.Item>
            <Form.Item label={"Часовой пояс"} name={"TimeZone"}>
                <Select disabled>
                    <Option value="-12.0">(GMT -12:00) Эниветок, Кваджалейн</Option>
                    <Option value="-11.0">(GMT -11:00) Остров Мидуэй, Самоа</Option>
                    <Option value="-10.0">(GMT -10:00) Гавайи</Option>
                    <Option value="-9.0">(GMT -9:00) Аляска</Option>
                    <Option value="-8.0">(GMT -8:00) Тихоокеанское время (США и Канада)</Option>
                    <Option value="-7.0">(GMT -7:00) Горное время (США и Канада)</Option>
                    <Option value="-6.0">(GMT -6:00) Центральное время (США и Канада), Мехико</Option>
                    <Option value="-5.0">(GMT -5:00) Восточное время (США и Канада), Богота, Лима</Option>
                    <Option value="-4.0">(GMT -4:00) Атлантическое время (Канада), Каракас, Ла-Пас</Option>
                    <Option value="-3.5">(GMT -3:30) Ньюфаундленд</Option>
                    <Option value="-3.0">(GMT -3:00) Бразилия, Буэнос-Айрес, Джорджтаун</Option>
                    <Option value="-2.0">(GMT -2:00) Срединно-Атлантического</Option>
                    <Option value="-1.0">(GMT -1:00 час) Азорские острова, острова Зеленого Мыса</Option>
                    <Option value="0.0">(GMT) Время Западной Европе, Лондон, Лиссабон, Касабланка</Option>
                    <Option value="1.0">(GMT +1:00 час) Брюссель, Копенгаген, Мадрид, Париж</Option>
                    <Option value="2.0">(GMT +2:00) Киев, Калининград, Южная Африка</Option>
                    <Option value="3.0">(GMT +3:00) Багдад, Эр-Рияд, Москва, Санкт-Петербург</Option>
                    <Option value="3.5">(GMT +3:30) Тегеран</Option>
                    <Option value="4.0">(GMT +4:00) Абу-Даби, Мускат, Баку, Тбилиси</Option>
                    <Option value="4.5">(GMT +4:30) Кабул</Option>
                    <Option value="5.0">(GMT +5:00) Екатеринбург, Исламабад, Карачи, Ташкент</Option>
                    <Option value="5.5">(GMT +5:30) Бомбей, Калькутта, Мадрас, Нью-Дели</Option>
                    <Option value="5.75">(GMT +5:45) Катманду</Option>
                    <Option value="6.0">(GMT +6:00) Алматы, Дакке, Коломбо</Option>
                    <Option value="7.0">(GMT +7:00) Бангкок, Ханой, Джакарта</Option>
                    <Option value="8.0">(GMT +8:00) Пекин, Перт, Сингапур, Гонконг</Option>
                    <Option value="9.0">(GMT +9:00) Токио, Сеул, Осака, Саппоро, Якутск</Option>
                    <Option value="9.5">(GMT +9:30) Аделаида, Дарвин</Option>
                    <Option value="10.0">(GMT +10:00) Восточная Австралия, Гуам, Владивосток</Option>
                    <Option value="11.0">(GMT +11:00) Магадан, Соломоновы острова, Новая Каледония</Option>
                    <Option value="12.0">(GMT +12:00) Окленд, Веллингтон, Фиджи, Камчатка</Option>
                </Select>
            </Form.Item>
            <Form.Item label={"Дата рождения"} name={"dateOfBirth"}>
                <Input placeholder={"День"} disabled/>
                <Input placeholder={"Месяц"} disabled/>
                <Input placeholder={"Год"} disabled/>
            </Form.Item>
            <Form.Item label={"Пол"} name={"Sex"}>
                <Radio.Group
                    options={[{label: 'Мужской', value: 'male', disabled: true}, {
                        label: 'Женский',
                        value: 'female',
                        disabled: true
                    }]}
                    optionType="button"
                    buttonStyle="solid"

                />
            </Form.Item>
            <div className="border"/>
            <Form.Item label={"Логин"} name={"Login"}>
                <Input defaultValue={user.Login}/>
            </Form.Item>
            <Form.Item label={"Пароль"} name={"Password"}>
                <Input defaultValue={user.Password}/>
            </Form.Item>
            {/*<Form.Item label={"Еще раз"}>*/}
            {/*    <Input/>*/}
            {/*</Form.Item>*/}
            <Form.Item label={"Отдел"} name={"Department"}>
                <Input disabled/>
            </Form.Item>
            <div className={"horizontal"}>
                <Form.Item>
                    <Button htmlType={"submit"}>Сохранить</Button>
                </Form.Item>
                <Form.Item>
                    <Button onClick={_ => closeCreate()} className={"secondary"}>Отменить</Button>
                </Form.Item>
            </div>
        </Form>
    </StyledCreateEmployer>)
}

function AdminPage({theme, showEmails}) {

    const [emails, setEmails] = useState([]);
    const [users, setUsers] = useState([]);
    // const [selectedEmail, setSelectedEmail] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showCreateEmail, setShowCreateEmail] = useState(false);
    const [emailToDelete, setEmailToDelete] = useState("");
    const [showDeleteEmail, setShowDeleteEmail] = useState(false);
    const [email, setEmail] = useState("");
    const [notif, setNotif] = useState("");
    const [showNotif, setShowNotif] = useState(false);

    const [formRef] = Form.useForm();

    const addEmail = ({email}) => {
        axios.post(`${api}/config/emails/${email}`).then(({data}) => {
            getEmails();
            formRef.resetFields();
            setShowCreateEmail(false);
            setNotif(<>Почта успешно<br/> добавлена!</>)
            setShowNotif(true);
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }
    const deleteEmail = () => {
        if (emailToDelete !== "") {
            axios.delete(`${api}/config/emails/${emailToDelete}`).then(({data}) => {
                getEmails();
                setEmailToDelete("");
                setShowDeleteEmail(false);
                setNotif(<>Почта успешно<br/> удалена!</>)
                setShowNotif(true);
            }).catch(({response}) => {
                message.error(response?.data || "Error")
            })
        }
    }

    const getUsers = () => {
        axios(`${api}/users`).then(({data}) => {
            setUsers(data);
        }).catch(({response: {data}}) => {
            message.error(data);
        })
    }
    const getEmails = () => {
        axios(`${api}/config/emails`).then(({data}) => {
            setEmails(data);
        }).catch(({response: {data}}) => {
            message.error(data);
        })
    }

    useEffect(() => {
        if (showEmails !== undefined) {
            getEmails()
        } else {
            getUsers();
        }
    }, [])

    useEffect(() => {
        if (selectedUser !== null) {
            let newSelectedUser = users.find(el => el.Id === selectedUser.Id);
            newSelectedUser !== undefined && setSelectedUser(newSelectedUser);
        }
    }, [users])

    return (<StyledAdminPage theme={theme}>
        <Modal className={"modal-modal"} visible={showCreateEmail} onCancel={() => setShowCreateEmail(false)} footer={null}
               getContainer={() => document.querySelector(`.${StyledAdminPage.styledComponentId}`)}>
            <h1>Добавление<br/> почты</h1>
            <HexBorder
                className={"HexInput"}
                height={"53px"}
                background={"#fff"}
                borderColor={"rgba(155, 155, 155, 0.52)"}
                oBorderColor={"rgba(219, 214, 214, 0.38)"}
            >
                <Form form={formRef} onFinish={addEmail}>
                    <AntInput name={'email'} rules={[{
                        type: "email", message: "Пожалуйста, введите корректный Email"
                    }, {
                        required: true, message: "Пожалуйста, введите Email"
                    }]}>
                        <Input placeholder={"Эл. почта"}/>
                    </AntInput>
                </Form>
            </HexBorder>
            <HexBorder className={"HexButton"} width={"240px"} height={"47px"} theme={theme.button.inBlock}>
                <Button_ theme={theme} onClick={_ => {
                    formRef.submit();
                }}>Добавить</Button_>
            </HexBorder>
        </Modal>
        <Modal visible={showDeleteEmail} onCancel={() => setShowDeleteEmail(false)} footer={null}
               getContainer={() => document.querySelector(`.${StyledAdminPage.styledComponentId}`)}>
            <h1>Вы действительно<br/> хотите удалить<br/> почту?</h1>
            <div className="buttons">
                <Button onClick={deleteEmail}>Да</Button>
                <Button onClick={() => {
                    setEmailToDelete("");
                    setShowDeleteEmail(false);
                }}>Нет</Button>
            </div>
        </Modal>
        <Modal visible={showNotif} onCancel={() => setShowNotif(false)} footer={null}
               getContainer={() => document.querySelector(`.${StyledAdminPage.styledComponentId}`)}>
            <p>{notif}</p>
        </Modal>
        {showEmails ? <EmployersList className={"div-block"}>
            <div  className="list">
                {emails.map(el => (<EmployerItem>
                    <EmailCircleSVG style={{marginRight: 15}}/>
                    <span className={"email-value"}>{el}</span>
                    <DeleteSVG style={{marginLeft: 25}} onClick={() => {
                        setEmailToDelete(el);
                        setShowDeleteEmail(true)
                    }}/>
                </EmployerItem>))}
            </div>
            <Button onClick={_ => {
                if (showEmails === undefined) {
                    setShowEdit(false);
                    setShowCreate(true);
                } else {
                    setShowCreateEmail(true);
                }
            }}><img src={plus} alt="plus"/> Добавить</Button>
        </EmployersList> : <EmployersList className={"div-block"}>
            <div className="list">
                {users.map(el => (<EmployerItem onClick={_ => {
                    setSelectedUser(el);
                    setShowCreate(false);
                    setShowEdit(false);
                }}
                                                className={selectedUser?.Id === el.Id ? "active" : ""}><img
                    src={ava}
                    alt="avatar"/>{el.Surname && el.Name ? `${el.Surname} ${el.Name}` : el.Login}
                </EmployerItem>))}
            </div>
            <Button onClick={_ => {
                if (showEmails === undefined) {
                    setShowEdit(false);
                    setShowCreate(true);
                } else {
                    setShowCreateEmail(true);
                }
            }}><img src={plus} alt="plus"/> Добавить</Button>
        </EmployersList>}
        <Content className={"div-block"}>
            {showEdit === true && <EditEmployer user={selectedUser} fetchUsers={getUsers} closeCreate={_ => {
                setShowEdit(false)
                setShowCreate(false)
                setSelectedUser(null);
            }}/>}
            {showCreate === true && <CreateEmployer fetchUsers={getUsers} closeCreate={_ => {
                setSelectedUser(null);
                setShowCreate(false)
            }}/>}
            {selectedUser?.Id !== undefined && showCreate === false && showEdit === false &&
                <Profile setShowEdit={setShowEdit}
                         closeAll={_ => {
                             setSelectedUser(null);
                             setShowCreate(false);
                         }}
                         getUsers={getUsers}
                         parent={selectedUser.ParentId === 1 ? users.find(el => el.Login === "admin") : users.find(el => el.Id === selectedUser.ParentId)}
                         user={selectedUser}/>}
        </Content>
        <RightPanel className={"div-block"}/>
    </StyledAdminPage>);
}

export default AdminPage;