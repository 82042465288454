import React, {Component} from "react";
import styled from 'styled-components';

import HexBorder from '../../components/HexagonalBorder';
import history from "../../utils/history";
import {Buttons} from "../../components/Buttons";

const Page = styled.div`
  max-width: 1440px;
  padding: 45px 0;

`;

class Mover2 extends Component {

    render() {
        const theme = this.props.theme;

        return (
            <Page>
                <Buttons theme={theme}>
                    {/*<div style={{minWidth: "277px"}}/>*/}
                    <HexBorder theme={theme.button}>
                        <button/>
                    </HexBorder>
                    <HexBorder theme={theme.button}>
                        <button onClick={_ => history.push("/mover3")}>Запросы в работе</button>
                    </HexBorder>
                    <HexBorder theme={theme.button}>
                        <button onClick={_=>history.push("/mover7")}>История запросов</button>
                    </HexBorder>

                </Buttons>
            </Page>
        )
    }
}


export default Mover2

