import React from 'react';

function ArrowDownSvg(props) {
    return (
        <svg {...props} width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7.5 8L14 1" stroke="black" strokeWidth="2"/>
        </svg>
    );
}

export default ArrowDownSvg;