import React, {Component} from "react";
import {Link} from "react-router-dom";
import styled from 'styled-components';

import HexBorder from '../../components/HexagonalBorder';

const Page = styled.div`
  max-width: 1440px;
  padding: 45px 0;
	
`

const SubGroupButtons = styled.div`
  display: flex;
  flex-direction: column;
  flex-direction: ${props => props.flexDirection};

  &:last-child{
    & > *:last-child{
      margin-left: 60px;
    }
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between; 

  & button{
    cursor: pointer;
    min-width: 223px; 
    height: 47px;
    border: 0;
    
    font-size: 18px;
    color: ${props => props.theme.button.color};
    background: ${props => props.theme.button.background};
  }

  & ${SubGroupButtons} button{
    min-width: 150px; 
  }

  & ${SubGroupButtons} > *{
    margin-bottom: 14px;
  }
`

class Mover6 extends Component {

  data = [
    [" "],
    [" "],
    [" "],
    [" "],
    [" "],
  ];

  render() {
    const theme = this.props.theme;

    const ListColumn1 = this.data.map((key) =>
        <HexBorder theme={theme.button}> <button>{key[0]}</button> </HexBorder>
    );

    return (
      <Page>

          <Buttons theme={theme}>

              <SubGroupButtons>
                {ListColumn1}
              </SubGroupButtons>

              <SubGroupButtons flexDirection={"row"}>
                <HexBorder theme={theme.button}> <button>Сохранить</button> </HexBorder>
                <HexBorder theme={theme.button}> <button>Отправить</button> </HexBorder>
              </SubGroupButtons>

          </Buttons>

      </Page>
    )
  }
}


export default Mover6

