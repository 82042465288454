import React, {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import {Button, DatePicker, Input, Select} from "antd";

import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

import SearchSvg from "../../css/svgs/Search";

import HexBorder from '../../components/HexagonalBorder';
import axios from "axios";
import {api} from "../../config";
import moment from "moment";
import {Buttons_, SubGroupButtons} from "../manager4/Manager4";
import history from "../../utils/history";
import {getStationName} from "../../utils/getStationName";

const {Option} = Select;

const SearchInput = styled(Input)`

  width: 100%;
  height: var(--heightEl);
  height: 47px;
  background: #FAFAFA;
  border: 0;
  border-radius: 0;

  & input {
    font-size: 18px;
  }

  &.ant-input-affix-wrapper:focus, &.ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 #0000;
  }
`

const Row = styled.div`
  margin: ${props => props.margin};
  display: flex;
  width: 100%;
  justify-content: space-between;
  justify-content: ${props => props.justifyContent};;

`

const Page = styled.div`
  max-width: 1440px;
  padding: 45px 0;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;

  .filters {
    display: flex;
    justify-content: center;  
    margin-top: 25px;
    align-items: center;
    font-size: 18px;

    .ant-btn, .ant-select-selector, .ant-picker {
      height: 56px;
      font-size: 18px;
      background: linear-gradient(180deg, #FFFFFF 63.08%, #E0E0E0 100%);
      border: 1px solid #9C9C9C;
      border-radius: 0;
    }

    .ant-select {
      margin-right: 12px;

      .ant-select-selector {
        min-width: 180px;
        font-size: 18px;

        .ant-select-selection-item {
          align-items: center;
          display: flex;

        }
      }
    }

    .ant-picker {
      margin: 0 12px;

      input {
        font-size: 18px;
      }
    }
  }

  & ${SearchInput} {
    background: ${props => props.theme.input.background};

    & svg {
      stroke: ${props => props.theme.input.svg};
    }

    & input {
      color: ${props => props.theme.input.color};
      background: ${props => props.theme.input.background};
    }
  }
`;

function Manager7({theme}) {

    const [requests, setRequests] = useState([]);
    const [stations, setStations] = useState([]);

    const [findType, setFindType] = useState("period");
    const [quarter, setQuarter] = useState(moment().startOf('quarter'));
    const [date, setDate] = useState({
        from: moment().add(-1, 'month').format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD")
    });
    const [searchValue, setSearchValue] = useState("");
    const axiosCancelToken = useRef(axios.CancelToken.source());

    useEffect(() => {
        axios(`${api}/catalogs/stations`).then(({data}) => {
            setStations(data);
        });
    }, []);


    const handleSearch = () => {
        if (searchValue.trim() === "") {
            return
        }
        if (axiosCancelToken.current) {
            axiosCancelToken.current.cancel();
            axiosCancelToken.current = axios.CancelToken.source()
        }
        let config = {cancelToken: axiosCancelToken.current.token}

        if (findType === "quarter") {
            const from = quarter.startOf("quarter").format("YYYY-MM-DD");
            const to = quarter.endOf("quarter").format("YYYY-MM-DD");

            axios(`${api}/requests/search/${searchValue.trim()}/${from}/${to}`, config).then(({data}) => {
                setRequests(data);
            })
        }else {
            axios(`${api}/requests/search/${searchValue.trim()}/${date.from}/${date.to}`, config).then(({data}) => {
                setRequests(data);
            })
        }
    }
    return (
        <Page theme={theme}>
            <Row justifyContent={"center"} margin={"40px 0 0 0"}>
                <HexBorder theme={theme.input} width={"100%"}>
                    <SearchInput allowClear onPressEnter={handleSearch} onChange={({target: {value}}) => setSearchValue(value)}
                                 placeholder={"Введите станцию отправления или назначения"}
                                 prefix={<SearchSvg/>}/>
                </HexBorder>
            </Row>

            <div className="filters">
                <Select value={findType} onChange={value => {
                    setFindType(value);
                }}>
                    <Option value="period">За период</Option>
                    <Option value="quarter">За квартал</Option>
                </Select>
                {findType === "period" ? <React.Fragment>
                    с
                    <DatePicker allowClear={false} locale={locale} onChange={value=>setDate({from:moment(value).format("YYYY-MM-DD"), to:date.to})} format={"DD.MM.YY"} value={moment(date.from, "YYYY-MM-DD")}/>
                    по
                    <DatePicker allowClear={false} locale={locale} onChange={value=>setDate({from:date.from, to:moment(value).format("YYYY-MM-DD")})} format={"DD.MM.YY"} value={moment(date.to, "YYYY-MM-DD")}/>
                    <Button onClick={handleSearch}>Поиск</Button>
                </React.Fragment> : <React.Fragment>
                    квартал
                    <DatePicker allowClear={false} value={quarter} onChange={value=> {
                        setQuarter(value);
                    }} locale={locale} picker="quarter"/>
                    <Button onClick={handleSearch}>Поиск</Button>
                </React.Fragment>}
            </div>

            <Buttons_ theme={theme} className="items">
                <SubGroupButtons>
                    {requests.map((el, index) => (
                        <HexBorder key={index} theme={theme.button}>
                            <button onClick={_ => window.open(`/manager5/${el.Id}`, '_blank', "noreferrer")}>Запрос № {el.Id} "{getStationName(stations, el.SrcStation)} — {getStationName(stations, el.DstStation)}"
                                от {moment(el.Created).format("DD.MM.YYYY")}</button>
                        </HexBorder>
                    ))}
                </SubGroupButtons>
            </Buttons_>

        </Page>
    )
}


export default Manager7

