import React, {Component} from "react";
import styled from 'styled-components';

const Page = styled.div`
  padding-top: 310px;
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #2B9976;
`

class EmailSent extends Component {

  render() {

    return (
      <Page>
       	<Title>Письмо  отправлено<br/> на электронную почту</Title>
      </Page>
    )
  }
}


export default EmailSent

