import React from 'react';

function MailSvg(props: any) {
    return (
        <svg {...props} width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M22.4648 30.9343H27.4498L34.2861 24.0979C34.9756 23.4084 34.9756 22.294 34.2861 21.6063L31.7946 19.1147C31.1051 18.4253 29.9907 18.4253 29.303 19.1147L22.4666 25.9511V30.9343H22.4648Z"
                  fill="black" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M31.2567 13.3499V4.55273" stroke="#323232" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M15.3946 23.9324H4.82175C2.87505 23.9324 1.29688 22.3542 1.29688 20.4057V4.50586" stroke="black"
                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M27.7149 0.958984H4.84115C2.88386 0.958984 1.29688 2.54597 1.29688 4.50502V4.50502C1.29688 5.68116 1.87877 6.7797 2.85212 7.43919L12.2947 13.8418C14.7016 15.4729 17.858 15.4729 20.2649 13.8418L29.704 7.44095C30.6773 6.78147 31.2592 5.68116 31.2592 4.50678V4.50502C31.2575 2.54597 29.6722 0.958984 27.7149 0.958984Z"
                  stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default MailSvg;