export const initialState = {
    loginReducer: {
        loading: true,
        userData: {},
        loginError: "",
        localClientStatus: ""
    },
    pageReducer: {
        requests: []
    }
}