import React, {Component} from "react";
import styled from 'styled-components';

import FormLogin from "./FormLogin";
import store from "../../redux/store";
import {loginAction} from "../../redux/actions/loginActions";

const LoginPage = styled.div`
  padding-top: 140px;
  display: flex;
  justify-content: center;
	
`;

class Login extends Component {

  handleSubmit = ({login, password}) => {
    store.dispatch(loginAction({login:login, password:password}));
  }

  render() {

    return (
      <LoginPage>
       		<FormLogin onFinish={this.handleSubmit}/>
      </LoginPage>
    )
  }
}


export default Login

