import React, {useEffect, useState} from "react";
import styled from 'styled-components';

import HexBorder from '../../components/HexagonalBorder';
import store from "../../redux/store";
import {clearRequestsAction, getRequestAction, getRequestPaginationAction} from "../../redux/actions/pageActions";
import {connect} from "react-redux";
import moment from "moment";
import {Buttons} from "../../components/Buttons";
import history from "../../utils/history";
import {Input} from "antd";
import showAllSvg from "../../images/showAll.svg"
import buttonArrowSvg from "../../images/buttonArrow.svg"
import axios from "axios";
import {api} from "../../config";
import {getStationName} from "../../utils/getStationName";
import {useInterval} from "../../utils/useInterval";
import {styles} from "react-animations/lib/swing";
import {StyledRoundedButton} from "../../components/RoundedButton";

const Page = styled.div`
  max-width: 1440px;
  padding: 45px 0;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;

`;

export const Pagination = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  .reversed {
    transform: rotate(180deg);
  }

  .top {
    display: flex;

    &.hidden {
      display: none;
    }
  }

  .showAll {
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;

    &:hover {
      opacity: .5;
    }
  }

  .pages {
    display: flex;
    align-items: center;
    margin: 0 40px;
    font-size: 18px;

    input {
      width: 50px;
      font-size: 18px;
      height: 40px;
      margin-right: 8px;
    }
  }
`;

export const SubGroupButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 0;

  &:last-child {
    margin-right: 20px;
    margin-left: 20px;
  }

  &:not(:last-child) {
    margin-right: auto;
  }

`

export const Buttons_ = styled(Buttons)`
  justify-content: flex-start;

  &.items {
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }


    &::-webkit-scrollbar-thumb {
      background-color: #343434;
    }
  }

  & ${SubGroupButtons} button {
    min-width: 100px;
    padding: 0 40px;
  }

  & ${SubGroupButtons} > * {
    margin-bottom: 25px;
  }
`

function Manager4({theme, requests}) {


    const [page, setPage] = useState(1);
    const [showAll, setShowAll] = useState(false);
    const [stations, setStations] = useState([]);

    useEffect(() => {
        store.dispatch(getRequestPaginationAction(requests, page || 1))
    }, [page])

    useEffect(() => {
        axios(`${api}/catalogs/stations`).then(({data}) => {
            setStations(data);
        });

        store.dispatch(getRequestPaginationAction(requests, page))

        return () => {
            store.dispatch(clearRequestsAction());
        }
    }, [])

    useInterval(() => {
        store.dispatch(showAll ? getRequestAction(requests) : getRequestPaginationAction(requests, page));
    }, 5000)

    const data = requests.Data ? requests.Data : requests;

    const handleCloseRequest = (Id) => {
        history.push(`/close/${Id}`);

        // axios.put(`${api}/requests/${Id}/status/closed`).then(() => {
        //     showAll ? getRequestAction(requests) : getRequestPaginationAction(requests, page);
        // })
    }

    const ListColumn1 = data?.map((req) =>
        <HexBorder theme={theme.button}>
            <button onClick={_ => history.push(`/manager5/${req.Id}`)}>Запрос
                № {req.Id} "{getStationName(stations, req.SrcStation)} — {getStationName(stations, req.DstStation)}"
                от {moment(req.Created).format("DD.MM.YYYY")}</button>
        </HexBorder>
    );

    const ListColumn2 = data?.map((req) =>
        <HexBorder background={req.front_updated ? "green" : undefined} theme={theme.button}>
            <button onClick={_ => history.push(`/manager5/${req.Id}`)} style={{background: req.front_updated ? "green" : undefined}}>{req.CountResp || "-"}</button>
        </HexBorder>
    );

    const ListColumn3 = data?.map((req) =>
        <HexBorder background={req.front_updated ? "green" : undefined} theme={theme.button}>
            <button onClick={()=>handleCloseRequest(req.Id)}>Закрыть</button>
        </HexBorder>
    );

    return (
        <Page>
            <Buttons_ theme={theme} className={"items"}>
                <SubGroupButtons>
                    {ListColumn1}
                </SubGroupButtons>
                <SubGroupButtons>
                    {ListColumn2}
                </SubGroupButtons>
                <SubGroupButtons>
                    {ListColumn3}
                </SubGroupButtons>
            </Buttons_>
            <Buttons_ theme={theme}>
                <Pagination>
                    <div className={`top ${showAll ? "hidden" : ""}`}>
                        <HexBorder theme={theme.button}>
                            <button onClick={_ => (parseInt(page) - 1 >= 1) && setPage(parseInt(page) - 1)}><img
                                className="reversed"
                                src={buttonArrowSvg}
                                alt=""/> Предыдущая
                            </button>
                        </HexBorder>
                        <div className="pages">
                            <Input value={page} onBlur={({target: {value}}) => {
                                if (value === "") {
                                    setPage(1);
                                }
                            }} onChange={({target: {value}}) => {
                                if (((value <= Math.ceil(requests.Total / 10)) && (value >= 1)) || value === "") {
                                    setPage(value);
                                }
                            }}/>
                            из {Math.ceil(requests.Total / 10)}
                        </div>
                        <HexBorder theme={theme.button}>
                            <button
                                onClick={_ => (parseInt(page) + 1 <= Math.ceil(requests.Total / 10)) && setPage(parseInt(page) + 1)}>Следующая <img
                                src={buttonArrowSvg} alt=""/></button>
                        </HexBorder>
                    </div>
                    <div className="bottom">
                        <div className="showAll" onClick={_ => {
                            if (showAll === false) {
                                store.dispatch(getRequestAction(requests));
                            } else {
                                store.dispatch(getRequestPaginationAction(requests, 1));
                            }
                            setShowAll(!showAll)
                        }}>
                            Вывести все <img src={showAllSvg} alt=""/>
                        </div>
                    </div>
                </Pagination>
            </Buttons_>
        </Page>
    )
}

const mapStateToProps = state => {
    return {
        requests: state.pageReducer.requests
    }
}

export default connect(mapStateToProps, null)(Manager4)

