import React from 'react';

function OpenWord(props: any) {
    return (
        <svg {...props} width="35" height="42" viewBox="0 0 35 42" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.2713 0H5.83333C4.28624 0 2.80251 0.553123 1.70854 1.53769C0.614581 2.52226 0 3.85761 0 5.25V36.75C0 38.1424 0.614581 39.4777 1.70854 40.4623C2.80251 41.4469 4.28624 42 5.83333 42H29.1667C30.7138 42 32.1975 41.4469 33.2915 40.4623C34.3854 39.4777 35 38.1424 35 36.75V12.3559C34.9998 11.6597 34.6924 10.9922 34.1454 10.5L23.3333 0.769125C22.7865 0.276808 22.0447 0.000148673 21.2713 0V0ZM21.875 9.1875V3.9375L30.625 11.8125H24.7917C24.0181 11.8125 23.2763 11.5359 22.7293 11.0437C22.1823 10.5514 21.875 9.88369 21.875 9.1875ZM10.1646 18.0574L13.1862 28.9354L16.0942 19.341C16.179 19.0641 16.3625 18.8198 16.6165 18.6458C16.8704 18.4717 17.1808 18.3775 17.5 18.3775C17.8192 18.3775 18.1296 18.4717 18.3835 18.6458C18.6375 18.8198 18.821 19.0641 18.9058 19.341L21.8138 28.9354L24.8354 18.0574C24.9362 17.7267 25.1766 17.4447 25.5054 17.2716C25.8342 17.0985 26.2252 17.048 26.5948 17.131C26.9644 17.214 27.2833 17.4239 27.4832 17.7157C27.6831 18.0075 27.7482 18.3582 27.6646 18.6926L23.2896 34.4426C23.2108 34.7237 23.0308 34.9735 22.7777 35.1528C22.5247 35.3322 22.2127 35.4311 21.8906 35.4341C21.5685 35.4371 21.2544 35.344 20.9972 35.1694C20.7401 34.9948 20.5544 34.7485 20.4692 34.4689L17.5 24.6803L14.5308 34.4715C14.4456 34.7511 14.2599 34.9974 14.0028 35.172C13.7456 35.3466 13.4315 35.4397 13.1094 35.4367C12.7873 35.4337 12.4753 35.3348 12.2223 35.1554C11.9692 34.9761 11.7892 34.7264 11.7104 34.4452L7.33542 18.6953C7.28402 18.5266 7.2706 18.3503 7.29596 18.1769C7.32132 18.0035 7.38493 17.8364 7.48307 17.6854C7.58121 17.5344 7.71189 17.4026 7.86743 17.2977C8.02298 17.1929 8.20024 17.117 8.38882 17.0747C8.57739 17.0323 8.77346 17.0243 8.96552 17.0512C9.15757 17.078 9.34173 17.1391 9.50716 17.2309C9.6726 17.3227 9.81598 17.4433 9.92888 17.5857C10.0418 17.7281 10.1219 17.8894 10.1646 18.06V18.0574Z"/>
        </svg>
    );
}

export default OpenWord;