import {initialState} from "../initialState";
import {CLEAR_ERROR, LOCAL_STATUS_CHANGE, LOGIN_ERROR, LOGIN_SUCCESS} from "../actionTypes";

export function loginReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                userData: action.payload,
                loading: false,
                loginError: ""
            }
        case LOGIN_ERROR:
            return {...state, loginError: "Неверный логин или пароль",}
        case CLEAR_ERROR:
            return {...state, loginError: "",}
        case LOCAL_STATUS_CHANGE:
            return {...state, localClientStatus: action.payload}
        default:
            return state;
    }
}