import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import axios from "axios";
import {api} from "../config";
import {message, Transfer} from "antd";

const StyledMoversList = styled.div`
  display: flex;
  justify-content: center;
`;

function MoversList({requestId}) {

    const [movers, setMovers] = useState([]);
    const [allMovers, setAllMovers] = useState([]);

    const [targetMovers, setTargetMovers] = useState([]);

    const getAllMovers = () => {
        axios(`${api}/users/mover`).then(({data}) => {
            setAllMovers(data)
        })
    }
    const getCurrentMovers = () => {
        axios(`${api}/requests/${requestId}/movers`).then(({data}) => {
            setMovers(data)
            setTargetMovers(data.map(el => {
                return el.Id
            }));
        })
    }

    const addMover = (userId) => {
        axios.put(`${api}/requests/${requestId}/mover/${userId}`).then(({data}) => {
            message.success(`Движенец №${userId} успешно добавлен`)
        })
    }
    const removeMover = (userId) => {
        axios.delete(`${api}/requests/${requestId}/mover/${userId}`).then(({data}) => {
            message.warning(`Движенец №${userId} успешно убран`)
        })
    }

    const onTransferChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetMovers(nextTargetKeys);
        if (direction === "right") {
            moveKeys.forEach(key => {
                addMover(key);
            })
        }
        if (direction === "left") {
            moveKeys.forEach(key => {
                removeMover(key);
            })
        }
    }

    useEffect(() => {
        getAllMovers();
        getCurrentMovers();
    }, [])

    return (
        <StyledMoversList>

            <Transfer
                dataSource={[...allMovers]}
                titles={['Доступные', 'Добавленные']}
                targetKeys={targetMovers}
                onChange={onTransferChange}
                oneWay
                locale={{itemUnit: "", itemsUnit: ""}}
                rowKey={item => item.Id}
                render={mover => mover.Surname && mover.Name ? `${mover.Surname} ${mover.Name}` : mover.Login}/>

            {/*{allMovers.filter(el=>movers.includes(el)).map((mover, index) => (*/}
            {/*    <div key={index}>*/}
            {/*        {mover.Surname && mover.Name ? `${mover.Surname} ${mover.Name}` : mover.Login}*/}
            {/*        <Button>Добавить</Button>*/}
            {/*    </div>*/}
            {/*))}*/}
            {/*{movers.map((mover, index) => (*/}
            {/*    <div key={index}>*/}
            {/*        {mover.Surname && mover.Name ? `${mover.Surname} ${mover.Name}` : mover.Login}*/}
            {/*        <Button>Удалить</Button>*/}
            {/*    </div>*/}
            {/*))}*/}
        </StyledMoversList>
    );
}

export default MoversList;