import React, {Component} from "react";
import styled from 'styled-components';

import HexBorder from '../../components/HexagonalBorder';
import history from "../../utils/history";
import {Buttons} from "../../components/Buttons";
import RoundedButton from "../../components/RoundedButton";

const StyledButtons = styled(Buttons)`
  justify-content: flex-start;

  & > div {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
`;

const Page = styled.div`
  max-width: 1440px;
  padding: 45px 0;

`;

class Supervisor extends Component {

    render() {
        const theme = this.props.theme;

        return (
            <Page>
                <Buttons theme={theme}>
                    <RoundedButton onClick={_ => history.push("/request")} theme={theme}>
                        Создать новый запрос
                    </RoundedButton>
                    <RoundedButton onClick={_ => history.push("/supervisor/managers")} theme={theme}>
                        Запросы в работе
                    </RoundedButton>
                    <RoundedButton theme={theme} onClick={_ => history.push("/mover7")}>
                        История запросов
                    </RoundedButton>
                </Buttons>
            </Page>
        )
    }
}


export default Supervisor

