import React, {useEffect, useState} from "react";
import styled from 'styled-components';

import HexBorder from '../../components/HexagonalBorder';
import axios from "axios";
import {api} from "../../config";
import {Form, Input, message, Modal, Button as AntButton} from "antd";
import moment from "moment";
import Chat, {StyledChat, StyledChatWrap} from "../../components/Chat";
import MoversList from "../../components/MoversList";
import {connect} from "react-redux";
import RequestInfo, {TextYourRequest} from "../../components/RequestInfo";
import PrintSVG from "../../images/PrintSVG";
import EmailSVG from "../../images/EmailSVG";

export const NumBlock = styled.div`
  height: 100%;
`


export const TitleRequest = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 0 60px;
`

export const BlockRequest = styled.div`
  width: 100%;
  min-height: 418px;
  background: #FAFAFA;
  padding: 14px 5px;
  border-radius: 4px;


  display: flex;
  align-items: center;
  flex-direction: column;

  & .HexInput {
    width: 60%;
  }

  position: relative;

  &:before {
    content: "";
    pointer-events: none;
    position: absolute;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    top: -6px;
    left: -6px;
    border-radius: 8px;

    border: 3px solid rgba(255, 255, 255, 0.38);
  }
`

export const BlockList = styled.div`
  width: 100%;
  background: #FAFAFA;
  padding: 6px 14px;

  font-size: 18px;
  line-height: 22px;
  color: #000000;
  display: flex;
  margin-bottom: 8px;

  .response {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: 100%;

    .name {
      font-size: 14px;
    }

    .text {
      padding-left: 10px;
      word-break: break-word;
    }

    .time {
      place-self: flex-end;
      font-size: 12px;
    }
  }

  &:last-child {
    margin-bottom: 0px;
  }
`

export const BlockInputs = styled.div`
  width: 100%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & input {
    width: 100%;
    height: 30px;
    margin-bottom: 8px;
    border: 1px solid #AAAAAA;
    background: #0000;
    outline: none;
    padding-left: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

`


export const Print = styled.div`
  display: flex;
  font-size: 18px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.65);
  border-radius: 10px;
  padding: 5px;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  top: -65px;

  @media print {
    display: none;
  }
`;

export const Email = styled.div`
  display: flex;
  font-size: 18px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.65);
  border-radius: 10px;
  padding: 5px;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 65px;
  top: -65px;

  svg {
    width: 40px;
    height: 40px;
  }

  @media print {
    display: none;
  }
`;

export const Row = styled.div`
  margin: ${props => props.margin};
  display: flex;
  width: 100%;
  justify-content: ${(props) => props.justifyContent || "space-between"};

  @media print {
    gap: 20px;
    flex-wrap: wrap;
  }

  &.h-100p {
    height: 100%;
  }
`;

export const Column = styled.div`
  width: ${props => props.width};
  display: flex;
  align-items: flex-start;
  flex-direction: column;


  @media print {
    &.req_info {
      width: 100%;
    }

    width: 45%;
    gap: 40px;
  }
`

const Button = styled.div`
  cursor: pointer;
  width: 100%;
  height: 60px;
  border: 0;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;

  background: #FF7F26;

  &:hover {
    background: #Fa7F26;
  }

  &:active {
    background: #ea7F26;
  }

  &:focus {
    background: #FF7F26;
  }
`

export const Line = styled.div`
  margin: 20px auto 40px auto;
  width: 65%;
  background: #000;
  border-bottom: 1px solid #FF7F26;
`

export const Outline = styled.div`

  width: 100%;
  position: relative;

  &:before {
    content: "";
    pointer-events: none;
    position: absolute;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    top: -6px;
    left: -6px;

  }
`

export const RequestPage_ = styled.div`
  max-width: 1440px;
  overflow: hidden;
  padding: 45px 10px;


  ${StyledChat} {
    @media print {
      display: none;
    }
  }

  .req_answer {
    @media print {
      display: none;
    }
  }

  & ${Button} {
    color: ${props => props.theme.button.inBlock.color};
    background: ${props => props.theme.button.inBlock.background};
  }

  & ${Outline} {
    &:before {
      border: 3px solid ${props => props.theme.block.borderColor};
    }
  }

  & ${BlockList} {
    color: ${props => props.theme.block.color};
    background: ${props => props.theme.block.background};

    & input {
      border: 1px solid ${props => props.theme.borderColorInput};
    }
  }

  & ${BlockRequest} {

    background: ${props => props.theme.block.background};

    & ${TitleRequest} {
      color: ${props => props.theme.block.color};
    }

    & ${TextYourRequest} {
      color: ${props => props.theme.block.color};
    }

    & ${Line} {
      border-bottom: 1px solid ${props => props.theme.block.line};
    }

    &:before {
      border: 3px solid ${props => props.theme.block.borderColor};
    }
  }

`;

const MoverButton = styled(HexBorder)`
  float: right;
  margin-bottom: 40px;
`;

function RequestPage({theme, ...props}) {

    const [request, setRequest] = useState();
    const [responses, setResponses] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    const getRequests = () => {
        axios(`${api}/requests/${props.match.params.requestId}`).then(({data}) => {
            setRequest(data)
        }).catch(({response: {data}}) =>
            message.error(data)
        )
    }
    const getResponses = () => {
        axios(`${api}/requests/${props.match.params.requestId}/responses`).then(({data}) => {
            setResponses(data);
        }).catch(({response: {data}}) =>
            message.error(data)
        )
    }

    const sendResponse = ({response}) => {
        axios.put(`${api}/requests/${props.match.params.requestId}/response`, JSON.stringify(response)).then(() => {
            message.success("Ответ успешно отправлен");
            getResponses();
        })
    }
    const [products, setProducts] = useState([]);
    const [stations, setStations] = useState([]);
    const [railways, setRailways] = useState([]);

    useEffect(_ => {
        axios(`${api}/catalogs/products`).then(({data}) => {
            setProducts(data);
        });
        axios(`${api}/catalogs/stations`).then(({data}) => {
            setStations(data);
        });
        axios(`${api}/catalogs/railways`).then(({data}) => {
            setRailways(data);
        });

        getRequests();
        getResponses();
    }, [])


    return (
        <RequestPage_ theme={theme}>
            <Modal title={"Управление движенцами"} footer={null} visible={modalVisible}
                   onCancel={_ => setModalVisible(false)}>
                <MoversList requestId={props.match.params.requestId}/>
            </Modal>
            {/*{props.user.Role === 80 && <MoverButton*/}
            {/*    theme={{ color: `#000`,*/}
            {/*        background: `#fff`,*/}
            {/*        borderColor: `rgba(255, 255, 255, 0.38)`,}}*/}
            {/*    width={"250px"}*/}
            {/*    height={"60px"}*/}
            {/*    className={"HexInput"}>*/}

            {/*    <Button style={{height: "60px",color: `#000`, background: "#fff"}}*/}
            {/*            onClick={() => setModalVisible(true)}>Движенцы</Button>*/}
            {/*</MoverButton>}*/}
            <Row margin={"40px 0 0 0"} className={"h-100p"}>

                <Column width={"31%"} className={"req_info"}>
                    <BlockRequest>
                        <Print onClick={() => window.print()}>
                            <PrintSVG/>
                        </Print>
                        <Email onClick={() => window.open("https://e.mail.ru/compose/", '_blank',"noopener,noreferrer")}>
                            <EmailSVG/>
                        </Email>
                        <RequestInfo products={products} railways={railways} request={request} theme={theme}
                                     stations={stations}/>
                    </BlockRequest>
                </Column>
                <Column width={"31%"}>

                    <BlockRequest>
                        <HexBorder
                            theme={theme.button.inBlock}
                            width={"250px"}
                            height={"60px"}
                            className={"HexInput"}>

                            <Button style={{height: "60px"}}>Чат</Button>
                        </HexBorder>
                        <Line/>
                        <Chat theme={theme} requestId={props.match.params.requestId}/>
                    </BlockRequest>

                </Column>

                <Column width={"31%"} className={"send_chat"}>
                    <Outline>
                        {props.user.Role === 30 && <BlockList className={"req_answer"}>
                            <BlockInputs>
                                <StyledChat theme_={theme} layout="vertical"
                                            onFinish={sendResponse}>
                                    <Form.Item name={"response"} rules={[{
                                        required: true,
                                        message: "Обязательно к заполнению"
                                    }]}>
                                        <Input.TextArea placeholder={"Ответ на запрос"}/>
                                    </Form.Item>
                                    <Form.Item>
                                        <AntButton htmlType={"submit"}>Отправить</AntButton>
                                    </Form.Item>
                                </StyledChat>
                            </BlockInputs>
                        </BlockList>}
                        {responses.map((el, index) => (
                            <BlockList key={index}><NumBlock>{index + 1})</NumBlock>
                                <div className="response">
                                    <div className="name">
                                        {el.User.Surname && el.User.Name ? `${el.User.Surname} ${el.User.Name}` : el.User.Login}
                                    </div>
                                    <div className="text">
                                        {el.Text}
                                    </div>
                                    <div className="time">
                                        {moment(el.Time).format("DD.MM.YY HH:mm")}
                                    </div>
                                </div>
                            </BlockList>
                        ))}
                    </Outline>
                </Column>


            </Row>
        </RequestPage_>
    )
}


const mapStateToProps = state => {
    return {
        user: state.loginReducer.userData
    }
}

export default connect(mapStateToProps, null)(RequestPage)

