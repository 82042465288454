import {initialState} from "../initialState";
import {GET_REQUESTS_SUCCESS, LOGIN_SUCCESS} from "../actionTypes";

export function pageReducer(state = initialState, action) {
    switch (action.type) {
        case GET_REQUESTS_SUCCESS:
            return {
                requests: action.payload
            }
        default:
            return state;
    }
}