import React, {useContext, useEffect, useState} from "react";
import styled from 'styled-components';

import HexBorder from '../../components/HexagonalBorder';
import axios from "axios";
import {api} from "../../config";
import {BlockRequest, Column, Line, RequestPage_, Row} from "../requestPage/RequestPage";
import {message, Modal} from "antd";
import history from "../../utils/history";
import MoversList from "../../components/MoversList";
import {connect} from "react-redux";
import Chat from "../../components/Chat";
import {Button as Manager6Button} from "../manager6/Manager6";
import {useInterval} from "../../utils/useInterval";
import RequestInfo from "../../components/RequestInfo";
import {ThemeContext} from "../../css/Themes";
import SmallUserSVG from "../../images/SmallUserSVG";
import moment from "moment";

const RequestDesc = styled.div`
  margin-top: 40px;
  width: 50%;
  min-height: 418px;
  text-align: center;
  padding: 40px;

  position: relative;

  &:before {
    content: "";
    pointer-events: none;
    position: absolute;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    top: -6px;
    left: -6px;

  }

`

const SubGroupButtons = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-right: 1px;
  }

  &:last-child {
    margin-left: auto;
  }
`

const Buttons = styled.div`
  margin-top: 40px;
  display: flex;
  width: 100%;

  & button {
    cursor: pointer;
    min-width: 223px;
    height: 47px;
    border: 0;
    font-size: 18px;

  }

  & ${SubGroupButtons} button {
    min-width: 150px;
  }

  & ${SubGroupButtons} > * {
    margin-bottom: 25px;
  }
`
const Page = styled(RequestPage_)`
  max-width: 1440px;
  overflow: hidden;
  padding: 45px 10px;


  .requestInfo {
    max-width: 726px
  }

  ${Row} {
    ${Column} {
      &:not(:last-child) {
        margin-right: 10px;
      }

      &:first-child {
        width: 35%;
      }

      width: 30%;
    }

    .chat {
      max-width: 450px;
      width: 100%;
    }
  }

  & button {
    color: ${props => props.theme.button.color};
    background: ${props => props.theme.button.background};
  }

  & ${RequestDesc} {

    color: ${props => props.theme.block.color};
    background: ${props => props.theme.block.background};

    &:before {
      border: 3px solid ${props => props.theme.block.borderColor};
    }
  }
`;

const CloseComment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FAFAFA;
  border-radius: 4px;
  position: relative;
  transform-style: preserve-3d;
  padding: 30px 50px;

  h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 25px;
    color: #000000;
  }

  > span {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0 4px 8px 5px rgba(137, 137, 137, 0.25);
    border-radius: 13px;
    padding: 10px 20px 10px 10px;

    .name {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 5px;

      color: #686868;

      svg {
        margin-right: 7px;
      }
    }

    .text {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;

      color: #000000;
      display: flex;
      padding-left: 30px;
    }

    .date {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      margin-left: auto;

      color: #686868;
    }
  }

  &::before {
    position: absolute;
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    top: -3px;
    border-radius: 4px;
    border: 3px solid ${({theme}) => theme.svgColor};
    box-shadow: 0 0 0 3px #FFFFFF60;
    transform: translateZ(-1px);
  }
`;

const MoverButton = styled(HexBorder)`
  float: right;
  margin-bottom: 40px;
`;

function Manager5({match, ...props}) {

    const [responses, setResponses] = useState([]);

    const theme = useContext(ThemeContext);

    const [products, setProducts] = useState([]);
    const [stations, setStations] = useState([]);
    const [railways, setRailways] = useState([]);
    const [request, setRequest] = useState({});
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(_ => {
        axios(`${api}/requests/${match.params.requestId}`).then(({data}) => {
            setRequest(data)
        }).catch(({response: {data}}) =>
            message.error(data)
        )

        axios(`${api}/requests/${match.params.requestId}/responses`).then(({data}) => {
            setResponses(data);
        })
        axios(`${api}/catalogs/products`).then(({data}) => {
            setProducts(data);
        });
        axios(`${api}/catalogs/stations`).then(({data}) => {
            setStations(data);
        });
        axios(`${api}/catalogs/railways`).then(({data}) => {
            setRailways(data);
        });
        return (() => {
            setRequest({})

            setResponses([]);

            setProducts([]);

            setStations([]);

        })
    }, [])

    useInterval(() => {
        axios(`${api}/requests/${match.params.requestId}/responses`).then(({data}) => {
            let validated = data.map(el => {

                let coincidence = responses.find(ell => el.User.Id === ell.User.Id);
                if (coincidence?.Count !== el.Count || coincidence.front_updated !== undefined) {
                    return {...el, front_updated: true};
                }
                return el;
            })
            setResponses(validated);
        })
    }, 5000)

    const ListColumn1 = responses.map((response, index) =>
        <HexBorder key={index} theme={theme.button}>
            <button
                onClick={_ => history.push(`/manager6/${match.params.requestId}/${response.User.Id}`)}>{response.User.Surname && response.User.Name ? `${response.User.Surname} ${response.User.Name}` : response.User.Login}</button>
        </HexBorder>
    );

    const ListColumn2 = responses.map((response, index) =>
        <HexBorder key={index} theme={theme.button} background={response.front_updated ? "green" : undefined}>
            <button style={{
                cursor: 'default',
                minWidth: "auto",
                background: response.front_updated ? "green" : undefined
            }}>{response.Count}</button>
        </HexBorder>
    );

    return (
        <Page theme={theme}>
            <Modal title={"Управление движенцами"} footer={null} visible={modalVisible}
                   onCancel={_ => setModalVisible(false)}>
                <MoversList requestId={match.params.requestId}/>
            </Modal>
            <Row className={"h-100p"}>
                <Column className={"requestInfo"}>
                    <BlockRequest>
                        <RequestInfo products={products} railways={railways} request={request} theme={theme}
                                     stations={stations}/>
                    </BlockRequest>
                </Column>

                {request?.Status?.Value === "closed" ?
                    <Column>
                        <CloseComment theme={theme}>
                            <h1>Комментарий от сотрудника</h1>
                            <span>
                                <span className={"name"}>
                                    <SmallUserSVG/>
                                    {request?.Status?.Author}
                                </span>
                                <span className="text">
                                    {request?.Status?.Note}
                                </span>
                                <span className="date">
                                    {moment(request?.Status?.Date).format("DD.MM.YY HH:mm")}
                                </span>
                            </span>
                        </CloseComment>

                    </Column> : <>
                        <Column className={"chat"}>
                            <BlockRequest>
                                <HexBorder
                                    theme={theme.button.inBlock}
                                    width={"250px"}
                                    height={"60px"}
                                    className={"HexInput"}>

                                    <Manager6Button theme_={theme} style={{height: "60px"}}>Чат</Manager6Button>
                                </HexBorder>
                                <Line/>
                                <Chat theme={theme} requestId={match.params.requestId}/>
                            </BlockRequest>
                        </Column>
                        <Column>
                            <Buttons>
                                <SubGroupButtons>
                                    {ListColumn1}
                                </SubGroupButtons>
                                <SubGroupButtons>
                                    {ListColumn2}
                                </SubGroupButtons>
                            </Buttons>
                        </Column>
                    </>
                }
            </Row>
        </Page>
    )
}

const mapStateToProps = state => {
    return {
        user: state.loginReducer.userData
    }
}

export default connect(mapStateToProps, null)(Manager5)


