import React, {Component} from "react";
import styled from 'styled-components';

import HexBorder from '../../components/HexagonalBorder';
import history from "../../utils/history";
import {Buttons} from "../../components/Buttons";
import RoundedButton from "../../components/RoundedButton";

const Page = styled.div`
  max-width: 1440px;
  padding: 45px 0;

`;

class SupervisorMain extends Component {

    render() {
        const theme = this.props.theme;

        return (
            <Page>
                <Buttons theme={theme}>
                    <RoundedButton onClick={_ => history.push("/supervisor/pick")} theme={theme}>
                        Запросы
                    </RoundedButton>
                    <RoundedButton onClick={_ => history.push("/supervisor/requests")} theme={theme}>
                        Справочники
                    </RoundedButton>
                </Buttons>
            </Page>
        )
    }
}


export default SupervisorMain

