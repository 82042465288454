import React from 'react';
import styled from "styled-components";
import {api} from "../config";
import closeDoor from "../images/closeDoor.svg"
import MailSVG from "../images/MailSVG";
import {Link} from "react-router-dom";
import history from "../utils/history";
import ArrowRightSVG from "../images/ArrowRightSVG";

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  a {
    display: flex;
    align-items: center;
  }
`;

const StyledAdminLayout = styled.div`
  display: flex;
  flex-direction: column;
  background: #f7f7f8;
  min-height: 100vh;
  max-height: 100vh;

  .header {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .logo {
      display: flex;
      justify-content: center;
      margin: 12px 0 12px 0;
      font-size: 18px;
      line-height: 22px;
      font-weight: bold;
    }

    .text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: white;
      font-weight: 600;
      font-size: 24px;
      padding: 18px 35px;
      position: relative;

      svg {
        position: relative;
      }

      a {
        color: black;
        font-size: 18px;

        img {

          &:hover {
            opacity: .5;
          }
        }
      }
    }
  }
`;

function AdminLayout({children}) {
    return (
        <StyledAdminLayout>
            <div className="header">
                <div className="logo">
                    ЦРМ
                </div>
                <div className="text">
                    {history.location.pathname === "/admin" ? "Все сотрудники" : "Почтовый адрес"}
                    <Actions>
                        {history.location.pathname === "/admin" ?
                            <Link to={"/admin/emails"}>
                                <MailSVG style={{top: 2}}/>
                            </Link> :
                            <Link to={"/admin"}>
                                <ArrowRightSVG/>
                            </Link>}
                        <a href={`${api}/exit`} className="exit"><img src={closeDoor} alt=""/></a>
                    </Actions>
                </div>
            </div>
            {children}
        </StyledAdminLayout>
    );
}

export default AdminLayout;