import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Button, Form, Input} from "antd";
import axios from "axios";
import {api} from "../config";
import moment from "moment";
import {connect} from "react-redux";
import messageAva from "../images/messageAva.svg";
import Picker from "emoji-picker-react";
import Smile from "../images/Smile";

export const StyledChatWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
  flex: 1 1 auto;

  .messages {
    max-height: 400px;
    overflow-y: auto;
    padding: 10px;
    height: 100%;

    @media print {
      overflow: visible;
      max-height: none;
    }

    &::-webkit-scrollbar {
      width: 1em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
`;

const Bubble = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 4px 8px 5px rgba(137, 137, 137, 0.25);
  font-size: 18px;
  padding: 10px;
  margin-bottom: 20px;
  margin-right: 80px;
  background: white;

  &.notMy {
    margin-right: 0;
    margin-left: 80px;
    background: #FFE0CB;
  }

  .user {
    font-size: 14px;
    color: #686868;

    img {
      height: 23px;
      margin-right: 7px;
    }
  }

  .text {
    overflow-wrap: break-word;
    padding-left: 30px;
    line-height: 22px;
  }

  .time {
    place-self: flex-end;
    line-height: 10px;
    color: #686868;
    font-size: 14px;
  }
`

export const StyledChat = styled(Form)`
  display: flex;
  width: 100%;
  margin-top: 20px;

  .smile {
    cursor: pointer;
    position: absolute;
    height: 20px;
    width: 20px;
    top: 10px;
    right: 10px;
    opacity: .5;

    &:hover {
      opacity: .7;
    }
  }

  .emoji-picker-react {
    position: absolute;
    bottom: 65px;
    z-index: 999;
    right: 0;
  }

  .ant-row:first-child {
    width: 100%;
  }

  .ant-btn {
    background: ${(props) => props?.theme_?.button.inBlock.background};
    color: ${(props) => props?.theme_?.button.inBlock.color};
    height: 55px;
    font-size: 18px;
    margin-left: 10px;
    border: none;
    border-radius: 0;

    &:hover {
      background: ${(props) => props?.theme_?.button.inBlock.background};
      color: ${(props) => props?.theme_?.button.inBlock.color};
      opacity: .5;
    }
  }

  textarea {
    resize: none;
    border: 2px solid #E3E3E3;
    border-radius: 0;
    font-size: 18px;
    max-height: 55px;
    width: 100% !important;
    padding-right: 30px !important;

    &:hover, &:focus, &:active {
      border-right-width: 2px !important;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

function Chat({theme, requestId, userId, user, noSend}) {
    const [messages, setMessages] = useState([]);
    const [canSend, setCanSend] = useState(true);
    const [emojiVisible, setEmojiVisible] = useState(false);

    const [formRef] = Form.useForm();
    const messagesRef = React.createRef();

    const getMessages = () => {
        axios(`${api}/requests/${requestId}/messages${userId ? "/" + userId : ''}`).then(({data}) => {
            if (JSON.stringify(messages) !== JSON.stringify(data))
                setMessages(data)
        })
    }

    const handleSendMessage = ({message}) => {
        if (canSend) {
            setCanSend(false);
            axios.put(`${api}/requests/${requestId}/messages${userId ? "/" + userId : ''}`, JSON.stringify(message)).then(() => {
                formRef.resetFields();
                getMessages();
            }).finally(() => {
                setEmojiVisible(false);
                setCanSend(true);
            })
        }
    }

    useEffect(() => {
        getMessages();
        messagesRef.current.scrollTo(0, messagesRef.current.scrollHeight);
        const interval = setInterval(_ => {
            getMessages();
        }, 2000);
        return _ => {
            clearInterval(interval);
        }
    }, [messages])

    return (
        <StyledChatWrap onMouseLeave={() => setEmojiVisible(false)}>
            <div className="messages" ref={messagesRef}>
                {messages.map((el, index) => (
                    <Bubble key={index} className={user.Id !== el.User.Id ? "notMy" : ""}>
                        <div
                            className="user"><img src={messageAva}
                                                  alt="ava"/>{el.User.Surname && el.User.Name ? `${el.User.Surname} ${el.User.Name}` : el.User.Login}
                        </div>
                        <div className="text">{el.Text}</div>
                        <div className="time">{moment(el.Time).format("DD.MM.YY HH:mm")}</div>
                    </Bubble>
                ))}
            </div>
            {!noSend && <StyledChat theme_={theme} form={formRef} hideRequiredMark onFinish={handleSendMessage}>
                <Form.Item>
                    <Form.Item name={"message"} noStyle rules={[{
                        required: true,
                        message: "Необходимо ввести текст сообщения"
                    }]}>
                        <Input.TextArea onPressEnter={() => formRef.submit()} placeholder={"Сообщение"}/>
                    </Form.Item>
                    <Smile onMouseOver={() => setEmojiVisible(true)} className={"smile"}/>
                    {emojiVisible && <div onMouseLeave={() => setEmojiVisible(false)}>
                        <Picker
                            onEmojiClick={(e, ee) => formRef.setFieldsValue({message: (formRef.getFieldValue("message") || "") + ee.emoji})}
                            disableAutoFocus={true}
                            native
                        />
                    </div>}

                </Form.Item>
                <Form.Item>
                    <Button htmlType={"submit"}>Отправить</Button>
                </Form.Item>
            </StyledChat>}
        </StyledChatWrap>
    );
}

const mapStateToProps = state => {
    return {
        user: state.loginReducer.userData
    }
}

export default connect(mapStateToProps, null)(Chat);