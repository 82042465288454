import React, {useEffect, useState} from "react";
import styled from 'styled-components';

import HexBorder from '../../components/HexagonalBorder';
import axios from "axios";
import {api} from "../../config";
import moment from "moment";
import buttonArrowSvg from "../../images/buttonArrow.svg";
import {Input, message, Popover, Select} from "antd";
import showAllSvg from "../../images/showAll.svg";
import {Buttons_, Pagination, SubGroupButtons} from "../manager4/Manager4";
import {Row} from "../requestPage/RequestPage";
import {Buttons} from "../../components/Buttons";
import history from "../../utils/history";
import {getStationName} from "../../utils/getStationName";
import TekSelect, {StyledTekSelect} from "../../components/TekSelect";
import ArrowDownSvg from "../../images/ArrowDownSVG";
import EditPenSVG from "../../images/EditPenSVG";
import RoundedButton from "../../components/RoundedButton";
import RoundSelect, {StyledRoundSelect} from "../../components/RoundSelect";

const {Option} = Select;

const EditButton = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;

  color: #4B7389;
  padding: 20px 8px 20px 15px;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }
`;

const Page = styled.div`
  max-width: 1440px;
  padding: 45px 0;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;

  .secondList{
    .ant-select{
      margin-bottom: 19px;
    }
  }
  
  .pages{
    .ant-input{

      background: #FAFAFA;
      border: 1px solid #606060;
      border-radius: 8px;
      text-align: center;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }
  }
  
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner{
    background: none;
  }
  
  .ant-popover-inner-content {
    border: 1px solid #9c9c9c;
    background: #ffffff;
    border-radius: 4px;
    padding: 0;
  }

  ${StyledRoundSelect} {
    
  }
`;

function SupervisorRequestList({theme, match}) {
    const [page, setPage] = useState(1);
    const [requests, setRequests] = useState([]);
    const [stations, setStations] = useState([]);
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        getRequests(page || 1)
    }, [page])

    const getRequests = (page_) => {
        let urlParams = new URLSearchParams(window.location.search);

        axios(`${api}/requests${page_ ? "/pages/" + page_ : ""}?manager=${match.params.managerId}&mover=${urlParams.get("mover") || 0}${urlParams.get("status") ? `&status=${urlParams.get("status")}` : ""}${match.params.managerId !== "0" && (urlParams.get("start") && urlParams.get("end")) ? `&start=${urlParams.get("start")}&end=${urlParams.get("end")}` : ""}`).then(({data}) => {
            setRequests(data)
        })
        axios(`${api}/catalogs/stations`).then(({data}) => {
            setStations(data);
        });
    }

    const data = requests.Data ? requests.Data : requests;

    const handleReqStatusChange = (Id, status) => {
        if (status === "closed") {
            history.push(`/close/${Id}`);
        } else {
            axios.put(`${api}/requests/${Id}/status/${status}`).then(() => {
                getRequests(page || 1)
            }).catch(() => {
                message.error("Ошибка");
            })
        }
    }

    const ListColumn1 = data.map((request, index) =>
        <RoundedButton style={{width: "fit-content", marginLeft: 6}}
                       onClick={_ => history.push(`/supervisor/request/${request.Request.Id}`)} key={index}
                       theme={theme}>
            Запрос
            № {request.Request.Id} "{getStationName(stations, request.Request.SrcStation)} — {getStationName(stations, request.Request.DstStation)}"
            от {moment(request.Request.Created).format("DD.MM.YYYY")} <Popover
            content={<EditButton onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                history.push(`/edit/request/${request.Request.Id}`)
            }}><EditPenSVG/> Редактировать</EditButton>}
            getPopupContainer={_ => document.querySelector(`.${Page.styledComponentId}`)} placement={"bottom"}
            trigger={"click"}><span onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
        }} style={{zIndex: 99, padding: "0 14px", flex: "0 1 auto", width: 10}}><ArrowDownSvg/></span></Popover>
        </RoundedButton>
    );

    const ListColumn2 = data.map((request, index) =>
        <RoundSelect noPopCon value={request?.Request?.Status === "closed" ? "closed" : "work"} key={index}
                     onChange={(v) => handleReqStatusChange(request.Request.Id, v)}
                     theme={theme}>
            <Option value={"work"}>В работе</Option>
            <Option value={"closed"}>Закрыта</Option>
        </RoundSelect>
    );

    return (
        <Page>
            <Row justifyContent={"center"}>
                <Buttons theme={theme}>
                    <RoundedButton theme={theme}>
                        Список
                        запросов {(match.params.managerId !== "0" && requests?.Data?.length && !(new URLSearchParams(window.location.search).get("mover"))) ? (requests.Data[0].Manager.Surname && requests.Data[0].Manager.Name ? `${requests.Data[0].Manager.Surname} ${requests.Data[0].Manager.Name}` : requests.Data[0].Manager.Login) : (requests?.Data?.length === 0) ? "пуст" : (new URLSearchParams(window.location.search).get("mover") && requests?.Data?.length) ? (requests.Data[0].Mover.Surname && requests.Data[0].Mover.Name ? `${requests.Data[0].Mover.Surname} ${requests.Data[0].Mover.Name}` : requests.Data[0].Mover.Login) : ""}
                    </RoundedButton>
                </Buttons>
            </Row>

            <Buttons_ theme={theme} className={"items"}>
                <SubGroupButtons>
                    {ListColumn1}
                </SubGroupButtons>
                <SubGroupButtons className={"secondList"}>
                    {ListColumn2}
                </SubGroupButtons>
            </Buttons_>
            <Buttons_ theme={theme}>
                <Pagination>
                    <div className={`top ${showAll ? "hidden" : ""}`}>
                        <RoundedButton onClick={_ => (parseInt(page) - 1 >= 1) && setPage(parseInt(page) - 1)}
                                       theme={theme}>
                            <img
                                className="reversed"
                                src={buttonArrowSvg}
                                alt=""/> Предыдущая
                        </RoundedButton>
                        <div className="pages">
                            <Input value={page} onBlur={({target: {value}}) => {
                                if (value === "") {
                                    setPage(1);
                                }
                            }} onChange={({target: {value}}) => {
                                if (((value <= Math.ceil(requests.Total / 10)) && (value >= 1)) || value === "") {
                                    setPage(value);
                                }
                            }}/>
                            из {Math.ceil(requests.Total / 10)}
                        </div>
                        <RoundedButton
                            onClick={_ => (parseInt(page) + 1 <= Math.ceil(requests.Total / 10)) && setPage(parseInt(page) + 1)}
                            theme={theme}>
                            Следующая <img
                            src={buttonArrowSvg} alt=""/>
                        </RoundedButton>
                    </div>
                    <div className="bottom">
                        <div className="showAll" onClick={_ => {
                            if (showAll === false) {
                                getRequests();
                            } else {
                                getRequests(1);
                            }
                            setShowAll(!showAll)
                        }}>
                            Вывести все <img src={showAllSvg} alt=""/>
                        </div>
                    </div>
                </Pagination>
            </Buttons_>
        </Page>
    )
}


export default SupervisorRequestList

