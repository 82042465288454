import React, {Component} from "react";
import styled from 'styled-components';

import HexBorder from '../../components/HexagonalBorder';
import history from "../../utils/history";
import {Buttons} from "../../components/Buttons";

const Page = styled.div`
  padding: 45px 0;
	
`;

class Mover1 extends Component {

  render() {
    const theme = this.props.theme;

    return (
      <Page>

        <Buttons theme={theme}>
          <HexBorder theme={theme.button}>
            <button onClick={_=>history.push("/mover2")}>Запросы</button>
          </HexBorder>

          <HexBorder theme={theme.button}>
            <button disabled>Справочники</button>
          </HexBorder>
        </Buttons>

      </Page>
    )
  }
}


export default Mover1

