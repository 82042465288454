import React, {useEffect} from "react";
import styled from 'styled-components';

import HexBorder from '../../components/HexagonalBorder';
import {connect} from "react-redux";
import axios from "axios";
import store from "../../redux/store";
import {getRequestAction} from "../../redux/actions/pageActions";

const Page = styled.div`
  max-width: 1440px;
  padding: 45px 0;

`;

const SubGroupButtons = styled.div`
  display: flex;
`

const Buttons = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  & button {
    cursor: pointer;
    min-width: 223px;
    height: 47px;
    border: 0;

    font-size: 18px;
    color: ${props => props.theme.button.color};
    background: ${props => props.theme.button.background};
  }

  & ${SubGroupButtons} button {
    min-width: 100px;
    padding: 0 40px;
  }

  & ${SubGroupButtons} > * {
    &:last-child {
      margin-left: 60px;
    }
  }
`


function Manager3({theme, requests}) {

    useEffect(()=>{
        store.dispatch(getRequestAction())
    }, [])

    return (
        <Page>

            {requests?.map(req=>(
                <Buttons theme={theme}>
                    <HexBorder theme={theme.button}>
                        <button>Запрос № {req.Id} от 01.01.2021</button>
                    </HexBorder>

                    <SubGroupButtons>
                        <HexBorder theme={theme.button}>
                            <button>Сохранить</button>
                        </HexBorder>
                        <HexBorder theme={theme.button}>
                            <button>Отправить</button>
                        </HexBorder>
                    </SubGroupButtons>
                </Buttons>
            ))}

        </Page>
    )
}

const mapStateToProps = state => {
    return {
        requests: state.pageReducer.requests
    }
}

export default connect(mapStateToProps, null)(Manager3);

