import React from 'react';

function DeleteSvg(props: any) {
    return (
        <svg {...props} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.66797 10.3327H18.3346" stroke="#FF7F26" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M17.3346 10.334V18.006C17.3346 18.9247 16.6186 19.6673 15.736 19.6673H10.2666C9.38264 19.6673 8.66797 18.9233 8.66797 18.006V10.334"
                stroke="#FF7F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15 7.00065H11" stroke="#FF7F26" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M21.4853 4.51472C26.1715 9.20101 26.1715 16.799 21.4853 21.4853C16.799 26.1715 9.20099 26.1715 4.51472 21.4853C-0.171573 16.799 -0.171573 9.20099 4.51472 4.51472C9.20101 -0.171573 16.799 -0.171573 21.4853 4.51472"
                stroke="#FF7F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default DeleteSvg;