import React from 'react';

function EditPenSvg(props) {
    return (
        <svg {...props} width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M17.3018 3.34975L15.7678 1.91752C15.3403 1.51838 14.6482 1.51838 14.2218 1.91752L2.375 12.9772V15.8539H5.45614L17.3018 4.79423C17.7293 4.39509 17.7293 3.7489 17.3018 3.34975Z"
                  stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.28125 19.9372H20.9621" stroke="#323232" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M15.3529 6.61508L12.2695 3.73633" stroke="#323232" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
}

export default EditPenSvg;