import React from 'react';
import styled from "styled-components";
import {Select} from "antd";

export const StyledRoundSelect = styled(Select)`
  padding: 2px;
  border: 3px solid ${({theme}) => theme.button.borderColor};
  border-radius: 4px;
  min-width: 265px;
  line-height: 22px !important;

  &:hover {
    opacity: .8;
  }

  .ant-select-dropdown {
    width: 100% !important;
    min-width: auto !important;
  }

  .ant-select-clear {
    background: none;
  }

  .ant-select-selector {
    height: 100% !important;
    background: ${({theme}) => theme.button.background};
    border: 1px solid #9C9C9C !important;
    border-radius: 4px !important;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: ${({theme}) => theme.button.color};
    padding: 13px 38px !important;
    line-height: 22px !important;

    .ant-select-selection-item, .ant-select-selection-placeholder {
      text-align: center;
      line-height: 22px !important;
    }
  }
`;

function RoundSelect({children, noPopCon, ...props}) {
    return (
        <StyledRoundSelect {...props}
                         getPopupContainer={noPopCon ? undefined : () => document.querySelector("." + StyledRoundSelect.styledComponentId)}>
            {children}
        </StyledRoundSelect>
    );
}

export default RoundSelect;