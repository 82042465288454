import React from 'react';

function Train(props) {
    return (
        <svg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
             y="0px"
             viewBox="0 0 512 512" style={{enableBackground: "new 0 0 512 512"}} xmlSpace="preserve">
        <path d="M503.467,452.281h-102.4H307.2h-93.867h-93.867H8.533c-4.719,0-8.533,3.823-8.533,8.533s3.814,8.533,8.533,8.533h494.933
    c4.719,0,8.533-3.823,8.533-8.533S508.186,452.281,503.467,452.281z" fill="white"/>
            <path d="M477.867,366.948h-21.035l-5.692-8.533h1.126c14.592,0,25.6-18.338,25.6-42.667s-11.008-42.667-25.6-42.667H409.6v-17.067
    c0-11.213-7.287-20.659-17.348-24.115c0.521-1.604,1.067-3.2,1.306-4.958c1.246-9.233-1.724-18.176-7.893-24.678
    c2.577-8.755,2.364-19.806-3.721-28.57c-2.953-4.25-9.242-10.487-21.257-11.767c1.382-13.739-3.959-27.042-15.155-36.531
    c-13.747-11.639-32.469-14.959-49.323-9.455c-1.579-3.814-3.891-6.929-6.938-9.301c-3.678-2.867-8.124-4.514-13.449-4.966
    c2.031-13.252-0.845-26.3-8.422-37.086c-10.103-14.37-26.692-22.485-45.534-21.905c-20.233,0-40.388,10.957-50.159,27.264
    c-7.014,11.733-8.047,25.105-3.098,38.289c-20.369,16.998-22.502,39.799-14.959,55.936c7.689,16.469,26.991,29.705,53.88,25.139
    c3.507,6.997,10.146,13.431,18.662,17.69c11.776,5.888,25.105,6.844,37.658,2.97c1.28,7.996,5.299,13.756,9.199,17.621
    c15.172,15.044,43.776,16.853,65.843,12.177c5.632,4.42,13.107,7.33,20.864,8.422c-0.811,2.475-1.357,5.077-1.357,7.825v17.067
    h-44.544c-4.582-10.035-17.126-17.067-32.256-17.067c-15.13,0-27.674,7.031-32.256,17.067h-61.611v-17.067
    c0-4.71-3.814-8.533-8.533-8.533H59.733c-4.719,0-8.533,3.823-8.533,8.533v25.6c0,3.917,2.662,7.33,6.468,8.277l27.665,6.921
    v70.135H42.667c-4.719,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.814,8.533,8.533,8.533h42.667
    c0,18.825,15.309,34.133,34.133,34.133s34.133-15.309,34.133-34.133h25.6c0,18.825,15.309,34.133,34.133,34.133
    c18.825,0,34.133-15.309,34.133-34.133h25.6c0,18.825,15.309,34.133,34.133,34.133s34.133-15.309,34.133-34.133h25.6
    c0,18.825,15.309,34.133,34.133,34.133c18.825,0,34.133-15.309,34.133-34.133h42.667c4.719,0,8.533-3.823,8.533-8.533v-34.133
    C486.4,370.771,482.586,366.948,477.867,366.948z M347.674,224.714c-1.613-1.818-3.942-2.833-6.34-2.833
    c-0.725,0-1.459,0.094-2.185,0.282c-21.299,5.632-44.553,2.748-54.084-6.699c-4.343-4.309-5.564-9.89-3.738-17.067
    c0.879-3.405-0.435-6.997-3.294-9.045c-2.85-2.057-6.673-2.133-9.626-0.222c-15.633,10.163-29.389,5.197-34.594,2.603
    c-7.544-3.772-11.733-9.438-11.972-13.082c-0.179-2.577-1.51-4.941-3.627-6.426c-2.125-1.476-4.796-1.911-7.279-1.203
    c-22.912,6.673-36.847-3.422-41.83-14.071c-4.753-10.172-3.721-26.462,14.831-38.912c3.652-2.449,4.838-7.253,2.773-11.119
    c-5.41-10.086-5.53-19.576-0.367-28.203c6.579-10.991,21.513-18.97,35.721-18.97c13.039-0.358,24.602,5.026,31.36,14.652
    c6.605,9.395,7.748,21.589,3.14,33.459c-1.118,2.893-0.58,6.17,1.408,8.55c1.971,2.381,5.137,3.49,8.158,2.91
    c6.05-1.152,10.505-0.879,12.621,0.759c2.057,1.604,2.679,5.453,2.85,8.397c0.162,2.978,1.869,5.658,4.497,7.057
    s5.811,1.331,8.363-0.188c14.729-8.713,31.07-4.523,40.013,3.063c4.241,3.584,13.44,13.611,7.407,29.184
    c-1.092,2.825-0.606,6.016,1.271,8.38c1.877,2.372,4.898,3.55,7.868,3.149c5.769-0.819,13.397-0.725,16.879,4.292
    c3.277,4.719,2.577,12.663,0.043,17.323c-2.125,3.917-0.887,8.806,2.859,11.23c5.103,3.302,6.349,8.721,5.82,12.689
    c-0.418,3.098-1.929,5.564-3.61,6.101C363.708,233.521,352.205,229.792,347.674,224.714z M187.733,290.148H256
    c4.719,0,8.533-3.823,8.533-8.533c0-3.482,6.647-8.533,17.067-8.533c10.419,0,17.067,5.052,17.067,8.533
    c0,4.71,3.814,8.533,8.533,8.533h59.733c4.719,0,8.533-3.823,8.533-8.533v-25.6c0-4.702,3.823-8.533,8.533-8.533
    s8.533,3.831,8.533,8.533v25.6c0,4.71,3.814,8.533,8.533,8.533h51.2c2.125,0,8.533,9.079,8.533,25.6
    c0,16.521-6.409,25.6-8.533,25.6H435.2c-3.149,0-6.042,1.732-7.526,4.506c-1.485,2.773-1.314,6.144,0.427,8.755l8.226,12.331
    H187.733V290.148z M119.467,435.215c-9.412,0-17.067-7.654-17.067-17.067h34.133C136.533,427.569,128.879,435.215,119.467,435.215
    z M213.333,435.215c-9.412,0-17.067-7.654-17.067-17.067H230.4C230.4,427.569,222.746,435.215,213.333,435.215z M307.2,435.215
    c-9.412,0-17.067-7.654-17.067-17.067h34.133C324.267,427.569,316.612,435.215,307.2,435.215z M401.067,435.215
    c-9.412,0-17.067-7.654-17.067-17.067h34.133C418.133,427.569,410.479,435.215,401.067,435.215z M469.333,401.09H51.2v-0.009
    v-17.067h42.667c4.719,0,8.533-3.823,8.533-8.533v-85.333c0-3.917-2.671-7.33-6.468-8.277l-27.665-6.912v-10.402h102.4V375.49
    c0,4.71,3.814,8.533,8.533,8.533h290.133V401.09z" fill="white"/>
    </svg>
    );
}

export default Train;