import React from 'react';
import styled from "styled-components";

const StyledEditSvg = styled.svg`

`;

function EditSvg(props: any) {
    return (
        <StyledEditSvg {...props} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                       xmlnsXlink="http://www.w3.org/1999/xlink"
                       x="0px" y="0px"
                       viewBox="0 0 58.985 58.985"
                       xmlSpace="preserve">
            <g>
                <polygon style={{fill: "#E0A370"}} points="1,57.985 13.728,53.743 5.243,45.257 	"/>
                <path style={{fill: "#CC4B4C"}} d="M57.228,1.757c-2.343-2.343-6.142-2.343-8.485,0l-2.121,2.121l8.485,8.485l2.121-2.121
    C59.571,7.899,59.571,4.1,57.228,1.757z"/>

                <rect x="22.76" y="2.966" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 27.7247 71.9338)"
                      style={{fill: "#E39700"}} width="12" height="54.518"/>

                <rect x="1.512" y="28.25" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 70.445 31.3207)"
                      style={{fill: "#FFCB43"}} width="54.447" height="4"/>
                <polygon style={{fill: "#959595"}}
                         points="52.278,15.192 52.278,15.192 55.107,12.364 55.107,12.364 54.4,13.071 	"/>
                <polygon style={{fill: "#F2ECBF"}}
                         points="45.914,4.586 43.793,6.707 52.278,15.192 54.4,13.071 55.107,12.364 46.621,3.879 	"/>
                <path style={{fill: "#949493"}} d="M1,58.985c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l0.985-0.985
    c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-0.985,0.985C1.512,58.888,1.256,58.985,1,58.985z"/>
            </g>
        </StyledEditSvg>
    );
}

export default EditSvg;