import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Form, Input, message, Select} from "antd";
import HexBorder from "../../components/HexagonalBorder";
import store from "../../redux/store";
import {editRequestAction, submitRequestAction} from "../../redux/actions/pageActions";
import axios from "axios";
import {api} from "../../config";
import history from "../../utils/history";
import OpenWord from "../../images/OpenWord";
import SendMail from "../../images/SendMail";
import KeyboardSvg from "../../images/KeyboardSVG";
import {localGoBack} from "../../utils/localHistory";
import * as _ from "lodash";

const {Option, OptGroup} = Select;

const Button = styled.button`
  cursor: pointer;
  font-size: 18px;
  border: none;
  width: 240px;
  height: 47px;
  color: ${props => props.theme.button.inBlock.color};
  background: ${props => props.theme.button.inBlock.background};
`;

const StyledEditRequest = styled.div`
  background: #FAFAFA;
  width: 100%;
  min-height: 100px;
  box-shadow: 0 0 43px 6px rgba(255, 255, 255, 0.4);
  margin-top: 10px;
  padding: 50px 30px 50px;
  position: relative;

  .typeRequest {
    position: absolute;
    right: 130px;
    top: 60px;
    width: 360px;
  }

  .actions {
    position: absolute;

    svg {
      cursor: pointer;

      &:first-child {
        margin-right: 20px;
      }

      path {
        fill: ${props => props.theme.svgColor};
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
  }

  .saveBtn:not(:last-child) {
    margin-right: 50px;
  }

  h1 {
    display: flex;
    font-size: 42px;
    font-weight: 600;
    justify-content: center;
    width: 100%;
    margin-bottom: 75px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;

  .ant-select-selector {
    overflow: hidden;
  }

  .ant-select-selection-overflow-item {
    align-self: auto;
  }

  .ant-select-selection-item-remove {
    margin-left: auto;
  }

  input::placeholder {
    color: #4b7389;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    color: #4B7389;
  }

  .group {
    width: 100%;

    &:first-child {
      margin-right: 50px;
    }

    .ant-row {
      justify-content: space-between;
      align-items: center;
      display: flex;
      margin-bottom: 10px;

      label {
        padding-right: 6px;
        max-width: 304px;
        white-space: break-spaces;
        text-align: left;
        line-height: 22px;
        font-weight: 500;
        font-size: 18px;
        max-height: 44px;
        height: 100%;

        svg {
          top: 10px;
          position: relative;
        }

        span {
          color: ${props => props.theme.block.line};
        }

        ::after {
          display: none;
        }
      }

      .ant-col {
        overflow: visible;
        max-width: 357px;

        input, .ant-select-selector {
          .ant-select-selection-item {
            top: 7px;
          }

          min-height: 50px;
          font-size: 18px;
        }
      }
    }
  }
`;


function FormItem({children, forceFirstElement, ...props}) {
    const handleFocusNext = (event) => {
        if (event.key !== "Enter") {
            return;
        }

        let node = event.currentTarget;
        let element;

        const getNextNode = (nextNode) => {
            element = nextNode.nextElementSibling || forceFirstElement;
            console.log("element", element);
            if (element?.style.display === 'none') {
                console.log(element.style.display);
                return getNextNode(element);
            } else {
                return element;
            }
        }

        let nextElement = getNextNode(node);
        console.log(nextElement);
        nextElement?.querySelector("input")?.focus();
    }


    return <Form.Item onKeyDown={handleFocusNext} {...props}>
        {children}
    </Form.Item>
}

function EditRequest({match, ...props}) {
    const [formRef] = Form.useForm();

    const [products, setProducts] = useState([]);
    const [stations, setStations] = useState([]);
    const [railWays, setRailWays] = useState([]);

    const [station1Loading, setStation1Loading] = useState(false);
    const [station1Data, setStation1Data] = useState([]);

    const [station2Loading, setStation2Loading] = useState(false);
    const [station2Data, setStation2Data] = useState([]);

    const [requestType, setRequestType] = useState(null);

    const [manuallySrcRailways, setManuallySrcRailways] = useState(false);

    const [manuallyDstRailways, setManuallyDstRailways] = useState(false);

    const [manuallySrcStation, setManuallySrcStation] = useState(false);

    const [manuallyDstStation, setManuallyDstStation] = useState(false);

    useEffect(()=>{
        console.log("requestType", requestType);
        console.log("formRef.getFieldsValue(\"TypeRequest\")", formRef.getFieldValue("TypeRequest"));
        console.log("formRef.getFieldsValue(\"TypeRequest\")", formRef.getFieldValue("TypeRequest") !== 0);
    }, [requestType])

    useEffect(() => {
        if (localStorage.getItem(`crm_request_fields_${match.params.id}`) === null) {
            axios(`${api}/requests/${match.params.id}`).then(({data}) => {
                formRef.setFieldsValue(data);

            });
        }

        axios(`${api}/catalogs/products`).then(({data}) => {
            setProducts(data);
        });
        axios(`${api}/catalogs/stations`).then(({data}) => {
            setStations(data);
        });
        axios(`${api}/catalogs/railways`).then(({data}) => {
            setRailWays(data);
        });
    }, []);


    const getGroups = () => {
        return products?.reduce((arr, el) => {
            if (!arr.includes(el.Group)) {
                arr.push(el.Group);
            }
            return arr;
        }, [])
    }

    const handleSubmit = (fields) => {
        // return console.log(fields)

        store.dispatch(editRequestAction(match.params.id, fields));
        localStorage.removeItem(`crm_request_fields_${match.params.id}`);
    }

    const loadFilteredStations = async (type, stations) => {
        if (stations?.length === 0)
            setStation1Data([]);


        if (type === 1) {
            setStation1Data([]);
            setStation1Loading(true);
        } else {
            setStation2Data([]);
            setStation2Loading(true);
        }

        let railwaysMap = [];
        for (let station of stations) {
            await axios(`${api}/catalogs/railways/${station}/stations`).then(({data}) => {
                railwaysMap = [...railwaysMap, ...data]
            })
        }
        console.log(railwaysMap);

        if (type === 1) {
            setStation1Loading(false);
            setStation1Data(railwaysMap);
        } else {
            setStation2Loading(false);
            setStation2Data(railwaysMap);
        }
    }

    const handleExportDocx = () => {
        formRef.validateFields().then(fields => {
            axios.post(`${api}/requests/export/docx`, fields, {
                responseType: "blob"
            }).then(({data, headers}) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;

                let filename = "";
                let disposition = headers["content-disposition"];

                if (disposition && disposition.indexOf('attachment') !== -1) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                }

                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
        })
    }

    return (
        <StyledEditRequest theme={props.theme}>
            <div className="actions">
                <a href="https://mail.yandex.ru/#compose" target="_blank" rel="noopener noreferrer">
                    <SendMail/>
                </a>
                <OpenWord onClick={handleExportDocx}/>
            </div>
            <h1>Запрос № {match.params.id}</h1>
            <StyledForm hideRequiredMark
                        initialValues={JSON.parse(localStorage.getItem(`crm_request_fields_${match.params.id}`))}
                        onFinish={handleSubmit} form={formRef} theme={props.theme} colon={false}>
                <div className="group">
                    <div className="typeRequest">
                        <FormItem rules={[{
                            required: true,
                            message: "Обязательно к заполнению"
                        }]} name={"TypeRequest"}>
                            <Select placeholder={"Тип запроса"} value={requestType} onChange={v => setRequestType(v)}>
                                <Option value={0}>Запрос ставки</Option>
                                <Option value={1}>Запрос ставки и возможность обеспечения</Option>
                            </Select>
                        </FormItem>
                    </div>
                    <FormItem rules={[{
                        required: true,
                        message: "Обязательно к заполнению"
                    }]} name={"Code"} label={<div>Код груза по ЕТСНГ<span>*</span>:</div>}>
                        <Select
                            showSearch
                            placeholder={"Силикат, ЕТСНГ 246010"} optionFilterProp="children"
                            filterOption={(input, option) => {
                                if (option.children) {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toString().indexOf(input.toString()) >= 0;
                                } else if (option.label) {
                                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                            }}
                            filterSort={(optionA, optionB) => {
                                if (optionA.children) {
                                    return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                } else if (optionA.label) {
                                    return optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
                                }
                            }
                            }>
                            {getGroups().map((group, index) => (
                                <OptGroup key={index} label={group}>
                                    {products.filter(el => el.Group === group).map((product, indexB) => (
                                        <Option key={`${index}_${indexB}`} value={product.Code}>
                                            {`${product.Name}(${product.Code})`}
                                        </Option>
                                    ))}
                                </OptGroup>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem style={{display: manuallySrcStation ? "flex" : "none"}} rules={[{
                        required: false,
                        message: "Обязательно к заполнению"
                    }]} name={"SrcStationString"} label={<div>Станция отправления<span>*</span>: <KeyboardSvg
                        className={manuallySrcStation ? "active" : ""}
                        onClick={() => setManuallySrcStation(!manuallySrcStation)}/></div>}>
                        <Select
                            mode="tags"
                            allowClear
                            placeholder={"Новотроицк"}
                        />
                    </FormItem>
                    <FormItem style={{display: manuallySrcStation ? "none" : "flex"}} rules={[{
                        required: false,
                        message: "Обязательно к заполнению"
                    }]} name={"SrcStation"} label={<div>Станция отправления<span>*</span>: <KeyboardSvg
                        className={manuallySrcStation ? "active" : ""}
                        onClick={() => setManuallySrcStation(!manuallySrcStation)}/></div>}>
                        <Select
                            loading={station1Loading}
                            mode="multiple"
                            onChange={(selectedStations) => {
                                let codesToSelect = [];
                                for (let selectedStation of selectedStations) {
                                    stations.filter(station => station.Code === selectedStation).forEach(station => codesToSelect.push(station.RailwayCode))
                                }
                                formRef.setFieldsValue({
                                    SrcRailways: _.uniq([...(formRef.getFieldValue("SrcRailways") || []), ...codesToSelect])
                                })
                            }}
                            allowClear
                            placeholder={"Новотроицк"}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                if (option.children) {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toString().indexOf(input.toString()) >= 0;
                                } else if (option.label) {
                                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                            }}
                            filterSort={(optionA, optionB) => {
                                if (optionA.children) {
                                    return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                } else if (optionA.label) {
                                    return optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
                                }
                            }
                            }
                        >
                            {formRef.getFieldValue("SrcRailways")?.length > 0 && station1Data.length > 0 ? station1Data.map((station, index) => (
                                <Option key={index} value={station.Code}>
                                    {station.FullName}
                                </Option>
                            )) : stations.map((station, index) => (
                                <Option key={index} value={station.Code}>
                                    {station.FullName}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem style={{display: manuallySrcRailways ? "flex" : "none"}} rules={[{
                        required: false,
                        message: "Обязательно к заполнению"
                    }]} name={"SrcRailwaysString"} label={<div>Дорога отправления<span>*</span>: <KeyboardSvg
                        className={manuallySrcRailways ? "active" : ""}
                        onClick={() => setManuallySrcRailways(!manuallySrcRailways)}/></div>}>
                        <Select
                            mode="tags"
                            allowClear
                            placeholder={"ЮУР ж.д."}
                        />
                    </FormItem>
                    <FormItem style={{display: manuallySrcRailways ? "none" : "flex"}} rules={[{
                        required: false,
                        message: "Обязательно к заполнению"
                    }]} name={"SrcRailways"} label={<div>Дорога отправления<span>*</span>: <KeyboardSvg
                        className={manuallySrcRailways ? "active" : ""}
                        onClick={() => setManuallySrcRailways(!manuallySrcRailways)}/></div>}>
                        <Select

                            onBlur={(value) => {
                                loadFilteredStations(1, formRef.getFieldValue("SrcRailways") || []);
                            }}
                            onDeselect={() => {
                                loadFilteredStations(1, formRef.getFieldValue("SrcRailways") || [])
                            }}

                            mode="multiple"
                            showSearch
                            allowClear
                            placeholder={"ЮУР ж.д."}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                if (option.children) {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toString().indexOf(input.toString()) >= 0;
                                } else if (option.label) {
                                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                            }}
                            filterSort={(optionA, optionB) => {
                                if (optionA.children) {
                                    return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                } else if (optionA.label) {
                                    return optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
                                }
                            }
                            }
                        >
                            {railWays.map((railway, index) => (
                                <Option key={index} value={railway.Code}>
                                    {railway.FullName}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem name={"Sender"} label={<div>Отправитель, ОКПО, код грузоотправителя:</div>}>
                        <Input placeholder={"АО \"НСХЗ\", ОКПО 05762312, ТГНЛ 3998"}/>
                    </FormItem>
                    <FormItem name={"IndexSender"} label={<div>Индекс, адрес отправителя:</div>}>
                        <Input/>
                    </FormItem>
                    <FormItem rules={[{
                        required: true,
                        message: "Обязательно к заполнению"
                    }]} name={"CountCar"} label={<div>Количество вагонов<span>*</span>:</div>}>
                        <Input placeholder={"5"}/>
                    </FormItem>
                    <FormItem rules={[{
                        required: true,
                        message: "Обязательно к заполнению"
                    }]} name={"Type"} label={<div>Вид подвижного состава<span>*</span>:</div>}>
                        <Input placeholder={"Крытый"}/>
                    </FormItem>
                    <FormItem rules={[{
                        required: true,
                        message: "Обязательно к заполнению"
                    }]} name={"SpecialConditions"}
                              label={<div>Особые условия и требования к вагонам<span>*</span>:</div>}>
                        <Input placeholder={"ГП от 67 тонн"}/>
                    </FormItem>
                    <FormItem style={{display: manuallyDstStation ? "flex" : "none"}} rules={[{
                        required: false,
                        message: "Обязательно к заполнению"
                    }]} name={"DstStationString"} label={<div>Станция назначения<span>*</span>: <KeyboardSvg
                        className={manuallyDstStation ? "active" : ""}
                        onClick={() => setManuallyDstStation(!manuallyDstStation)}/></div>}>
                        <Select
                            mode="tags"
                            allowClear
                            placeholder={"Новотроицк"}
                        />
                    </FormItem>
                    <FormItem
                        forceFirstElement={document.querySelector(`.${StyledEditRequest.styledComponentId} .group:nth-child(2) .ant-row`)}
                        style={{display: manuallyDstStation ? "none" : "flex"}}
                        rules={[{
                            required: false,
                            message: "Обязательно к заполнению"
                        }]}
                        name={"DstStation"}
                        label={<div>Станция назначения<span>*</span>: <KeyboardSvg
                            className={manuallyDstStation ? "active" : ""}
                            onClick={() => setManuallyDstStation(!manuallyDstStation)}/></div>}
                    >
                        <Select
                            loading={station2Loading}
                            mode="multiple"
                            showSearch
                            allowClear
                            onChange={(selectedStations) => {
                                let codesToSelect = [];
                                for (let selectedStation of selectedStations) {
                                    stations.filter(station => station.Code === selectedStation).forEach(station => codesToSelect.push(station.RailwayCode))
                                }
                                formRef.setFieldsValue({
                                    DstRailways: _.uniq([...(formRef.getFieldValue("DstRailways") || []), ...codesToSelect])
                                })
                            }}
                            placeholder={"Новотроицк"}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                if (option.children) {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toString().indexOf(input.toString()) >= 0;
                                } else if (option.label) {
                                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                            }}
                            filterSort={(optionA, optionB) => {
                                if (optionA.children) {
                                    return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                } else if (optionA.label) {
                                    return optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
                                }
                            }
                            }
                        >
                            {formRef.getFieldValue("DstRailways")?.length > 0 && station2Data.length > 0 ? station2Data.map((station, index) => (
                                <Option key={index} value={station.Code}>
                                    {station.FullName}
                                </Option>
                            )) : stations.map((station, index) => (
                                <Option key={index} value={station.Code}>
                                    {station.FullName}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                </div>
                <div className="group">
                    <FormItem style={{display: manuallyDstRailways ? "flex" : "none"}} rules={[{
                        required: false,
                        message: "Обязательно к заполнению"
                    }]} name={"DstRailwaysString"} label={<div>Дорога назначения<span>*</span>: <KeyboardSvg
                        className={manuallyDstRailways ? "active" : ""}
                        onClick={() => setManuallyDstRailways(!manuallyDstRailways)}/></div>}>
                        <Select
                            mode="tags"
                            allowClear
                            placeholder={"ЮУР ж.д."}
                        />
                    </FormItem>
                    <FormItem style={{display: manuallyDstRailways ? "none" : "flex"}} rules={[{
                        required: false,
                        message: "Обязательно к заполнению"
                    }]} name={"DstRailways"} label={<div>Дорога назначения<span>*</span>: <KeyboardSvg
                        className={manuallyDstRailways ? "active" : ""}
                        onClick={() => setManuallyDstRailways(!manuallyDstRailways)}/></div>}>
                        <Select

                            onBlur={(value) => {
                                loadFilteredStations(2, formRef.getFieldValue("DstRailways") || []);
                            }}
                            onDeselect={() => {
                                loadFilteredStations(2, formRef.getFieldValue("DstRailways") || [])
                            }}
                            mode="multiple"
                            allowClear
                            showSearch
                            placeholder={"ЮУР ж.д."}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                if (option.children) {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toString().indexOf(input.toString()) >= 0;
                                } else if (option.label) {
                                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                            }}
                            filterSort={(optionA, optionB) => {
                                if (optionA.children) {
                                    return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                } else if (optionA.label) {
                                    return optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
                                }
                            }
                            }
                        >
                            {railWays.map((railway, index) => (
                                <Option key={index} value={railway.Code}>
                                    {railway.FullName}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem name={"Recipient"} label={<div>Получатель, код ОКПО:</div>}>
                        <Input/>
                    </FormItem>
                    <FormItem name={"AddressRecipient"} label={<div>Адрес получателя:</div>}>
                        <Input/>
                    </FormItem>
                    <FormItem name={"Buyer1"} label={<div>Плательщик ж.д. тарифа за груженный вагон:</div>}>
                        <Input/>
                    </FormItem>
                    <FormItem name={"Buyer2"}
                              label={<div>Плательщик ж.д. тарифа за возврат порожнего вагона:</div>}>
                        <Input/>
                    </FormItem>
                    <FormItem rules={[{
                        required: true,
                        message: "Обязательно к заполнению"
                    }]} name={"Date"} label={<div>Период действия заявки<span>*</span>:</div>}>
                        <Input placeholder={"С 15.04.2019"}/>
                    </FormItem>
                    <FormItem rules={[{
                        required: formRef.getFieldValue("TypeRequest") !== 0,
                        message: "Обязательно к заполнению"
                    }]} name={"Schedule"}
                              label={<div>График подачи вагонов{formRef.getFieldValue("TypeRequest") !== 0 && <span>*</span>}:</div>}>
                        <Input/>
                    </FormItem>
                    <FormItem label={<div>Ставка{formRef.getFieldValue("TypeRequest") !== 0 && <span>*</span>}:</div>}>
                        <Input.Group compact style={{display: "flex"}}>
                            <FormItem rules={[{
                                required: formRef.getFieldValue("TypeRequest") !== 0,
                                message: "Обязательно к заполнению"
                            }]} noStyle name={"Price"}>
                                <Input style={{width: '50%'}}/>
                            </FormItem>
                            <FormItem rules={[{
                                required: formRef.getFieldValue("TypeRequest") !== 0,
                                message: "Обязательно к заполнению"
                            }]} noStyle name={"Tax"}>
                                <Select placeholder={"НДС 20%"} style={{width: '48%', marginLeft: "auto"}}>
                                    <Option value={"НДС 20%"}>НДС 20%</Option>
                                    <Option value={"НДС 0%"}>НДС 0%</Option>
                                    <Option value={"без НДС"}>без НДС</Option>
                                </Select>
                            </FormItem>
                        </Input.Group>
                    </FormItem>
                    <FormItem name={"Note"} label={<div>Примечание:</div>}>
                        <Input placeholder={""}/>
                    </FormItem>
                    {/*<FormItem rules={[{*/}
                    {/*    required: true,*/}
                    {/*    message: "Обязательно к заполнению"*/}
                    {/*}]} name={"Accept1"}*/}
                    {/*           label={<div>Наличие согласия на погрузку в вагоны Исполнителя<span>*</span>:</div>}>*/}
                    {/*    <Input/>*/}
                    {/*</FormItem>*/}
                    {/*<FormItem rules={[{*/}
                    {/*    required: true,*/}
                    {/*    message: "Обязательно к заполнению"*/}
                    {/*}]} name={"Accept2"} label={<div>Наличие подтверждения на прием груза<span>*</span>:</div>}>*/}
                    {/*    <Input/>*/}
                    {/*</FormItem>*/}
                </div>
            </StyledForm>
            <div className="buttons">
                {/*<HexBorder className="saveBtn" width={"240px"} height={"47px"} theme={props.theme.button.inBlock}>*/}
                {/*    <Button theme={props.theme} onClick={_ => {*/}
                {/*        localStorage.setItem(`crm_request_fields_${match.params.id}`, JSON.stringify(formRef.getFieldsValue()))*/}
                {/*        message.success("Сохранено!")*/}
                {/*        localGoBack();*/}
                {/*    }}>Сохранить</Button>*/}
                {/*</HexBorder>*/}
                <HexBorder className="saveBtn" width={"240px"} height={"47px"} theme={props.theme.button.inBlock}>
                    <Button theme={props.theme} onClick={_ => formRef.submit()}>Сохранить</Button>
                </HexBorder>
                <HexBorder className="saveBtn" width={"240px"} height={"47px"} theme={props.theme.button.inBlock}>
                    <Button theme={props.theme} onClick={_ => {
                        localGoBack();
                    }}>Отмена</Button>
                </HexBorder>
            </div>
        </StyledEditRequest>
    );
}

export default EditRequest;