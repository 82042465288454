import styled from "styled-components";

export const Buttons = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  & button {
    min-width: 223px;
    height: 47px;
    border: 0;

    font-size: 18px;
    color: ${props => props.theme.button.color};
    background: ${props => props.theme.button.background};
    cursor: pointer;


    &:disabled {
      cursor: not-allowed;
    }
  }
`
