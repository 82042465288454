import Or_Ge_Blu_Background from "../images/Background1.png";
// import Dark_Background from "../images/DarkThemeBG.png";
import Dark_Background from "../images/DarkThemeBG.png";
import White1_Background from "../images/WhiteTheme1BG.png";
import White2_Background from "../images/WhiteTheme2BG.png";
import React from "react";

/*
Фон (картинка, цвет)          - background

цвет заголовка в Welcom       - colorWelcom
цвет даты в layout            - colorDate
цвет кнопки "назад" в layout  - colorBack

цвет текста за блоком         - color
цвет текста в блоке           - colorInBlock

цвет обводки                  - borderColor
цвет блока                    - backgroundBlock

Кнопки - Button:
  на фоне страницы:
    цвет текста в кнопке  - Color
    цвет кнопки           - Background
    hover:
      цвет кнопки           - Background
    action:
      цвет кнопки           - Background
    focus:
      цвет кнопки           - Background

  на фоне блока - InBlock:
    цвет текста в кнопке  - Color
    цвет кнопки           - Background
    hover:
      цвет кнопки           - Background
    action:
      цвет кнопки           - Background
    focus:
      цвет кнопки           - Background

*/

export const Themes = {
  Orange: {
    svgColor: "#FF7F26",
    // background: `#FF7F26 url(${Or_Ge_Blu_Background})`,
    background: `#FF7F26`,
    colorWelcome: `#FAFAFA`,
    colorDate: `#000`,
    colorBack: `#FFF`,
    color: `#FFF`,
    borderColorInput: `#AAAAAA`, 

    block: {
      line: `#FF7F26`,
      color: `#000`,
      borderColor: `rgba(255, 255, 255, 0.38)`,
      background: `#FAFAFA`,
    },

    input:{
      color: `#000`,
      background: `#FFF`,
      borderColor: `rgba(255, 255, 255, 0.38)`,
      svg: `#676767`,
    },

    button: {
      color: `#000`,
      background: `#FFF`,
      borderColor: `rgba(255, 255, 255, 0.38)`,
      hover: {background:``},
      action: {background:``},
      focus: {background:``},
      inBlock: {
        color: `#fff`,
        background: `#FF7F26`,
        borderColor: `rgba(255, 127, 38, 0.38)`,
        hover: {background:``},
        action: {background:``},
        focus: {background:``},
      },
    },
  },

  Dark: {
    svgColor: "#272B36",
    // background: `#272B36 url(${Dark_Background})`,
    background: `#272B36`,
    colorWelcome: `#FFFFFF`,
    colorDate: `#FFFFFF`,
    colorBack: `#FFFFFF`,
    color: `#FFFFFF`,
    borderColorInput: `#272B36`,

    block: {
      line: `#FFFFFF`,
      color: `#fff`,
      borderColor: `rgba(115, 119, 130, 0.38)`,
      background: `#737782`,

    },

    input:{
      color: `#fff`,
      background: `#737782`,
      borderColor: `rgba(115, 119, 130, 0.38)`,
      svg: `#fff`,
    },

    button: {
      color: `#fff`,
      background: `linear-gradient(180deg, #8E919A 0%, #7D818A 36.92%, #5D616C 100%);`,
      borderColor: `rgba(115, 119, 130, 0.38)`,
      hover: {background:``},
      action: {background:``},
      focus: {background:``},
      inBlock: {
        color: `#fff`,
        background: `linear-gradient(180deg, #8E919A 0%, #7D818A 36.92%, #5D616C 100%);`,
        borderColor: `#626570`,
        hover: {background:``},
        action: {background:``},
        focus: {background:``},
      },
    },
  },

  White2: {
    svgColor: "#3674D9",
    // background: `#F6F6F8 url(${White2_Background})`,
    background: `#F6F6F8`,
    colorWelcome: `#000`,
    colorDate: `#000`,
    colorBack: `#3674D9`,
    color: `#000`,
    borderColorInput: `#AAAAAA`,

    block: {
      line: `#3674D9`,
      color: `#000`,
      borderColor: `#0000`,
      background: `#fff`,
      
    },

    input:{
      color: `#000`,
      background: `#fff`,
      borderColor: `rgba(54, 116, 217, 0.38)`,
      svg: `#000`,
    },

    button: {
      color: `#fff`,
      background: `linear-gradient(180deg, #5893F4 0%, #1D5EC9 58.28%)`,
      borderColor: `rgba(54, 116, 217, 0.38)`,
      hover: {background:``},
      action: {background:``},
      focus: {background:``},
      inBlock: {
        color: `#fff`,
        background: `linear-gradient(180deg, #5893F4 0%, #1D5EC9 58.28%)`,
        borderColor: `rgba(54, 116, 217, 0.38)`,
        hover: {background:``},
        action: {background:``},
        focus: {background:``},
      },
    },
  },

  Blue: {
    svgColor: "#05345E",
    // background: `#05345E url(${Or_Ge_Blu_Background})`,
    background: `#05345E`,
    colorWelcome: `#FAFAFA`,
    colorDate: `#000`,
    colorBack: `#fff`,
    color: `#FFFFFF`,
    borderColorInput: `#AAAAAA`,

    block: {
      line: `#05345E`,
      color: `#000`,
      borderColor: `rgba(255, 255, 255, 0.38)`,
      background: `#fff`,
      
    },

    input:{
      color: `#000`,
      background: `#fff`,
      borderColor: `rgba(255, 255, 255, 0.38)`,
      svg: `#676767`,
    },

    button: {
      color: `#000`,
      background: `#fff`,
      borderColor: `rgba(255, 255, 255, 0.38)`,
      hover: {background:``},
      action: {background:``},
      focus: {background:``},
      inBlock: {
        color: `#fff`,
        background: `#05345E`,
        borderColor: `rgba(5, 52, 94, 0.38)`,
        hover: {background:``},
        action: {background:``},
        focus: {background:``},
      },
    },
  },

  White1: {
    svgColor: "#05345E",
    // background: `#F6F6F8 url(${White1_Background})`,
    background: `#F6F6F8`,
    colorWelcome: `#302E2D`,
    colorDate: `#000`,
    colorBack: `#05345E`,
    color: `#302E2D`,
    borderColorInput: `#AAAAAA`,

    block: {
      background: `#fff`,
      color: `#000`,
      line: `#05345E`,
      borderColor: `rgba(5, 52, 94, 0.38)`,
    },

    input:{
      background: `#fff`,
      color: `#302E2D`,
      borderColor: `rgba(5, 52, 94, 0.38)`,
      svg: `#302E2D`,
    },

    button: {
      color: `#fff`,
      background: `linear-gradient(180deg, #1D5E99 0%, #05345E 49.42%)`,
      borderColor: `rgba(5, 52, 94, 0.38)`,
      hover: {background:``},
      action: {background:``},
      focus: {background:``},
      inBlock: {
        color: `#fff`,
        background: `linear-gradient(180deg, #1D5E99 0%, #05345E 49.42%)`,
        borderColor: `rgba(5, 52, 94, 0.38)`,
        hover: {background:``},
        action: {background:``},
        focus: {background:``},
      },
    },
  },

  Green: {
    svgColor: "#2B9976",
    // background: `#2B9976 url(${Or_Ge_Blu_Background})`,
    background: `#2B9976`,
    colorWelcome: `#FAFAFA`,
    colorDate: `#000`,
    colorBack: `#fff`,
    color: `#fff`,
    borderColorInput: `#AAAAAA`,

    block: {
      background: `#fff`,
      color: `#000`,
      line: `#2B9976`,
      borderColor: `rgba(255, 255, 255, 0.38)`,
    },

    input:{
      background: `#fff`,
      color: `#302E2D`,
      borderColor: `rgba(255, 255, 255, 0.38)`,
      svg: `#676767`,
    },

    button: {
      color: `#000`,
      background: `#FFFFFF`,
      borderColor: `rgba(255, 255, 255, 0.38)`,
      hover: {background:``},
      action: {background:``},
      focus: {background:``},
      inBlock: {
        color: `#fff`,
        background: `#2B9976`,
        borderColor: `rgba(43, 153, 118, 0.38)`,
        hover: {background:``},
        action: {background:``},
        focus: {background:``},
      },
    },
  },
};

export const ThemeContext = React.createContext(Themes.Orange);
