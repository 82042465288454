import React from 'react';
import styled from "styled-components";

export const StyledRoundedButton = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  border: none;
  color: #000000 !important;
  position: relative;
  background: #FAFAFA;
  border-radius: 4px;
  padding: 20px 38px;
  cursor: pointer;

  &:hover {
    opacity: .8;
  }

  &::before {
    position: absolute;
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    left: -3px;
    top: -3px;
    border-radius: 4px;
    border: 3px solid ${({theme}) => theme.svgColor};
    box-shadow: 0 0 0 3px #FFFFFF60;
    transform: translateZ(-1px);
  }
`;

function RoundedButton({children, ...props}) {
    return (
        <StyledRoundedButton {...props}>
            {children}
        </StyledRoundedButton>
    );
}

export default RoundedButton;