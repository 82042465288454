import React, {useState} from 'react';
import styled from "styled-components";
import moment from "moment";
import {Line, TitleRequest} from "../pages/requestPage/RequestPage";
import ExpandSVG from "../images/ExpandSVG";
import EditSvg from "../images/EditSvg";
import history from "../utils/history";
import {connect} from "react-redux";

export const TextYourRequest = styled.div`
  width: 100%;
  color: #000;
  font-size: 18px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-right: 1px;

  .title {
    margin-bottom: 20px;
  }

  .values {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: .5em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }

    & > div {
      display: flex;
      justify-content: space-between;
      border-top: 2px solid ${props => props.theme.block.line};

      div {
        width: 100%;
        padding: 10px 5px;
        display: flex;
        align-items: center;

        &:last-child {
          text-align: center;
          justify-content: center;
          border-left: 2px solid ${props => props.theme.block.line};
          word-break: break-all;
        }
      }

      &:not(:last-child) {
      }
    }
  }
`


const Expand = styled.div`
  user-select: none;
  cursor: pointer;
  display: flex;
  position: absolute;
  left: 20px;
  top: 10px;
`;

export const Edit = styled.div`
  display: flex;
  width: 35px;
  height: 35px;
  margin-left: auto;
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;

  svg {
    flex: 0 0 auto;
  }
`;

export const StyledRequestInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

function RequestInfo({request, stations, railways, products, theme, defaultOpened, ...props}) {

    const [opened, setOpened] = useState(defaultOpened || false);

    return (
        <StyledRequestInfo>
            <Expand style={{transform: `rotate(${opened ? 180 : 0}deg)`}}
                    onClick={() => setOpened(!opened)}><ExpandSVG/></Expand>
            <TitleRequest>№{request?.Id} от {request ? moment(request.Created).format("DD.MM.YYYY") : ""}</TitleRequest>
            {props.user.Role === 80 && <Edit onClick={() => history.push(`/edit/request/${request?.Id}`)}>
                <EditSvg/>
            </Edit>}
            <Line/>
            <TextYourRequest theme={theme}>
                <div className="title">Описание запроса</div>
                <div className="values">
                    <div>
                        <div>Код груза по ЕТСНГ:</div>
                        <div>{products?.filter(el => el.Code === request?.Code)[0]?.Name || request?.Code}</div>
                    </div>
                    <div>
                        <div>Станция отправления:</div>
                        <div>{stations?.filter(el => el.Code === request?.SrcStation?.[0])[0]?.FullName || request?.SrcStation?.[0]}</div>
                    </div>
                    {stations.filter(el => request?.SrcStation?.includes(el.Code))?.filter(el => el.Code !== request?.SrcStation?.[0])?.map((el, index) => (
                        <div key={index}>
                            <div></div>
                            <div>{el.FullName}</div>
                        </div>
                    ))}
                    {request?.SrcStationString?.map((el, index) => (
                        <div key={index}>
                            <div></div>
                            <div>{el}</div>
                        </div>
                    ))}
                    <div>
                        <div>Дорога отправления:</div>
                        <div>{request?.SrcRailways && (railways.filter(el => el.Code === request?.SrcRailways?.[0])[0]?.FullName || request?.SrcRailways?.[0])}</div>
                    </div>
                    {request?.SrcRailways && railways.filter(el => request?.SrcRailways?.includes(el.Code))?.filter(el => el.Code !== request?.SrcRailways?.[0])?.map((el, index) => (
                        <div key={index}>
                            <div></div>
                            <div>{el.FullName}</div>
                        </div>
                    ))}
                    {request?.SrcRailwaysString?.map((el, index) => (
                        <div key={index}>
                            <div></div>
                            <div>{el}</div>
                        </div>
                    ))}
                    <div>
                        <div>Количество вагонов:</div>
                        <div>{request?.CountCar}</div>
                    </div>
                    <div>
                        <div>Станция назначения:</div>
                        <div>{stations.filter(el => el.Code === request?.DstStation?.[0])[0]?.FullName || request?.DstStation?.[0]}</div>
                    </div>
                    {stations.filter(el => request?.DstStation?.includes(el.Code))?.filter(el => el.Code !== request?.DstStation?.[0])?.map((el, index) => (
                        <div key={index}>
                            <div></div>
                            <div>{el.FullName}</div>
                        </div>
                    ))}
                    {request?.DstStationString?.map((el, index) => (
                        <div key={index}>
                            <div></div>
                            <div>{el}</div>
                        </div>
                    ))}
                    <div>
                        <div>Дорога назначения:</div>
                        <div>{request?.DstRailways && (railways.filter(el => el.Code === request?.DstRailways?.[0])[0]?.FullName || request?.DstRailways?.[0])}</div>
                    </div>
                    {request?.DstRailways && railways.filter(el => request?.DstRailways?.includes(el.Code))?.filter(el => el.Code !== request?.DstRailways?.[0])?.map((el, index) => (
                        <div key={index}>
                            <div></div>
                            <div>{el.FullName}</div>
                        </div>
                    ))}
                    {request?.DstRailwaysString?.map((el, index) => (
                        <div key={index}>
                            <div></div>
                            <div>{el}</div>
                        </div>
                    ))}
                    {opened && <>
                        <div>
                            <div>Отправитель, ОКПО, код грузоотправителя:</div>
                            <div>{request?.Sender}</div>
                        </div>

                        <div>
                            <div>Индекс, адрес отправителя:</div>
                            <div>{request?.IndexSender}</div>
                        </div>

                        <div>
                            <div>Вид подвижного состава:</div>
                            <div>{request?.Type}</div>
                        </div>

                        <div>
                            <div>Особые условия и требования к вагонам:</div>
                            <div>{request?.SpecialConditions}</div>
                        </div>

                        <div>
                            <div>Получатель, код ОКПО:</div>
                            <div>{request?.Recipient}</div>
                        </div>

                        <div>
                            <div>Адрес получателя:</div>
                            <div>{request?.AddressRecipient}</div>
                        </div>

                        <div>
                            <div>Плательщик ж.д. тарифа за груженный вагон:</div>
                            <div>{request?.Buyer1}</div>
                        </div>

                        <div>
                            <div>Плательщик ж.д. тарифа за возврат порожнего вагона:</div>
                            <div>{request?.Buyer2}</div>
                        </div>

                        <div>
                            <div>Период действия заявки:</div>
                            <div>{request?.Date}</div>
                        </div>

                        <div>
                            <div>График подачи вагонов:</div>
                            <div>{request?.Schedule}</div>
                        </div>

                        <div>
                            <div>Ставка:</div>
                            <div>{request?.Price} {request?.Tax}</div>
                        </div>

                        <div>
                            <div>Примечание:</div>
                            <div>{request?.Note}</div>
                        </div>
                    </>}
                </div>
            </TextYourRequest>

        </StyledRequestInfo>
    );
}

const mapStateToProps = state => {
    return {
        user: state.loginReducer.userData
    }
}

export default connect(mapStateToProps, null)(RequestInfo)