import React from 'react';

function ArrowRightSvg(props: any) {
    return (
        <svg {...props} width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5195 6.98047H1.52953" stroke="#323232" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M7.51562 0.988281L1.47862 7.00028L7.51562 13.0123" stroke="#323232" strokeWidth="1.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default ArrowRightSvg;