import React, {Component, useEffect, useRef, useState} from "react";
import {Route, Router, Switch, Link, Redirect, useLocation, useHistory} from "react-router-dom";
import 'antd/dist/antd.css';
import "./css/fonts/Montserrat/stylesheet.css";
import "./css/main.css";
import styled from 'styled-components';

import Layout from "./layout/Layout";

import Login from "./pages/login/Login";
import PasswordRecovery from "./pages/passwordRecovery/PasswordRecovery";
import Welcome from "./pages/welcome/Welcome";
import EmailSent from "./pages/emailSent/EmailSent";
import Theme from "./pages/theme/Theme";

// Страницы которые не понятно как назвать
import Manager1 from "./pages/manager1/Manager1";
import Manager2 from "./pages/manager2/Manager2";
import Manager3 from "./pages/manager3/Manager3";
import Manager4 from "./pages/manager4/Manager4";
import Manager5 from "./pages/manager5/Manager5";
import RequestPage from "./pages/requestPage/RequestPage";
import Manager7 from "./pages/manager7/Manager7";

import Mover1 from "./pages/mover1/Mover1";
import Mover2 from "./pages/mover2/Mover2";
import Mover3 from "./pages/mover3/Mover3";
import Mover4 from "./pages/mover4/Mover4";
import Mover5 from "./pages/mover5/Mover5";
import Mover6 from "./pages/mover6/Mover6";
import Mover7 from "./pages/mover7/Mover7";

import {ThemeContext, Themes} from "./css/Themes";
import history from "./utils/history";
import store from "./redux/store";
import {localClientStatusAction, refreshTokenAction} from "./redux/actions/loginActions";
import CreateRequest from "./pages/createRequest/createRequest";
import {api} from "./config";
import {connect} from "react-redux";
import AdminLayout from "./layout/AdminLayout";
import AdminPage from "./pages/adminPages/AdminPage";
import Manager6 from "./pages/manager6/Manager6";
import SupervisorRequests from "./pages/supervisor/SupervisorRequests";
import SupervisorRequestList from "./pages/supervisor/SupervisorRequestList";
import {getWS} from "./utils/websocket";
import SupervisorRequestPage from "./pages/supervisor/SupervisorRequestPage";
import Supervisor from "./pages/supervisor/Supervisor";
import SupervisorMain from "./pages/supervisor/SupervisorMain";
import SupervisorManagers from "./pages/supervisor/SupervisorManagers";
import EditRequest from "./pages/editRequest/editRequest";
import ApplicationCommentPage from "./pages/ApplicationCommentPage";

const ListLink = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;

  & a {
    font-size: 20px;
    background: #0001;
    padding: 5px 20px;
    margin: 2px;
    max-width: 400px;
    transition: .1s;

    display: flex;
    justify-content: space-between;

    &:hover {
      background: #0002;
      max-width: 410px;
    }
  }
`

const ThemeStyle = styled.div`
  background: ${props => props.theme.background};
  background-image: none;
  border-radius: 5px;
  color: white;
  width: 30px;
  height: 30px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 5px #0006;

  &:before {
    content: "";
    position: absolute;
    width: 34%;
    height: 100%;
    background: ${props => props.theme.button.background};
  }
`


const App = ({loading, user}) => {
    const intervalRef = useRef();
    const localStatusInterval = useRef();
    const [theme, setTheme] = useState(Themes.Orange)

    const location = useLocation();

    const updateData = (value) => {
        setTheme(Themes[value])
    };

    useEffect(() => {
        console.log(history);
        let prevHistory = localStorage.getItem("crm_back_url") ? JSON.parse(localStorage.getItem("crm_back_url")) : ["/"];
        if (history.action !== "POP") {
            prevHistory.push(location.pathname + location.search)
            localStorage.setItem("crm_back_url", JSON.stringify(prevHistory));
        }
    }, [location])

    useEffect(() => {
        let prevHistory = ["/"];
        prevHistory.push(history.location.pathname + history.location.search)
        localStorage.setItem("crm_back_url", JSON.stringify(prevHistory));

        store.dispatch(refreshTokenAction());

        localStatusInterval.current = setInterval(() => {
            store.dispatch(localClientStatusAction()).catch((err) => {
                /*do nothing*/
            });

        }, 3000)

        if (localStorage.getItem("crm_theme") !== null)
            updateData(localStorage.getItem("crm_theme"))

        const [, interval] = getWS();
        console.log(interval);
        intervalRef.current = interval;
        return () => {
            console.log(intervalRef.current);
            clearInterval(intervalRef.current);
            clearInterval(localStatusInterval.current);
        }
    }, [])


    return (
        <React.Fragment>
            <ThemeContext.Provider value={theme}>

                <Route path="/login" exact>
                    <Layout hideTopMenu={true} hideBackground={true}>
                        <Login/>
                    </Layout>
                </Route>
                <Route path="/recovery" exact>
                    <Layout hideTopMenu={true} hideBackground={true}>
                        <PasswordRecovery theme={theme}/>
                    </Layout>
                </Route>
                <React.Fragment>
                    <div className="App">
                        {!loading ? <React.Fragment>
                                <Route path={"/"} exact>
                                    <Redirect
                                        to={`${user.Role === 90 ? "/admin" : user.Role === 80 ? "/manager1" : user.Role === 85 ? "/supervisor" : "/mover1"}`}/>
                                </Route>
                                <Route path="/admin123" exact>
                                    <div style={{display: "flex", width: "100%"}}>
                                        <ListLink>
                                            Rest pages
                                            <Link to={"/login"}>/login</Link>
                                            <Link to={"/welcome"}>/welcome</Link>
                                            <Link to={"/recovery"}>/recoveryPass</Link>
                                            <Link to={"/email"}>/emailSent</Link>
                                            <Link to={"/theme"}>/theme<ThemeStyle theme={theme}/></Link>
                                        </ListLink>
                                        <ListLink>
                                            Manager pages
                                            <Link to={"/manager1"}>/manager1</Link>
                                            <Link to={"/manager2"}>/manager2</Link>
                                            <Link to={"/manager3"}>/manager3</Link>
                                            <Link to={"/manager4"}>/manager4</Link>
                                            <Link to={"/manager5"}>/manager5</Link>
                                            <Link to={"/request"}>/manager6</Link>
                                            <Link to={"/manager7"}>/manager7</Link>
                                        </ListLink>
                                        <ListLink>
                                            Mover pages
                                            <Link to={"/mover1"}>/mover1</Link>
                                            <Link to={"/mover2"}>/mover2</Link>
                                            <Link to={"/mover3"}>/mover3</Link>
                                            <Link to={"/mover4"}>/mover4</Link>
                                            <Link to={"/mover5"}>/mover5</Link>
                                            <Link to={"/mover6"}>/mover6</Link>
                                            <Link to={"/mover7"}>/mover7</Link>
                                        </ListLink>
                                    </div>
                                </Route>

                                <Route path="/theme" exact>
                                    <Layout hideTopMenu={true} hideBackground={true}>
                                        <Theme updateData={updateData}/>
                                    </Layout>
                                </Route>
                                <Route path="/email" exact>
                                    <Layout theme={theme} hideTopMenu={true} hideBackground={true}>
                                        <EmailSent theme={theme}/>
                                    </Layout>
                                </Route>
                                <Route path="/welcome" exact>
                                    <Layout theme={theme} hideTopMenu={true}>
                                        <Welcome theme={theme}/>
                                    </Layout>
                                </Route>

                                <Route path="/manager1" exact>
                                    <Layout back={`${api}/exit`} theme={theme}>
                                        <Manager1 theme={theme}/>
                                    </Layout>
                                </Route>
                                <Route path="/manager2" exact>
                                    <Layout back={"/manager1"} theme={theme}>
                                        <Manager2 theme={theme}/>
                                    </Layout>
                                </Route>
                                <Route path="/request" exact>
                                    <Layout back={"/manager2"} theme={theme} hideTopMenu fullBackground>
                                        <CreateRequest theme={theme}/>
                                    </Layout>
                                </Route>
                                <Route path="/edit/request/:id" exact
                                       render={props =>
                                           <Layout back={"/manager2"} {...props} theme={theme} hideTopMenu fullBackground>
                                               <EditRequest theme={theme} {...props}/>
                                           </Layout>}
                                />
                                <Route path="/manager3" exact>
                                    <Layout back={"/manager2"} theme={theme}>
                                        <Manager3 theme={theme}/>
                                    </Layout>
                                </Route>
                                <Route path="/manager4" exact>
                                    <Layout back={"/manager2"} theme={theme}>
                                        <Manager4 theme={theme}/>
                                    </Layout>
                                </Route>
                                <Route path="/manager5/:requestId" exact
                                       render={props => <Layout back={"/manager4"}
                                                                theme={theme} {...props}>
                                           <Manager5 theme={theme} {...props}/>
                                       </Layout>}>
                                </Route>
                                <Route path="/manager6/:requestId/:moverId" exact
                                       render={props => <Layout back={`/manager5/${props.match.params.requestId}`}
                                                                theme={theme} {...props}>
                                           <Manager6 theme={theme} {...props}/>
                                       </Layout>}>
                                </Route>
                                <Route path="/request/:managerId/:requestId" exact render={props =>
                                    <Layout
                                        back={`${props.user?.Role === 80 ? "/manager4" : "/mover4/" + props.match.params.managerId}`}
                                        theme={theme} {...props}>
                                        <RequestPage theme={theme} {...props}/>
                                    </Layout>}>
                                </Route>
                                <Route path="/close/:requestId" exact
                                       render={props => <Layout back={`/supervisor`}
                                                                theme={theme} {...props}>
                                           <ApplicationCommentPage theme={theme} {...props}/>
                                       </Layout>}>
                                </Route>
                                <Route path="/manager7" exact>
                                    <Layout back={`/manager2`} theme={theme}>
                                        <Manager7 theme={theme}/>
                                    </Layout>
                                </Route>

                                <Route path="/mover1" exact>
                                    <Layout back={`${api}/exit`} theme={theme}>
                                        <Mover1 theme={theme}/>
                                    </Layout>
                                </Route>
                                <Route path="/mover2" exact>
                                    <Layout back={"/mover1"} theme={theme}>
                                        <Mover2 theme={theme}/>
                                    </Layout>
                                </Route>
                                <Route path="/mover3" exact>
                                    <Layout back={"/mover2"} theme={theme}>
                                        <Mover3 theme={theme}/>
                                    </Layout>
                                </Route>
                                <Route path="/mover4/:managerId" exact
                                       render={props => <Layout back={"/mover3"} {...props}
                                                                theme={theme}>
                                           <Mover4 theme={theme} {...props}/>
                                       </Layout>}>
                                </Route>
                                <Route path="/mover5" exact>
                                    <Layout theme={theme}>
                                        <Mover5 theme={theme}/>
                                    </Layout>
                                </Route>
                                <Route path="/mover6" exact>
                                    <Layout theme={theme}>
                                        <Mover6 theme={theme}/>
                                    </Layout>
                                </Route>
                                <Route path="/mover7" exact>
                                    <Layout back={`/mover2`} theme={theme}>
                                        <Mover7 theme={theme}/>
                                    </Layout>
                                </Route>
                                <Route path="/supervisor" exact>
                                    <Layout back={`${api}/exit`} theme={theme}>
                                        <SupervisorMain theme={theme}/>
                                    </Layout>
                                </Route>
                                <Route path="/supervisor/pick" exact>
                                    <Layout back={`/supervisor`} theme={theme}>
                                        <Supervisor theme={theme}/>
                                    </Layout>
                                </Route>
                                <Route path="/supervisor/requests" exact>
                                    <Layout back={`/supervisor`} theme={theme}>
                                        <SupervisorRequests theme={theme}/>
                                    </Layout>
                                </Route>
                                <Route path="/supervisor/managers" exact>
                                    <Layout back={`/supervisor/pick`} theme={theme}>
                                        <SupervisorManagers theme={theme}/>
                                    </Layout>
                                </Route>
                                <Route path="/supervisor/requests/:managerId" exact>
                                    <Layout back={`/supervisor`} theme={theme}>
                                        <SupervisorRequests theme={theme}/>
                                    </Layout>
                                </Route>
                                <Route path="/supervisor/search/:managerId"
                                       exact
                                       render={(props) => <Layout back={`/supervisor/managers`} {...props}
                                                                  theme={theme}>
                                           <SupervisorRequestList {...props} theme={theme}/>
                                       </Layout>}>
                                </Route>
                                <Route path="/supervisor/request/:requestId" exact
                                       render={(props) => <Layout fullBackground
                                                                  back={`/supervisor/managers`} {...props}
                                                                  theme={theme}>
                                           <SupervisorRequestPage {...props} theme={theme}/>
                                       </Layout>}>
                                </Route>
                                <Route path="/admin" exact
                                       render={(props) => <AdminLayout>
                                           <AdminPage {...props} theme={theme}/>
                                       </AdminLayout>}>
                                </Route>
                                <Route path="/admin/emails" exact
                                       render={(props) => <AdminLayout>
                                           <AdminPage {...props} theme={theme} showEmails/>
                                       </AdminLayout>}>
                                </Route>
                                <Route path="" exact>

                                </Route>
                            </React.Fragment> :
                            <Layout theme={theme} hideTopMenu={true}>
                                <Welcome theme={theme}/>
                            </Layout>}
                    </div>
                </React.Fragment>
            </ThemeContext.Provider>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loginReducer.loading,
        user: state.loginReducer.userData
    }
}

export default connect(mapStateToProps, null)(App);
