import {api} from "../config";

let websocket;
let interval;

function ws_connect() {
    websocket = new WebSocket(`${window.location.protocol === "http:" ? "ws://" : "wss://"}${window.location.host}${api}/ws`);

    websocket.onopen = (e) => {
        console.log(e);
    }

    websocket.onmessage = async e => {
        const wsMessage = JSON.parse(e.data);
        switch (wsMessage.Type) {
            case 1: {
                console.log(wsMessage);
                break;
            }
            default:
                return;
        }
    };

    return websocket;
}

interval = setInterval(() => {
    if (websocket !== undefined && websocket.readyState === websocket.CLOSED) {
        ws_connect();
    } else if (websocket.readyState === websocket.OPEN) {
        websocket.send(JSON.stringify({Type: 0}));
    }
}, 3000)

export function getWS() {
    if (websocket === undefined || websocket.readyState === websocket.CLOSED) {
        console.log(interval);
        return [ws_connect(), interval];
    }
    return [websocket, interval];
}