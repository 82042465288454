import React, {Component} from "react";
import styled from 'styled-components';

import OrangeThemeImg from "../../images/OrangeTheme.png";
import DarkThemeImg from "../../images/DarkTheme.png";
import WhiteTheme1Img from "../../images/WhiteTheme1.png";
import WhiteTheme2Img from "../../images/WhiteTheme2.png";
import GreenThemeImg from "../../images/GreenTheme.png";
import BlueThemeImg from "../../images/BlueTheme.png";

import {Themes} from "../../css/Themes";
import history from "../../utils/history";
import {localGoBack} from "../../utils/localHistory";

const Page = styled.div`
  padding-top: 60px;
`;

const Title = styled.div`
  width: 100%;
  margin-bottom: 70px;
  
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;

  color: #000000;

  & span{
    color: #279a77;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  &:first-child{
    margin-top: 0px;
  }
;
`

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  &:last-child{
    margin-right: 0px;
  }
`

const ThemeImg = styled.img`
  width: 100%;
  margin-bottom: 10px;
`

const SelectionButton = styled.button`
  cursor: pointer;
  border: 0;
  width: 100%;
  height: 70px;
  background: linear-gradient(180deg, #39F8BB 0%, #2B9976 55.38%);

  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
`



class Theme extends Component {

  updateTheme = (name) => {
    localGoBack();
    console.log(history);
    this.props.updateData(name);
    localStorage.setItem("crm_theme", name)
  };

  render() {

    return (
      <Page>
       	<Title>Чтобы выбрать нужную цветовую схему -<br/> нажмите кнопку <span>“Выбрать”</span> под этой схемой.</Title>
        <Row>
        <Column>
            <ThemeImg src={OrangeThemeImg}/>
            <SelectionButton onClick={()=>this.updateTheme("Orange")}>Выбрать</SelectionButton>
          </Column>
          <Column>
            <ThemeImg src={DarkThemeImg}/>
            <SelectionButton  onClick={()=>this.updateTheme("Dark")}>Выбрать</SelectionButton>
          </Column>
          <Column>
            <ThemeImg src={WhiteTheme1Img}/>
            <SelectionButton onClick={()=>this.updateTheme("White1")}>Выбрать</SelectionButton>
          </Column>
        </Row>

        <Row>
          <Column>
            <ThemeImg src={WhiteTheme2Img}/>
            <SelectionButton onClick={()=>this.updateTheme("White2")}>Выбрать</SelectionButton>
          </Column>
          <Column>
            <ThemeImg src={GreenThemeImg}/>
            <SelectionButton onClick={()=>this.updateTheme("Green")}>Выбрать</SelectionButton>
          </Column>
          <Column>
            <ThemeImg src={BlueThemeImg}/>
            <SelectionButton onClick={()=>this.updateTheme("Blue")}>Выбрать</SelectionButton>
          </Column>
        </Row>

      </Page>
    )
  }
}


export default Theme

