import React, {useEffect, useState} from "react";
import styled from 'styled-components';

import HexBorder from '../../components/HexagonalBorder';
import {connect} from "react-redux";
import history from "../../utils/history";
import {Buttons_, SubGroupButtons} from "../manager4/Manager4";
import axios from "axios";
import {api} from "../../config";
import {useInterval} from "../../utils/useInterval";

const Page = styled.div`
  max-width: 1440px;
  padding: 45px 0;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;

`;

function Mover3({theme}) {
    const [managers, setManagers] = useState([]);

    useInterval(() => {
        axios(`${api}/managers`).then(({data})=>{
            let validated = data.map(el => {

                let coincidence = managers?.find(ell => el.User.Id === ell.User.Id);

                if (coincidence?.Count !== el.Count || coincidence.front_updated !== undefined) {
                    return {...el, front_updated: true};
                }
                return el;
            })
            setManagers(validated);
        })
    }, 5000)

    useEffect(() => {
        axios(`${api}/managers`).then(({data}) => {
            setManagers(data);
        })
    }, [])

    const ListColumn1 = managers?.map((manager, index) =>
        <HexBorder key={index} theme={theme.button}>
            <button
                onClick={_ => history.push(`/mover4/${manager.User.Id}`)}>{manager.User.Surname && manager.User.Name ? `${manager.User.Surname} ${manager.User.Name}` : manager.User.Login}</button>
        </HexBorder>
    );

    const ListColumn2 = managers?.map((manager, index) => {
            console.log(manager);
            return <HexBorder background={manager.front_updated ? "green" : undefined} key={index} theme={theme.button}>
                <button style={{background: manager.front_updated ? "green" : undefined}}>{manager.Count}</button>
            </HexBorder>
        }
    );

    return (
        <Page>
            <Buttons_ theme={theme} className={"items"}>
                <SubGroupButtons>
                    {ListColumn1}
                </SubGroupButtons>
                <SubGroupButtons>
                    {ListColumn2}
                </SubGroupButtons>

            </Buttons_>
        </Page>
    )
}

const mapStateToProps = state => {
    return {
        requests: state.pageReducer.requests
    }
}

export default connect(mapStateToProps, null)(Mover3);