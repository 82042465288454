import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import TekSelect, {StyledTekSelect} from "../../components/TekSelect";
import {Checkbox, DatePicker, message, Select} from "antd";
import TekButton, {ButtonWrap, StyledTekButton} from "../../components/TekButton";
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import axios from "axios";
import {api} from "../../config";
import history from "../../utils/history";
import moment from "moment";

const {Option} = Select;
const {RangePicker} = DatePicker;

const SearchCheck = styled(Checkbox)`
  .ant-checkbox-inner {
    width: 50px;
    height: 50px;
    background: ${({theme}) => theme.button.background};

    &::after {
      border: 4px solid ${({theme}) => theme.button.color};
      border-top: 0;
      border-left: 0;
      width: 17.8571429px;
      height: 28.5714286px;
    }
  }
`;

const StyledSupervisorRequests = styled.div`
  position: relative;

  h1 {
    color: ${({theme}) => theme.block.color};
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 45px;
  }

  .filters {
    display: flex;

    .searchWrap {
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }

    .filters_block {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      .fullWidth {
        flex: 1 1 auto;
      }

      &:last-child {
        flex: 1 1 auto;
      }
    }

    .twoFilters {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;

      &:not(:last-child) {
        margin-right: 10px;
      }

      .secondaryFilter {
        margin-top: 10px;

        button {
          padding: 0;
          width: 100%;
        }

        .ant-picker {
          width: 100%;
          height: 100%;
          background: none;
          color: ${({theme}) => theme.button.color};
          border: 0;

          .ant-picker-clear {
            background: none;
          }

          &:hover {
            .ant-picker-suffix {
              opacity: 0;
            }
          }

          input, input::placeholder {
            color: ${({theme}) => theme.button.color};
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
    }

    ${StyledTekSelect} {
      height: 84px;
      margin-right: 10px;
    }

    ${StyledTekButton}, ${StyledTekButton} {
      width: 100%;
      //height: fit-content;
    }
  }

`;

function SupervisorRequests({theme}) {
    const [manager, setManager] = useState();
    const [mover, setMover] = useState();
    const [movers, setMovers] = useState([]);
    const [managers, setManagers] = useState([]);
    const [dates, setDates] = useState([]);

    const getMovers = () => {
        axios(`${api}/users/mover`).then(({data}) => {
            setMovers(data);
        })
    }

    const getManagers = () => {
        axios(`${api}/users/manager`).then(({data}) => {
            if (data?.length > 0) {
                setManager(data[0].Id)
            }
            setManagers(data);
        })
    }

    const getAllRequests = () => {
        history.push(`/supervisor/search/0${mover ? `?mover=${mover}` : ""}`)
    }
    const getWeekRequests = () => {
        history.push(`/supervisor/search/${manager}?start=${moment().startOf("week").format("YYYY-MM-DD")}&end=${moment().endOf("week").format("YYYY-MM-DD")}`)
    }

    const handleSearch = () => {

        if (dates.length > 1) {
            console.log(moment);
            console.log(manager);
            console.log(mover);
            console.log(dates);
            return history.push(`/supervisor/search/${manager}?start=${moment(dates[0]).format("YYYY-MM-DD")}&end=${moment(dates[1]).format("YYYY-MM-DD")}${mover ? `&mover=${mover}` : ""}`);
        }

        message.error("Вы не выбрали период, в котором нужно искать запросы!");

    }

    useEffect(() => {
        getMovers();
        getManagers();
    }, [])

    return (
        <StyledSupervisorRequests theme={theme}>
            <h1>Введите фильтр для ввода списка запросов</h1>
            <div className="filters">
                <div className="filters_block">
                    <TekSelect noPopCon theme={theme} placeholder={"Менеджер"} value={manager}
                               onSelect={v => setManager(v)}>
                        {managers.map((el, index) => (
                            <Option key={index}
                                    value={el.Id}>{el.Surname && el.Name ? `${el.Surname} ${el.Name}` : el.Login}</Option>
                        ))}
                    </TekSelect>
                    <TekSelect noPopCon theme={theme} placeholder={"Движенец"} value={mover} onSelect={v => setMover(v)}
                               allowClear={true} onClear={v => setMover(undefined)}>
                        {movers.map((el, index) => (
                            <Option key={index}
                                    value={el.Id}>{el.Surname && el.Name ? `${el.Surname} ${el.Name}` : el.Login}</Option>
                        ))}
                    </TekSelect>
                </div>
                <div className="filters_block">
                    <div className="twoFilters">
                        <TekButton onClick={getWeekRequests} theme={theme}>Показать запросы за последнюю
                            неделю</TekButton>
                        <TekButton wrapClassName={"secondaryFilter"} theme={theme}><RangePicker locale={locale}
                                                                                                onChange={(e, v) => setDates(v)}
                                                                                                placeholder={["За период с", "За период до"]}/></TekButton>
                    </div>
                    <div className="twoFilters" style={{flex: "1 1 auto"}}>
                        <TekButton wrapClassName={"fullWidth"} onClick={getAllRequests} theme={theme}>Показать все
                            запросы</TekButton>
                        <div className="searchWrap">
                            <SearchCheck theme={theme} checked={true} onClick={handleSearch}/>
                        </div>
                    </div>
                </div>
            </div>
        </StyledSupervisorRequests>
    );
}

export default SupervisorRequests;