import React from 'react';

export default function Search() {
	return (
		<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="6" cy="6" r="5" strokeWidth="2"/>
			<path d="M9 10L13 15" strokeWidth="2"/>
		</svg>
	)
}
