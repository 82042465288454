import history from "./history";

export const localGoBack = () => {
    let localHistory = JSON.parse(localStorage.getItem("crm_back_url"));
    if (localHistory.length === 2) {
        localHistory.pop();
        history.push(localHistory[localHistory.length - 1])
    } else {
        localHistory.pop();
        history.push(localHistory[localHistory.length - 1])
        localHistory.pop();
    }
    localStorage.setItem("crm_back_url", JSON.stringify(localHistory))
}