import React, {Component} from "react";
import styled, {keyframes} from 'styled-components';
import Train from "../../images/Train";

import {pulse, slideOutRight} from 'react-animations';

const pulseAnimation = keyframes`${pulse} ${slideOutRight}`;

const Page = styled.div`
  padding-top: 280px;
  overflow: hidden;
`;

const Title = styled.div`
  width: 100%;

  font-weight: bold;
  font-size: 64px;
  line-height: 78px;
  text-align: center;
  color: ${props => props.theme.colorWelcome};
`

const SubTitle = styled.div`
  width: 100%;
  margin-top: 15px;

  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: ${props => props.theme.colorWelcome};
`
const Content = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledTrain = styled(Train)`
  height: 100px;
  width: 200px;
  left: -100px;
  position: relative;
  animation: 1s ${pulseAnimation} infinite alternate;
`;

class Welcome extends Component {

    render() {
        const theme = this.props.theme;

        return (
            <Page>
                <Title theme={theme}>Добро пожаловать</Title>
                <Content>
                    <StyledTrain/>
                </Content>
            </Page>
        )
    }
}


export default Welcome

