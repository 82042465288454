import React, {Component} from "react";
import {Link} from "react-router-dom";
import styled from 'styled-components';

import {Form, Input, Button, message} from 'antd';

import HexBorder from '../../components/HexagonalBorder';
import {ForgotPassword} from "../login/FormLogin";
import axios from "axios";
import {api} from "../../config";

const AntLoginForm = styled(Form)`

  width: 465px;
  height: fit-content;
  word-wrap: break-word;

  background: #FFFFFF;
  box-shadow: 0px 0px 30px 6px rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 60px 70px 80px;

  .HexInput {
    width: 100%;
  }

  & > .HexInput {
    margin-bottom: 8px;
  }

`

const TitleForm = styled.div`
  margin-bottom: 35px;
  text-align: center;

  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #302E2D;
`

const AntInput = styled(Form.Item)`

  height: 53px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 20px 0 20px;

  margin-bottom: 0px;

  & label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.4);

  }

  & .ant-input {
    border: 0px;

    &:focus {
      border-color: #0000;
      box-shadow: 0 0 0 #000;
    }

  }

`

const AntSubmit = styled(Form.Item)`

  width: 100%;
  margin: 0;
  cursor: pointer;

  & button {
    font-weight: 500;
    line-height: 17px;
    color: #FFFFFF;

    height: 53px;
    width: 100%;
    border-radius: 0px;
    border: 0;

    background: linear-gradient(180deg, #39F8BB 0%, #2B9976 55.38%);

    &:hover {
      background: linear-gradient(180deg, #39F8BB 0%, #2B9976 55.38%);
    }

    &:focus {
      background: linear-gradient(180deg, #39F8BB 0%, #2B9976 55.38%);
    }

    &:active {
      background: linear-gradient(180deg, #39F8BB 0%, #2B9976 55.38%);
    }
  }
`

class PassRecForm extends Component {

    handleRecover = ({login}) => {
        axios(`${api}/recovery?a123=${login}`).then(({data}) => {
            message.success("Письмо успешно отправлено на вашу почту")
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        });
    }

    render() {

        return (
            <AntLoginForm colon={false} name="nest-messages" onFinish={this.handleRecover}>

                <TitleForm>Восстановление пароля</TitleForm>

                <HexBorder
                    className={"HexInput"}
                    height={"53px"}
                    background={"#fff"}
                    borderColor={"rgba(155, 155, 155, 0.52)"}
                    oBorderColor={"rgba(219, 214, 214, 0.38)"}
                >
                    <AntInput name={'login'} label="Логин">
                        <Input/>
                    </AntInput>
                </HexBorder>

                <HexBorder
                    className={"HexInput"}
                    height={"53px"}
                    background={"linear-gradient(135deg,#8bffce 0%,#2B9976 55.38%)"}
                    oBorderColor={"rgba(43, 153, 118, 0.38)"}
                >
                    <AntSubmit rules={[{
                        required: true,
                        message: "Обязательно к заполнению"
                    }]}>
                        <Button type="primary" htmlType="submit">
                            Отправить
                        </Button>
                    </AntSubmit>
                </HexBorder>

                <ForgotPassword to={"/login"}>Назад</ForgotPassword>
            </AntLoginForm>
        )
    }
}


export default PassRecForm

