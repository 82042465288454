import React, {useContext} from 'react';
import styled from "styled-components";
import {ThemeContext} from "../css/Themes";

const StyledKeyboardSVG = styled.svg`
  cursor: pointer;


  &.active {
    .keyboard {
      fill: ${({theme}) => theme.svgColor};
    }
  }

  .keyboard {
    fill: #BFBFBF;

  }

  &:hover {
    &.active {
      .keyboard {
        fill: #BFBFBF;
      }
    }

    .keyboard {
      fill: ${({theme}) => theme.svgColor};
    }
  }
`;

function KeyboardSvg(props) {
    const theme = useContext(ThemeContext);

    return (
        <StyledKeyboardSVG {...props} theme={theme} width="32" height="24" viewBox="0 0 32 24" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
            <path
                className={"keyboard"}
                d="M29.7615 0H2.24011C1.00645 0 0 0.995917 0 2.21667V16.7833C0 18.0009 1.00645 19 2.23851 19H29.7599C30.9919 19 32 18.0009 32 16.7833V2.21667C32.0016 0.995917 30.9919 0 29.7615 0ZM17.6009 3.16667H20.801V6.33333H17.6009V3.16667ZM22.4011 7.91667V11.0833H19.201V7.91667H22.4011ZM12.8006 3.16667H16.0008V6.33333H12.8006V3.16667ZM17.6009 7.91667V11.0833H14.4007V7.91667H17.6009ZM8.0004 3.16667H11.2006V6.33333H8.0004V3.16667ZM12.8006 7.91667V11.0833H9.60048V7.91667H12.8006ZM3.20016 3.16667H6.40032V6.33333H3.20016V3.16667ZM8.0004 7.91667V11.0833H4.80024V7.91667H8.0004ZM6.40032 15.8333H3.20016V12.6667H6.40032V15.8333ZM24.0012 15.8333H8.0004V12.6667H24.0012V15.8333ZM28.8014 15.8333H25.6013V12.6667H28.8014V15.8333ZM24.0012 11.0833V7.91667H27.2014V11.0833H24.0012ZM28.8014 6.33333H22.4011V3.16667H28.8014V6.33333Z"
                fill="#FF7F26"/>
            <path
                className={"hand"}
                d="M19.4797 15.717C19.8718 15.536 20.3229 15.7529 20.4823 16.1988L21.3958 18.5312H21.6893V12.1875C21.6893 11.7062 22.0357 11.3125 22.4588 11.3125C22.8823 11.3125 23.2284 11.7062 23.2284 12.1875V16.3369L23.6133 16.3438V10.875C23.6133 10.3937 23.9596 10 24.3828 10C24.8073 10 25.1523 10.3937 25.1523 10.875V16.3369L25.5364 16.3438V11.75C25.5364 11.2687 25.8828 10.875 26.306 10.875C26.7294 10.875 27.0755 11.2687 27.0755 11.75V16.7744L27.4604 16.7812V13.5C27.4604 13.0187 27.8068 12.625 28.2299 12.625C28.6536 12.625 29 13.0187 29 13.5V19.4062C29 21.4789 28.0331 24 24.5989 24C23.5364 24 22.5307 23.8086 21.9776 23.4258C21.6307 23.1857 21.3323 22.8762 21.0995 22.515C20.9254 22.2454 20.7854 21.9533 20.6828 21.646L19.0562 16.857C18.8971 16.411 19.0875 15.8983 19.4797 15.717Z"
                fill="#333232"/>
        </StyledKeyboardSVG>
    );
}

export default KeyboardSvg;