import React from 'react';

function EmailCircleSvg(props: any) {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M16 30.25C23.8701 30.25 30.25 23.8701 30.25 16C30.25 8.12994 23.8701 1.75 16 1.75C8.12994 1.75 1.75 8.12994 1.75 16C1.75 23.8701 8.12994 30.25 16 30.25Z"
                  stroke="#FF7F26" strokeWidth="1.5882" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M21.2759 21.8524H10.7214C9.2632 21.8524 8.08203 20.6697 8.08203 19.213V12.7879C8.08203 11.3312 9.2632 10.1484 10.7214 10.1484H21.2775C22.7342 10.1484 23.9169 11.3296 23.9169 12.7879V19.213C23.9154 20.6697 22.7342 21.8524 21.2759 21.8524Z"
                  fill="white" stroke="#FF7F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.5469 15.9727C19.2252 17.3866 22.0198 20.0276 23.1329 21.0868" stroke="#FF7F26"
                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.4532 15.9727C12.7749 17.3866 9.98027 20.0276 8.86719 21.0868" stroke="#FF7F26"
                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M8.87891 10.9023C9.88907 11.827 12.2609 13.9962 14.2227 15.7838C15.2297 16.7021 16.7702 16.7037 17.7772 15.7853C19.8087 13.9328 22.1298 11.8096 23.121 10.9023"
                stroke="#FF7F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default EmailCircleSvg;