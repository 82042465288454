import React, { useEffect, useState} from "react";
import styled from 'styled-components';

import HexBorder from '../../components/HexagonalBorder';
import axios from "axios";
import {api} from "../../config";
import moment from "moment";
import buttonArrowSvg from "../../images/buttonArrow.svg";
import {Input} from "antd";
import showAllSvg from "../../images/showAll.svg";
import {Buttons_, Pagination, SubGroupButtons} from "../manager4/Manager4";
import {Row} from "../requestPage/RequestPage";
import {Buttons} from "../../components/Buttons";
import history from "../../utils/history";
import {getStationName} from "../../utils/getStationName";
import {useInterval} from "../../utils/useInterval";

const Page = styled.div`
  max-width: 1440px;
  padding: 45px 0;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;

`;

function Mover4({theme, match}) {
    const [page, setPage] = useState(1);
    const [requests, setRequests] = useState([]);
    const [stations, setStations] = useState([]);
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        getRequests()
    }, [page])

    useEffect(() => {
        console.log(showAll);
        page === 1 ? getRequests() : setPage(1);
    }, [showAll])

    const getRequests = () => {
        console.log(showAll);
        axios(`${api}/managers/${match.params.managerId}${!showAll && page ? "/pages/" + page : ""}`).then(({data}) => {
            setRequests(data)
        })
    }

    useEffect(() => {
        axios(`${api}/catalogs/stations`).then(({data}) => {
            setStations(data);
        });
        getRequests()
    }, [])

    const data = requests.Data ? requests.Data : requests;

    const ListColumn1 = data.map((request, index) =>
        <HexBorder key={index} theme={theme.button}>
            <button onClick={_ => history.push(`/request/${requests.User.Id}/${request.Id}`)}>Запрос
                № {request.Id} "{getStationName(stations, request.SrcStation)} — {getStationName(stations, request.DstStation)}"
                от {moment(request.Created).format("DD.MM.YYYY")}</button>
        </HexBorder>
    );

    return (
        <Page>
            <Row justifyContent={"center"}>
                <Buttons theme={theme}>
                    <HexBorder theme={theme.button}>
                        <button>Список
                            запросов {requests?.User?.Surname && requests?.User?.Name ? `${requests?.User?.Surname} ${requests?.User?.Name}` : requests?.User?.Login}</button>
                    </HexBorder>
                </Buttons>
            </Row>

            <Buttons_ theme={theme} className={"items"}>
                <SubGroupButtons>
                    {ListColumn1}
                </SubGroupButtons>

            </Buttons_>
            <Buttons_ theme={theme}>
                <Pagination>
                    <div className={`top ${showAll ? "hidden" : ""}`}>
                        <HexBorder theme={theme.button}>
                            <button onClick={_ => (parseInt(page) - 1 >= 1) && setPage(parseInt(page) - 1)}><img
                                className="reversed"
                                src={buttonArrowSvg}
                                alt=""/> Предыдущая
                            </button>
                        </HexBorder>
                        <div className="pages">
                            <Input value={page} onBlur={({target: {value}}) => {
                                if (value === "") {
                                    setPage(1);
                                }
                            }} onChange={({target: {value}}) => {
                                if (((value <= Math.ceil(requests.Total / 10)) && (value >= 1)) || value === "") {
                                    setPage(value);
                                }
                            }}/>
                            из {Math.ceil(requests.Total / 10)}
                        </div>
                        <HexBorder theme={theme.button}>
                            <button
                                onClick={_ => (parseInt(page) + 1 <= Math.ceil(requests.Total / 10)) && setPage(parseInt(page) + 1)}>Следующая <img
                                src={buttonArrowSvg} alt=""/></button>
                        </HexBorder>
                    </div>
                    <div className="bottom">
                        <div className="showAll" onClick={_ => {
                            setShowAll(!showAll)
                        }}>
                            Вывести все <img src={showAllSvg} alt=""/>
                        </div>
                    </div>
                </Pagination>
            </Buttons_>
        </Page>
    )
}


export default Mover4

